import { Theme } from '@mui/material'
import { THEME_MODE } from '@constants'
import { GridColumnType } from '../hooks'

// Хелпер для получения класса заголовка или ячейки колонки, которая проставлена как обязательная
// (restrictions.required = true)
export const getDataGridElementClassName = (
  column: GridColumnType,
  theme: Theme,
  element: 'header' | 'cell'
) => {
  return column.required
    ? `required-column-${element}--${theme.palette.mode === THEME_MODE.LIGHT ? 'light' : 'dark'}${
        column.pinnedColumn ? '--pinned' : ''
      }`
    : ''
}
