import { ChangeEvent, FC, useMemo, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Popover,
  Typography,
} from '@mui/material'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { ConfiguredColumnEmbeddedField } from '../../../../../../types'
import { FormInput, FormLabel, TextField } from '@microservices/wiskey-react-components'
import { useTranslation } from 'react-i18next'

type EmbeddedFieldsPopoverProps = {
  open: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
  field: string
  embeddedFields: ConfiguredColumnEmbeddedField[]
}

export const EmbeddedFieldsPopover: FC<EmbeddedFieldsPopoverProps> = ({
  open,
  anchorEl,
  onClose,
  field,
  embeddedFields,
}) => {
  const { t } = useTranslation()

  const { setValue, getValues } = useFormContext()

  const [search, setSearch] = useState('')

  const handleChangeSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleSwitchAll = (value: boolean) => {
    return () => {
      const embeddedFieldsEnable = getValues()[field].embeddedFieldsEnable
      const mappedEmbeddedFields = Object.fromEntries(
        Object.entries(embeddedFieldsEnable).map(([name]) => [name, value])
      )
      setValue(`${field}.embeddedFieldsEnable`, mappedEmbeddedFields, { shouldDirty: true })
    }
  }

  const filteredFields = useMemo(
    () =>
      embeddedFields.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())) || [],
    [embeddedFields, search]
  )

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card>
        <Typography sx={{ p: 3, textTransform: 'capitalize' }}>{field}</Typography>
        <Box sx={{ px: 3 }}>
          <FormLabel
            name={'search'}
            label={t('configSettings.searchInput.label')}
            labelPlacement='top'
            labelSx={{ fontSize: 12 }}
            control={
              <TextField
                onChange={handleChangeSearch}
                name={'search'}
                variant='standard'
                fullWidth
                placeholder={t('configSettings.searchInput.placeholder')}
              />
            }
          />
        </Box>
        <CardContent sx={{ overflow: 'auto', maxHeight: 300, minWidth: 350, maxWidth: 350 }}>
          <Grid container direction={'column'}>
            {filteredFields.map(({ name }) => (
              <Grid key={name} item>
                <FormInput
                  name={`${field}.embeddedFieldsEnable.${name}`}
                  label={name}
                  labelPlacement={'end'}
                  labelSx={{ width: '100%' }}
                  inputType={GENERATOR_INPUT_TYPE.SWITCH}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}>
          <Button
            onClick={handleSwitchAll(false)}
            size='small'
            color='primary'
            sx={{ textTransform: 'uppercase' }}
          >
            {t('configSettings.embeddedFieldsPopover.hideBtn')}
          </Button>
          <Button
            onClick={handleSwitchAll(true)}
            size='small'
            color='primary'
            sx={{ textTransform: 'uppercase' }}
          >
            {t('configSettings.embeddedFieldsPopover.showBtn')}
          </Button>
        </CardActions>
      </Card>
    </Popover>
  )
}
