import '../i18n'

import { SnackMessage } from '@components/SnackMessage'
import { AuthProvider } from '@providers/AuthProvider'
import { ThemeProvider } from '@providers/ThemeProvider'
import { store } from '@redux/store'
import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { AppRoutes } from './AppRoutes'

function App() {
  return (
    <Suspense fallback='loading'>
      <Provider store={store}>
        <AuthProvider>
          <ThemeProvider>
            <AppRoutes />
            <SnackMessage />
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </Suspense>
  )
}

export default App
