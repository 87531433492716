import { ErrorDescriptionRows, ObjectDataRecord } from 'src/types'
import { getErrorDescriptionByField } from './getErrorDescriptionByField'

export const getErrorDescriptionByRow = (rows: ObjectDataRecord[]) => {
  const result: ErrorDescriptionRows = {}

  rows.forEach(({ errors, _id, id }) => {
    if (errors) {
      result[_id || id] = getErrorDescriptionByField(errors)
    }
  })

  return result
}
