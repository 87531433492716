import { useContext } from 'react'
import { AuthContext } from '@providers/AuthProvider'
import { useFetchUserLoggedQuery } from '@redux/api/user.api'

export const useAuth = () => {
  // Необходимо бэкенду для "регистрации" пользователя
  useFetchUserLoggedQuery()

  const { getUsername, hasRole, doLogout } = useContext(AuthContext)

  return {
    username: getUsername(),
    hasRole,
    doLogout,
  }
}
