import { SortType, useDebounceFn, useInfiniteScroll } from '@hooks'
import {
  AutoCompleteInfiniteScroll,
  GridRenderEditCellParams,
  TextField,
  useGridApiContext,
} from '@microservices/wiskey-react-components'
import {
  AutocompleteOption,
  AutoCompleteProps,
} from '@microservices/wiskey-react-components/dist/AutoComplete'
import { grey } from '@mui/material/colors'
import { ChangeEvent, FC, FocusEventHandler, useEffect, useMemo, useState } from 'react'
import { AutocompleteInputChangeReason, OutlinedInputProps } from '@mui/material'

import { useFetchObjectByCodeQuery, useFetchObjectDataByCodeMutation } from '@redux/api'
import { GETObjectDataParams, GetObjectDataRecord } from 'src/types'
import { getParsedFields } from '@pages/ConfiguredView/helpers/getParsedFields'
import { FIELD_VALUE_TYPE, REGEX } from '@constants'

type LinkedObjectAutocompleteProps = {
  objectCode?: string
  field?: string
  autoFocus?: boolean
  inputProps?: Partial<OutlinedInputProps>
  editCellParams: GridRenderEditCellParams
  valueType: FIELD_VALUE_TYPE
  type?: FIELD_VALUE_TYPE
} & Omit<AutoCompleteProps<AutocompleteOption>, 'options' | 'renderInput'>

export const LinkedObjectAutocomplete: FC<LinkedObjectAutocompleteProps> = ({
  objectCode,
  onChange,
  value,
  autoFocus,
  inputProps,
  field = '_id',
  editCellParams,
  valueType,
  type,
  ...props
}) => {
  const apiRef = useGridApiContext()
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)

  const getFilter = (field: string, value: unknown) => {
    // Для boolean полей фильтр работает только по полному соответствию
    const needPartialMatchOperator = valueType !== FIELD_VALUE_TYPE.BOOLEAN
    return `${field}==${value}${needPartialMatchOperator ? '*' : ''}`
  }

  const defaultSort = field ? ([{ field: field, sort: 'asc' }] as SortType[]) : undefined
  const defaultFilter = field && value ? getFilter(field, value) : undefined
  const {
    combinedData,
    readMore,
    onSetParams,
    currentPage,
    totalPages,
    isLoading: isLoadingData,
  } = useInfiniteScroll<GETObjectDataParams, GetObjectDataRecord>(
    useFetchObjectDataByCodeMutation,
    {
      code: objectCode,
      skip: !open,
    },
    undefined,
    {
      sort: defaultSort,
      filter: defaultFilter,
    }
  )

  const { data: objectModel } = useFetchObjectByCodeQuery(objectCode)
  
  const pkField = useMemo(() => objectModel?.fields.find(field => field.isPk), [objectModel])

  const options: AutocompleteOption[] = useMemo(
    () =>
      combinedData.map(item => {
        const fieldValue = item[field] ?? ''
        return {
          id: (item[pkField!.name] as string | number) || '',
          label: fieldValue.toString(),
        }
      }) ?? [],
    [combinedData, pkField]
  )
  // Нужно т.к. при первом рендере editCell внутри значения строка или число => автокомплит пустой
  const optionValue = options.find(({ id, label }) => id === value || value === label)
  const currentValue =
    optionValue === undefined && value && typeof value !== 'object'
      ? { id: 0, label: value }
      : optionValue

  const handleInputChange = useDebounceFn({
    fn: (
      event: ChangeEvent<HTMLInputElement>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (reason === 'clear') {
        onSetParams({
          page: 0,
          sort: defaultSort,
          filter: undefined,
        })
        return
      }

      const trimmedValue = value.trim()
      if (trimmedValue === inputValue) {
        return
      }

      setInputValue(trimmedValue)

      if (field && value) {
        if (type !== FIELD_VALUE_TYPE.INTEGER && REGEX.EXCLUDE_EQUAL.test(trimmedValue)) {
          onSetParams({
            page: 0,
            sort: defaultSort,
            filter: `${field}==${trimmedValue}*`,
          })
          return
        }
        if (REGEX.VALIDATE_INTEGER_FOR_REQUEST.test(trimmedValue)) {
          onSetParams({
            page: 0,
            sort: defaultSort,
            filter: `${field}${trimmedValue}`,
          })
        }
        return
      }

      onSetParams({
        page: 0,
        sort: defaultSort,
        filter: undefined,
      })
    },
    delay: 1000,
  })

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    if (value) {
      return
    }
    onSetParams({ page: 0, filter: undefined })
    setInputValue('')
  }

  // Инициализируем значение в виде AutocompleteOption, так-как по-умолчанию значение в клетке - просто строка
  useEffect(() => {
    const { id, row, field, value } = editCellParams

    if (typeof value !== 'string') {
      return
    }

    const { parentFieldName } = getParsedFields(field)

    if (!parentFieldName) {
      return
    }

    const refObjectId = row[parentFieldName]

    if (!refObjectId) {
      return
    }

    apiRef.current.setEditCellValue({ id, field, value: { id: refObjectId, label: value } })
  }, [])

  return (
    <AutoCompleteInfiniteScroll
      options={options}
      loading={isLoadingData}
      onChange={onChange}
      onInputChange={handleInputChange}
      // value={currentValue || value}
      value={value || null}
      // inputValue={value?.label || ''}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      filterOptions={x => x} // Переопределяем дефолтный фильтр
      componentsProps={{
        popper: {
          sx: {
            '& .MuiAutocomplete-option': {
              minHeight: '36px !important',
            },
          },
        },
      }}
      renderInput={params => (
        <TextField
          {...params}
          // onBlur={handleBlur}
          autoFocus={autoFocus}
          sx={{
            color: grey[600],
            '*::before': {
              borderBottom: 'none !important',
            },
          }}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {inputProps?.endAdornment}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onLoadNextPage={readMore}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      totalPages={totalPages}
      page={currentPage}
      {...props}
    />
  )
}
