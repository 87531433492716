import { commonAPI, TAG_TYPE } from './common.api'
import { ReturnUpdateObjectDataRecord } from '../../types'

export const validateAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchViewValidationErrors: build.query<ReturnUpdateObjectDataRecord[], number>({
      query: viewId => ({
        url: `validate/allRules/${viewId}`,
      }),
      providesTags: [TAG_TYPE.VIEW_VALIDATION_ERRORS],
    }),
  }),
})

export const { useFetchViewValidationErrorsQuery } = validateAPI
