import { FC } from 'react'

import { AppBar as MUIAppBar, Box, Grid, IconButton, Toolbar } from '@mui/material'
import { Brightness4, Brightness7 } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import { MIN_DRAWER_WIDTH, THEME_MODE } from '@constants'
import { useAppDispatch } from '@hooks'
import { setThemeMode } from '@redux/reducers/theme.reducer'
import MenuIcon from '@mui/icons-material/Menu'
import { usePaletteMode } from '@hooks'

type AppBarProps = {
  open: boolean
  setOpen?: (sidebarOpen: boolean) => void
}

const StyledAppBar = styled(MUIAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  '& .MuiToolbar-root': {
    paddingLeft: theme.spacing(2),
  },
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: MIN_DRAWER_WIDTH,
    width: `calc(100% - ${MIN_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export const AppBar: FC<AppBarProps> = ({ open, setOpen }) => {
  const mode = usePaletteMode()
  const dispatch = useAppDispatch()

  const toggleColorMode = () => {
    const newMode = mode === THEME_MODE.LIGHT ? THEME_MODE.DARK : THEME_MODE.LIGHT
    dispatch(setThemeMode(newMode))
    localStorage.setItem('mode', newMode)
  }

  const handleClick = () => {
    setOpen?.(true)
  }

  return (
    <StyledAppBar open={open} position='static'>
      <Toolbar>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Box>
              <Grid container alignItems='center'>
                <Grid item>
                  {!open && (
                    <IconButton
                      sx={{ mr: 3, color: theme => theme.palette.background.paper }}
                      onClick={handleClick}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Grid container alignItems={'center'}>
              <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color='inherit'>
                {mode === THEME_MODE.DARK ? <Brightness7 /> : <Brightness4 />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  )
}
