import { RouteObject, useRoutes } from 'react-router-dom'

import { GanttChart } from '@pages/GanttChart'
import { ConfiguredView } from '@pages/ConfiguredView'
import { MenuSetting } from '@pages/MenuSetting'
import { ConfigViewList } from '@pages/ConfigViewList'
import { AppLayout } from '@layouts/AppLayout'
import { ROUTES, USER_ROLES } from '@constants'
import { useAuth } from '@hooks'
import { useFetchMenuQuery } from '@redux/api'
import { AppLoader } from '@components/AppLoader'
import { OptionType } from '../../types'
import { ViewCreateOrEdit } from '@pages/ViewCreateOrEdit'

export const MainRoute = () => {
  const { hasRole } = useAuth()

  const { data: menu, isSuccess } = useFetchMenuQuery()

  // Создание роутов для динамически конфигурируемых страниц
  const createConfiguredRoutes = (menu: OptionType[], routes: RouteObject[] = []) => {
    menu.forEach(menuItem => {
      if (menuItem.viewId) {
        routes.push({
          path: menuItem.code,
          element: (
            <ConfiguredView
              viewId={menuItem.viewId}
              path={menuItem.code}
              // For rerender
              key={menuItem.code}
              title={menuItem.title}
            />
          ),
        })
      }

      if (menuItem.childrenMenu) {
        createConfiguredRoutes(menuItem.childrenMenu, routes)
      }
    })

    return routes
  }

  const renderByRoles = (roles: USER_ROLES[], routes: RouteObject[]): RouteObject[] => {
    return hasRole(roles) ? routes : []
  }

  const routes: RouteObject[] = [
    ...renderByRoles(
      [USER_ROLES.ADMIN],
      [
        {
          element: <GanttChart />,
          path: ROUTES.GANTT_CHART,
        },
        {
          element: <MenuSetting />,
          path: ROUTES.MENU_SETTING,
        },
        {
          element: <ConfigViewList />,
          path: ROUTES.VIEWS,
          children: [
            {
              element: <ViewCreateOrEdit />,
              path: ROUTES.VIEWS_CREATE,
            },
            {
              element: <ViewCreateOrEdit />,
              path: ROUTES.VIEWS_EDIT_ID,
            },
          ],
          // children: generateEntityChildrenRoutes(ENTITY.VIEW),
        },
      ]
    ),
    ...createConfiguredRoutes(menu || []),
  ]

  const appLayout = <AppLayout>{useRoutes(routes)}</AppLayout>

  return isSuccess ? appLayout : <AppLoader />
}
