import { POSTRestriction, RESTRICTION_NAME, RESTRICTION_TYPE } from '../../../types'

const getRestrictionTypeByName = (restrictionName: string) => {
  switch (restrictionName) {
    case 'enabled':
      return RESTRICTION_TYPE.ENABLED
    case 'userRequired':
      return RESTRICTION_TYPE.USER_REQUIRED
    case 'unique':
      return RESTRICTION_TYPE.UNIQUE
    case 'symbolsNumLimit':
      return RESTRICTION_TYPE.SYMBOLS_NUMBER
    default:
      return RESTRICTION_TYPE.UNDEFINED
  }
}

const transformValueByRestrictionType = (value: unknown, restrictionType: RESTRICTION_TYPE) => {
  switch (restrictionType) {
    case RESTRICTION_TYPE.SYMBOLS_NUMBER:
      return value ? Number(value) : null
    default:
      return value
  }
}

export const transformRestrictionsFieldsForSending = (
  values: Record<string, unknown>,
  viewId: number
): POSTRestriction[] => {
  const postRestrictions: POSTRestriction[] = []
  // Перебираем список изменённых полей
  Object.entries(values).forEach(([fieldName, restrictions]) => {
    if (!restrictions) {
      return
    }
    // Перебираем список изменённых ограничений для каждого поля
    Object.entries(restrictions).forEach(([restrictionName, restrictionValue]) => {
      // У каждого поля может быть список вложенных полей, для которых указано значение активности
      if (restrictionName === 'embeddedFieldsEnable') {
        if (restrictionValue && typeof restrictionValue === 'object') {
          // Перебираем список вложенных полей
          Object.entries(restrictionValue).forEach(([embeddedFieldName, embeddedFieldEnabled]) => {
            // Добавляем ограничение с вложенным полем в соответствии с значением enabled
            postRestrictions.push({
              viewId: viewId,
              parentFieldName: fieldName,
              fieldName: embeddedFieldName,
              restrictionType: RESTRICTION_TYPE.ENABLED,
              restrictionName: RESTRICTION_NAME.ENABLED,
              value: embeddedFieldEnabled,
            })
          })
        }
        return
      }

      const restrictionType = getRestrictionTypeByName(restrictionName)

      // Добавляем ограничение для обычного поля
      postRestrictions.push({
        viewId: viewId,
        parentFieldName: null,
        fieldName: fieldName,
        restrictionType: restrictionType,
        restrictionName: restrictionName as RESTRICTION_NAME,
        value: transformValueByRestrictionType(restrictionValue, restrictionType),
      })
    })
  })

  return postRestrictions
}
