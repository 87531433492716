import { ChangeEvent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuth } from './auth'

import { useKeyPress } from './keyPress'
import { EVENT_NAME, publish } from '@events'
import { useFetchMenuWithSearchQuery } from '@redux/api'
import { OptionType } from '../types'
import { useDebounce } from './debounce'
import { getSidebarDefaultOptions } from '@helpers'
import { KEYBOARD_EVENT_PROPS, KEYS_CODES, USER_ROLES } from '@constants'

type SidebarHook = {
  sidebarOpen: boolean
  handleOpenSidebar: (value: boolean) => void
  navOptions: OptionType[]
  handleMPress: () => void
  searchValue: string
  handleChangeSearchValue({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
}

export const useSidebar = (): SidebarHook => {
  const { t } = useTranslation()

  const { hasRole } = useAuth()
  const sidebarDefaultOptions = getSidebarDefaultOptions(hasRole([USER_ROLES.ADMIN]))

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)
  const { pathname, search } = useLocation()

  const [searchValue, setSearchValue] = useState('')
  const debounceSearchValue = useDebounce(searchValue, 500)
  const { data: menu } = useFetchMenuWithSearchQuery(searchValue ? debounceSearchValue : '')

  const [navOptions, setNavOptions] = useState<OptionType[]>([])

  useEffect(() => {
    if (menu) {
      setNavOptions([...menu, ...sidebarDefaultOptions])

      return
    }
  }, [menu])

  const sidebarTriggerPress = useKeyPress(KEYS_CODES.KEY_M, KEYBOARD_EVENT_PROPS.SHIFT_KEY)

  useEffect(() => {
    if (sidebarTriggerPress) {
      handleMPress()
    }
  }, [sidebarTriggerPress])

  const handleMPress = () => {
    setSidebarOpen((prev: boolean) => {
      publish(EVENT_NAME.SIDEBAR_TRIGGER, { sidebarOpen: !prev })

      return !prev
    })
  }

  const handleOpenSidebar = (value: boolean) => setSidebarOpen(value)

  const handleChangeSearchValue = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(value)
  }

  return {
    sidebarOpen,
    handleOpenSidebar,
    navOptions,
    handleMPress,
    searchValue,
    handleChangeSearchValue,
  }
}
