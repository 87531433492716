import { FC, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '@microservices/wiskey-react-components'

import { useDeleteObjectDataRecordsMutation } from '@redux/api'
import { isFailedDelition } from '@pages/ConfiguredView/helpers'

type DeleteLinkedRecordDialogProps = {
  isShow: boolean
  onClose: () => void
  onSave: () => void
}

export const DeleteLinkedRecordDialog: FC<DeleteLinkedRecordDialogProps> = ({
  isShow,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation()
  const [, { data = {} }] = useDeleteObjectDataRecordsMutation({ fixedCacheKey: 'shareDeleteIds' })

  const countRows = (data && Object.keys(data).length) || 0

  const successDeletedCount =
    (data && Object.values(data).filter(refCount => refCount === true).length) || 0

  const errorRows = countRows - successDeletedCount

  const failedDeletionsList = useMemo(() => Object.entries(data).filter(isFailedDelition), [data])

  return (
    <ModalWrapper
      isShow={isShow}
      onClose={onClose}
      onSave={onSave}
      title={t('configuredView.deleteLinkedModal.title')}
      btnText={t('configuredView.deleteLinkedModal.edit')}
      disabledSave
      hideDivider
      focusSave
      focusCancel
    >
      <Box my={2} maxWidth={500}>
        <Typography>
          {successDeletedCount
            ? t('configuredView.deleteLinkedModal.successDeleted', { count: successDeletedCount })
            : null}
        </Typography>
        <Typography mt={1}>
          {countRows && countRows > 1
            ? t('configuredView.deleteLinkedModal.multiple', { count: errorRows })
            : t('configuredView.deleteLinkedModal.single')}
        </Typography>
        <Typography mt={1}>{t('configuredView.deleteLinkedModal.end')}</Typography>
        {failedDeletionsList.map(([id, refList]) => (
          <Box mt={2} key={id}>
            <Typography mb={1}>{t('configuredView.deleteLinkedModal.id', { id })}</Typography>
            {refList.map(({ objectTitle, count, field }) => (
              <Typography key={field}>{`${objectTitle}, ${field}: ${count}`}</Typography>
            ))}
          </Box>
        ))}
      </Box>
    </ModalWrapper>
  )
}
