import { Add, Edit, Delete } from '@mui/icons-material'
import { Grid, IconButton, ListItemIcon } from '@mui/material'
import { FC } from 'react'

type IconButtonsProps = {
  onAdd?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onEdit?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const IconButtons: FC<IconButtonsProps> = ({ onAdd, onDelete, onEdit }) => {
  return (
    <Grid container item justifyContent={'flex-end'}>
      {onAdd && (
        <IconButton onClick={onAdd}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <Add fontSize='small' />
          </ListItemIcon>
        </IconButton>
      )}
      {onEdit && (
        <IconButton onClick={onEdit}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <Edit fontSize='small' />
          </ListItemIcon>
        </IconButton>
      )}
      {onDelete && (
        <IconButton onClick={onDelete}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <Delete fontSize='small' />
          </ListItemIcon>
        </IconButton>
      )}
    </Grid>
  )
}
