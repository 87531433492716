import { FC, ReactNode, useEffect, useMemo } from 'react'
import {
  createTheme,
  PaletteOptions,
  Theme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles'
import { CssBaseline, PaletteMode, useMediaQuery } from '@mui/material'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

import { useAppDispatch, useAppSelector } from '@hooks'
import { setThemeMode } from '@redux/reducers/theme.reducer'
import { THEME_MODE } from '@constants'

declare module '@mui/material/styles' {
  // Типизация кастомных параметров темы
  // interface Theme {}
  // interface ThemeOptions {}

  interface Palette {
    border: {
      table: '#515151' | '#e0e0e0'
    }
    dragger: '#f4f7f9' | '#23292e'
  }

  interface PaletteOptions {
    border?: {
      table: '#515151' | '#e0e0e0'
    }
    dragger?: '#f4f7f9' | '#23292e'
  }
  interface TypeBackground {
    emptyRow: '#242b30' | '#fff'
    editRow: '#e3f1fd' | '#3c5f7d'
    updatesRow: '#74de656e' | '#73de6581'
    errorRow: '#67494b' | '#ffe6e8'
  }
}

const getPalette = (mode: PaletteMode): PaletteOptions => {
  return {
    mode,
    ...(mode === 'light'
      ? {
          border: {
            table: '#e0e0e0',
          },
          dragger: '#f4f7f9',
          background: {
            editRow: '#e3f1fd',
            updatesRow: '#73de6581',
            errorRow: '#ffe6e8',
          },
        }
      : {
          border: {
            table: '#515151',
          },
          dragger: '#23292e',
          background: {
            paper: '#2d353c',
            default: '#161a1d',
            editRow: '#3c5f7d',
            emptyRow: '#242b30',
            updatesRow: '#74de656e',
            errorRow: '#67494b',
          },
        }),
  }
}

const getMuiCssBaseline = (): {
  styleOverrides?: ComponentsOverrides<Theme>['MuiCssBaseline']
} => {
  return {
    styleOverrides: {
      body: {
        minWidth: 600,
      },
    },
  }
}

type ThemeProviderProps = {
  children: ReactNode
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const { mode } = useAppSelector(state => state.theme)
  const dispatch = useAppDispatch()
  const isDark = useMediaQuery('(prefers-color-scheme: dark)')

  useEffect(() => {
    const currentMode = localStorage.getItem('mode') as THEME_MODE
    const usedTheme = Object.values(THEME_MODE).includes(currentMode) ? currentMode : null
    const systemTheme = isDark ? 'dark' : 'light'

    dispatch(setThemeMode(usedTheme ?? systemTheme))
  }, [isDark])

  const theme = useMemo(
    () =>
      createTheme({
        palette: getPalette(mode),
        components: {
          MuiCssBaseline: getMuiCssBaseline(),
        },
      }),
    [mode]
  )

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}
