import { SxProps } from '@mui/material'

export enum THEME_MODE {
  LIGHT = 'light',
  DARK = 'dark',
}

export const VISIBLE_HIDDEN: SxProps = {
  visible: 'hidden',
  overflow: 'hidden',
  height: 0,
  p: 0,
}
