import { FC, createContext } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Tabs, Tab } from '@mui/material'

import { PageTitle } from '@components/PageTitle'
import { TabPanel } from '@components/Tabs'
import { PageContentLayout } from '@layouts/PageContentLayout'
import { AllViews } from './components/AllViews'
import { useConfigViewList } from './hooks'
import { VISIBLE_HIDDEN } from '@constants'
import { PageContextType } from './types'
import { CopyDialog } from '@components/CopyDialog'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const ConfigViewList: FC = () => {
  const { t } = useTranslation()

  const { state, data, handlers } = useConfigViewList()

  const { allEntities, currentTab, copyViewData, showCopyDialog, isAllEntities } = state

  const {
    isFetchingViews,
    isLoadingCopyView,
    // objects
  } = data

  const {
    handleDeleteView,
    handleEditView,
    handleSetObjectCode,
    handleSetSearchPattern,
    handleSetAllEntities,
    handleTabChange,
    handleSetDirty,
    handleCloseDialog,
    handleShowDialog,
    handleCopyView,
  } = handlers

  const contextValue = {
    entitiesTables: allEntities,
    viewsIsLoading: isFetchingViews,
    onDeleteView: handleDeleteView,
    onEditView: handleEditView,
    onSetObjectCode: handleSetObjectCode,
    onSetSearchPattern: handleSetSearchPattern,
    onShowDialog: handleShowDialog,
  }

  return (
    <PageContext.Provider value={contextValue}>
      {showCopyDialog && copyViewData && (
        <CopyDialog
          isShow={showCopyDialog}
          onClose={handleCloseDialog}
          copyData={copyViewData}
          onCopy={handleCopyView}
          dialogTitle={t(`copying.view.dialogTitle`)}
          loading={isLoadingCopyView}
        />
      )}
      <Outlet />
      <Box
        sx={{
          ...(!isAllEntities && VISIBLE_HIDDEN),
        }}
      >
        <PageTitle
          start={
            <>
              <Typography display={'inline'} variant={'h6'} sx={{ mr: 1 }}>
                {t('configViewList.models')}
              </Typography>
              <Typography display={'inline'} variant={'subtitle2'}>
                {t('configViewList.modelsControl')}
              </Typography>
            </>
          }
          end={false}
        />
        <PageContentLayout key={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleTabChange}>
              <Tab label={t(`configViewList.tabs.all`)} />
            </Tabs>
          </Box>
          <TabPanel
            value={currentTab}
            index={0}
            isAlwaysRendered
            hidden={false}
            sx={{ ...(currentTab !== 0 && VISIBLE_HIDDEN) }} // Пришлось кастомизировать стили, т.к display none немного ломает отображение DataGrid
          >
            <AllViews />
          </TabPanel>
        </PageContentLayout>
      </Box>
    </PageContext.Provider>
  )
}
