import { OptionType } from 'src/types'

export const findNavOptionParent = (
  navOptions: OptionType[],
  navOption: OptionType
): OptionType | null => {
  let result: OptionType | null = null

  navOptions.forEach(option => {
    if (result) {
      return
    }

    if (option.childrenMenu.find(innerOption => innerOption.code === navOption.code)) {
      result = option

      return
    }

    if (option.childrenMenu.length) {
      result = findNavOptionParent(option.childrenMenu, navOption)
    }
  })

  return result
}
