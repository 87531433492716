import { ViewColumn } from '../../../types'
import { mergeBaseAndUserRequired } from '../../../helpers/mergeBaseAndUserRequired'

export const getColumnVisibilityByRestrictions = (column: ViewColumn): boolean => {
  // Для уникальных полей
  if (column.restrictions.unique) {
    return true
  }
  // Для полей с ограничением по количеству символов
  if (typeof column.restrictions.symbolsNumber === 'number') {
    return true
  }
  // Для обязательных полей
  return mergeBaseAndUserRequired({
    baseRequired: column.baseRequired,
    userRequired: column.userRequired,
  })
    ? true
    : column.restrictions.show ?? true
}
