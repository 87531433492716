import { FC, ReactNode } from 'react'
import { Box, Grid, SxProps } from '@mui/material'

type PageTitleProps = {
  start: ReactNode
  end: ReactNode
  sx?: SxProps
}

export const PageTitle: FC<PageTitleProps> = ({ start, end, sx }) => {
  return (
    <Box mb={2} sx={sx}>
      <Grid item container justifyContent={'space-between'}>
        <Grid item>{start}</Grid>
        <Grid item>{end}</Grid>
      </Grid>
    </Box>
  )
}
