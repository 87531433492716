import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'

import { commonAPI, TAG_TYPE } from './common.api'
import { transformResponseWithPagination, getEnrichedSortParam } from '@helpers'
import {
  DELETEMultipleObjectDataParams,
  DELETEMultipleObjectDataReturn,
  GETEnrichedObjectDataParams,
  GETObjectData,
  GETObjectDataParams,
  GETObjectDataRecordParams,
  GetObjectDataRecord,
  POSTUpdateObjectDataRecord,
  ReturnUpdateObjectDataRecord,
  GETAllObjects,
  GETObject,
} from '../../types'

export const objectAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllObjects: build.query<GETAllObjects, void>({
      query: () => ({
        url: 'router/models/object/list',
      }),
    }),
    fetchObjectByCode: build.query<GETObject, string | void>({
      query: code => ({
        url: `router/models/object/code/${code}`,
      }),
    }),
    fetchObjectDataByCode: build.mutation<GETObjectData, GETObjectDataParams>({
      query: ({ code, page, size, sort, filter }) => ({
        url: `router/storage/object/list/${code}`,
        params: {
          page,
          size,
          sort,
          filter,
        },
      }),
      transformResponse: (output: GetObjectDataRecord[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<GetObjectDataRecord[]>(output, meta),
    }),
    fetchObjectDataListEnrichedByViewId: build.mutation<GETObjectData, GETObjectDataParams>({
      query: ({ viewId, page, size, sort, filter }) => ({
        url: `router/storage/view/list/${viewId}/enriched?${getEnrichedSortParam(sort)}`,
        params: {
          // code,
          page,
          size,
          filter,
        },
      }),
      transformResponse: (output: GetObjectDataRecord[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<GetObjectDataRecord[]>(output, meta),
    }),
    fetchObjectDataEnrichedByViewId: build.mutation<
      GetObjectDataRecord[],
      GETEnrichedObjectDataParams
    >({
      query: ({ ids, viewId }) => ({
        url: `router/storage/view/${viewId}/enriched`,
        params: {
          ids,
        },
      }),
    }),
    fetchObjectData: build.mutation<GetObjectDataRecord, GETObjectDataRecordParams>({
      query: ({ objectCode, objectId }) => ({
        url: `router/storage/object/${objectCode}/id/${objectId}`,
      }),
    }),
    updateObjectDataRecord: build.mutation<
      ReturnUpdateObjectDataRecord[],
      POSTUpdateObjectDataRecord
    >({
      query: ({ viewId, rows }) => ({
        url: `router/storage/view/${viewId}`,
        method: 'POST',
        body: rows,
      }),
      extraOptions: {
        showNotification: false,
      },
      invalidatesTags: [TAG_TYPE.VIEW_VALIDATION_ERRORS],
    }),
    deleteObjectDataRecords: build.mutation<
      DELETEMultipleObjectDataReturn,
      DELETEMultipleObjectDataParams
    >({
      query: ({ viewId, objectIds }) => ({
        url: `router/storage/view/${viewId}`,
        method: 'DELETE',
        body: objectIds,
      }),
      extraOptions: {
        showNotification: false,
      },
    }),
  }),
})

export const {
  useFetchAllObjectsQuery,
  useFetchObjectByCodeQuery,
  useFetchObjectDataByCodeMutation,
  useFetchObjectDataListEnrichedByViewIdMutation,
  useFetchObjectDataMutation,
  useUpdateObjectDataRecordMutation,
  useDeleteObjectDataRecordsMutation,
  useFetchObjectDataEnrichedByViewIdMutation,
} = objectAPI
