import { ObjectDataRecordError } from 'src/types'
import { GridColumnType } from '../hooks'

export const getSortErrorsByColumns = (
  columns: GridColumnType[],
  errors: ObjectDataRecordError[]
): ObjectDataRecordError[] => {
  const orderColumns = columns.map(({ field }) => field)
  const copyErrors: ObjectDataRecordError[] = structuredClone(errors)

  copyErrors.sort((a, b) => orderColumns.indexOf(a.fieldName) - orderColumns.indexOf(b.fieldName))

  return copyErrors
}
