import qs from 'qs'

export const getEnrichedSortParam = (sort: string[] | undefined) => {
  return qs.stringify(
    {
      sort,
    },
    { encode: false, indices: false }
  )
}
