import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithNotifications } from './config'

export enum TAG_TYPE {
  // Список меню
  MENU = 'Menu',
  // Список представлений в меню
  VIEWS_IN_MENU = 'ViewsInMenu',
  // Список неиспользованных представлений в меню
  UNUSED_VIEWS_IN_MENU = 'UnusedViewsInMenu',
  // Открытая View
  VIEW = 'View',
  // Cписок View
  ALL_VIEWS = 'AllViews',
  // Список ограничений
  RESTRICTIONS = 'Restrictions',
  // Ошибки валидации по представлению
  VIEW_VALIDATION_ERRORS = 'ViewValidationErrors',
}

export const tagTypes: Array<TAG_TYPE> = Object.values(TAG_TYPE)

export const commonAPI = createApi({
  reducerPath: 'commonApi',
  tagTypes,
  baseQuery: baseQueryWithNotifications,
  endpoints: () => ({}),
})
