import { FC, useContext, useEffect, useMemo, useState } from 'react'

import { Button, Grid, IconButton, SxProps } from '@mui/material'
import { TreeItem } from '@atlaskit/tree'
import { Close, Done } from '@mui/icons-material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ConfirmModal, FormGenerator } from '@microservices/wiskey-react-components'

import { MenuSettingContext } from '@pages/MenuSetting/MenuSetting'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { useFetchViewsMenuQuery } from '@redux/api'
import { AutocompleteOption } from '../../../../../types'

type AddItemProps = {
  treeItem?: TreeItem
  onClose?: () => void
  onSetEdit: (value: boolean) => void
  isEdit?: boolean
  disabled?: boolean
  isGlobalEdit?: boolean
  sx?: SxProps
}

type FormValues = {
  selectElement: AutocompleteOption | null
  groupName?: string
  selectedView?: AutocompleteOption | null
  menuTitle?: string
}

enum ElementTypeGroup {
  ELEMENT = 1,
  GROUP = 2,
}

export const AddItem: FC<AddItemProps> = ({
  sx,
  onClose,
  treeItem,
  isEdit,
  disabled,
  onSetEdit,
  isGlobalEdit,
}) => {
  const { t } = useTranslation()
  const {
    tree,
    onAddTreeItem,
    allData,
    onEditTreeItem,
    updateMenuOptions,
    isDirty,
    onCancel,
    transformTree,
  } = useContext(MenuSettingContext)

  const ELEMENT_TYPE_GROUP = {
    ELEMENT: { id: 1, label: t('menuSetting.element.name') },
    GROUP: { id: 2, label: t('menuSetting.group.name') },
  }

  const { data: unusedObjectsMenu } = useFetchViewsMenuQuery()

  const [showCancelModal, setShowCancelModal] = useState(false)
  const defaultValues: FormValues = {
    selectElement: (isEdit && treeItem?.hasChildren) || disabled ? ELEMENT_TYPE_GROUP.GROUP : null,
    selectedView: null,
    menuTitle: '',
    groupName: '',
  }
  const methods = useForm<FormValues>({
    defaultValues,
  })
  const { handleSubmit, reset, watch } = methods

  const watchElementType = watch('selectElement')
  const isViewType = useMemo(
    () => watchElementType && watchElementType.id === ElementTypeGroup.ELEMENT,
    [watchElementType]
  )

  const selectedViewOptions = useMemo(
    () =>
      unusedObjectsMenu
        ? Object.entries(unusedObjectsMenu)
          .map(([key, value]) => ({
            id: Number(key),
            label: value,
          }))
          .sort((a, b) => b.id - a.id)
        : [],
    [unusedObjectsMenu]
  )

  const treeNames = useMemo(() => Object.values(tree?.items ?? {}).map(op => op.data.title), [tree])

  useEffect(() => {
    reset({ ...defaultValues, selectElement: watchElementType })
  }, [isViewType])

  const handleSaveOrEdit = () => {
    reset()
    if (!isGlobalEdit) {
      onSetEdit(true)
      return
    }
    onSetEdit(false)
    if (tree) {
      updateMenuOptions()
    }
  }

  useEffect(() => {
    if (treeItem && isEdit) {
      reset(
        treeItem.data.viewId
          ? {
            selectElement: ELEMENT_TYPE_GROUP.ELEMENT,
            selectedView: { id: treeItem.data.viewId, label: treeItem.data.viewTitle },
            menuTitle: treeItem.data.title,
          }
          : { selectElement: ELEMENT_TYPE_GROUP.GROUP, groupName: treeItem.data.title }
      )

      return
    }

    reset({ ...defaultValues, selectElement: watchElementType })
  }, [isEdit, treeItem, isViewType])

  const onAdd = ({ selectElement, groupName, selectedView, menuTitle }: FormValues): void => {
    if (selectElement) {
      // добавление папки
      if (selectElement.id === ElementTypeGroup.GROUP && groupName) {
        if (isEdit && treeItem) {
          // обновление заголовка у папки
          onEditTreeItem({ ...treeItem, data: { ...treeItem.data, title: groupName } })
          reset({ ...defaultValues, selectElement })
          return
        }

        const option = {
          title: groupName,
          code: Date.now().toString(),
          viewId: null,
          // hasChild: true,
          childrenMenu: [],
        }
        onAddTreeItem(option, treeItem)
        reset({ ...defaultValues, selectElement })
        return
      }
      // добавление
      if (selectElement.id === ElementTypeGroup.ELEMENT && selectedView && menuTitle) {
        // Обновление вьюхи
        if (isEdit && treeItem) {
          onEditTreeItem({
            ...treeItem,
            data: {
              ...treeItem.data,
              title: menuTitle,
              viewId: Number(selectedView.id),
              viewTitle: selectedView.label,
            },
          })
          return
        }
        const option = {
          title: menuTitle,
          code: Date.now().toString(),
          // hasChild: false,
          childrenMenu: [],
          viewId: Number(selectedView.id),
          viewTitle: selectedView.label,
        }

        onAddTreeItem(option, treeItem)
        reset({ ...defaultValues, selectElement })
      }
    }
  }

  const handleCancel = () => {
    if (isDirty) {
      setShowCancelModal(true)
      return
    }
    handleClose()
  }

  const handleCloseModal = () => setShowCancelModal(false)

  const handleClose = () => {
    if (treeItem && isEdit) {
      onClose?.()
      reset(
        treeItem.data.viewId
          ? {
            selectedView: { id: treeItem.data.viewId, label: treeItem.data.viewTitle },
            menuTitle: treeItem.data.title,
            selectElement: ELEMENT_TYPE_GROUP.ELEMENT,
          }
          : { selectElement: ELEMENT_TYPE_GROUP.GROUP, groupName: treeItem.data.title }
      )

      return
    }

    reset({ ...defaultValues, selectElement: watchElementType })
    onCancel()
    onSetEdit(false)
    handleCloseModal()
  }

  const isCurrentItem = (value: string) => isEdit && treeItem && treeItem.data.title === value

  return (
    <Grid item container direction={'row'} spacing={2} alignItems='center' sx={sx}>
      {showCancelModal && (
        <ConfirmModal
          isShow={showCancelModal}
          title={t('modal.cancelMenuChanges.title')}
          text={t('modal.cancelMenuChanges.text')}
          actionBtnText={t('modal.cancelBtnText')}
          cancelBtnText={t('modal.closeBtnText')}
          onClose={handleCloseModal}
          onConfirm={handleClose}
        />
      )}
      <FormProvider {...methods}>
        <Grid item ml={1} flex={1}>
          <FormGenerator
            inputs={[
              {
                inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                name: 'selectElement',
                disabled:
                  isGlobalEdit === undefined ? isEdit || treeItem?.data || disabled : !isGlobalEdit,
                placeholder: t(`menuSetting.elementType.placeholder`),
                isOptionEqualToValue: (option, value) => option.label === value.label,
                getOptionLabel: option => option.label,
                autocompleteOptions: [ELEMENT_TYPE_GROUP.ELEMENT, ELEMENT_TYPE_GROUP.GROUP],
              },
            ]}
          />
        </Grid>
        <Grid item flex={2}>
          {!isViewType ? (
            <FormGenerator
              inputs={[
                {
                  inputType: GENERATOR_INPUT_TYPE.INPUT,
                  name: 'groupName',
                  disabled: disabled,
                  placeholder: t(`menuSetting.group.placeholder`),
                  rules: {
                    required: true,
                    validate: value => {
                      if (value.trim().length === 0) {
                        return false
                      }
                      if (treeNames.includes(value) && !isCurrentItem(value)) {
                        return `${value} ${t(`menuSetting.alreadyUsed`)}`
                      }
                    },
                  },
                },
              ]}
            />
          ) : (
            <FormGenerator
              isRow
              inputs={[
                {
                  inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                  name: 'selectedView',
                  disabled: disabled,
                  placeholder: t(`menuSetting.element.placeholder`),
                  isOptionEqualToValue: (option, value) => option.label === value.label,
                  autocompleteOptions: selectedViewOptions,
                  // getOptionDisabled: option =>
                  //   Boolean(findNavOptionByCode(transformTree, option.id as string, true)),
                  rules: {
                    required: true,
                  },
                },
                {
                  inputType: GENERATOR_INPUT_TYPE.INPUT,
                  name: 'menuTitle',
                  disabled: disabled,
                  placeholder: t(`menuSetting.menuTitle.placeholder`),
                  rules: {
                    required: true,
                    validate: value => {
                      if (value.trim().length === 0) {
                        return false
                      }
                      if (treeNames.includes(value) && !isCurrentItem(value)) {
                        return `${value} ${t(`menuSetting.alreadyUsed`)}`
                      }
                    },
                  },
                },
              ]}
            />
          )}
        </Grid>
        <Grid item mr={treeItem ? 0 : 2} alignSelf={'baseline'}>
          <IconButton onClick={handleSubmit(onAdd)} disabled={!isGlobalEdit && !treeItem}>
            <Done color='primary' />
          </IconButton>
          <IconButton onClick={handleClose} disabled={!isGlobalEdit && !treeItem}>
            <Close color='error' />
          </IconButton>
        </Grid>
        {!treeItem && (
          <Grid container sx={{ mt: 2, ml: 2 }} spacing={1}>
            <Grid item>
              <Button
                variant='contained'
                onClick={handleSaveOrEdit}
                disabled={isGlobalEdit && !isDirty}
              >
                {isGlobalEdit ? t('button.save') : t('button.edit')}
              </Button>
            </Grid>
            {isGlobalEdit && (
              <Grid item>
                <Button onClick={handleCancel}>{t('button.close')}</Button>
              </Grid>
            )}
          </Grid>
        )}
      </FormProvider>
    </Grid>
  )
}
