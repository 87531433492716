import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { showMessage } from '../../reducers/snackbar.reducer'
import { getResponseMessageText } from '@helpers'
import { URLS } from '@constants'
import { ExtraOptions, ServerError } from '../../../types'
import AuthService from '@services/AuthService'

const baseQuery = fetchBaseQuery({
  baseUrl: URLS.API_URL,
  prepareHeaders: headers => {
    const token = AuthService.getToken()
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const baseQueryWithNotifications: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  ExtraOptions
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  const requestArgs = args as FetchArgs
  const isValidRequest = requestArgs?.body || requestArgs?.method === 'DELETE' || result.error
  const resultErrorData = result.error?.data as ServerError
  const showNotification = extraOptions?.showNotification ?? true
  if (result.meta?.response?.status === 401) {
    AuthService.doLogin()
  }
  if (isValidRequest && showNotification) {
    api.dispatch(
      showMessage({
        type: !result.error ? 'success' : 'error',
        text: getResponseMessageText(result.error, resultErrorData),
        statusCode: result.meta?.response?.status,
      })
    )
  }

  return result
}
