import { useEffect, useMemo, useState } from 'react'
import { SIZE_PAGE } from '@constants'
import { getSortParamRequest } from '@helpers'
import { GridSortItem } from '@mui/x-data-grid'
import { UseMutationType } from '../types'

type ListQueryResponse<T> = {
  data: T[]
  totalCount: number
}

type FilterParam = string | undefined

export type SortType = {
  sortKey?: string
} & GridSortItem

export type ScrollingParams = {
  sort?: SortType[]
  filter?: FilterParam
  page: number
} & Record<string, unknown>

type QueryParameters = {
  size?: number
  skip?: boolean
} & Record<string, unknown>

export const useInfiniteScroll = <Payload, Return>(
  useGetDataList: UseMutationType<Payload, ListQueryResponse<Return>>,
  { size = SIZE_PAGE, skip, ...outerQueryParameters }: QueryParameters,
  transformResponse?: (data: Return[]) => Return[],
  defaultScrollingParams?: Partial<ScrollingParams>
) => {
  const [combinedData, setCombinedData] = useState<Return[]>([])

  const [scrollingParams, setScrollingParams] = useState<ScrollingParams>({
    page: 0,
    sort: undefined,
    filter: undefined,
    ...defaultScrollingParams,
  })

  const [getDataList, { data: listData, isLoading }] = useGetDataList()
  const totalPages = useMemo(
    () => (listData as ListQueryResponse<Return>)?.totalCount || 1,
    [listData]
  )

  // Поддержка сохранения параметра sort в url
  // const { sort: sortFromURL, setSort: setSortFromURL } = useSorting()
  // const sort = useMemo(() => getSortParam(scrollingParams.sort || []), [scrollingParams.sort])
  // useEffect(() => {
  //   if (sortFromURL.length && JSON.stringify(sortFromURL) !== JSON.stringify(sort)) {
  //     refresh({ sort: sortFromURL })
  //   }
  // }, [sortFromURL])

  const refresh = (
    params?: Omit<ScrollingParams, 'page'>
  ): Promise<ListQueryResponse<Return>> | undefined => {
    // const sort = params?.sort
    // const isSorting = sort && sort.length
    // setSortFromURL(isSorting ? getSortParam(sort) : undefined)
    return fetchDataList({ page: 0, ...params })
  }

  const changeSort = (sort: SortType[]) => {
    fetchDataList({ ...scrollingParams, page: 0, sort })
  }

  const fetchDataList = (
    newScrollingParams: ScrollingParams,
    newOuterParams?: Record<string, unknown>
  ): Promise<ListQueryResponse<Return>> | undefined => {
    if (!skip) {
      const outerParams = newOuterParams ? newOuterParams : outerQueryParameters

      const params = {
        size,
        ...outerParams,
        ...newScrollingParams,
        sort: getSortParamRequest(
          'sort' in newScrollingParams ? newScrollingParams.sort : scrollingParams.sort
        ),
        filter: 'filter' in newScrollingParams ? newScrollingParams.filter : scrollingParams.filter,
      } as Payload
      return getDataList({
        ...params,
      })
        .unwrap()
        .then((res: ListQueryResponse<Return>) => {
          const { data } = res as ListQueryResponse<Return>
          const nextData = transformResponse ? transformResponse(data) : data

          if (newScrollingParams.page === 0) {
            setCombinedData(nextData)
          } else {
            setCombinedData(previousData => [...previousData, ...nextData])
          }
          setScrollingParams(prev => ({ ...prev, ...newScrollingParams }))
          return res
        })
    }
  }

  useEffect(() => {
    fetchDataList({ page: 0 })
  }, [skip])

  const readMore = (params?: ScrollingParams) => {
    if (scrollingParams.page < totalPages && !skip) {
      fetchDataList({ ...scrollingParams, ...params, page: scrollingParams.page + 1 })
    }
  }

  const handleSetParams = (params: ScrollingParams) => {
    setScrollingParams(params)
    fetchDataList(params)
  }

  const refetch = (outerParams?: Record<string, unknown>) => fetchDataList({ page: 0 }, outerParams)

  return {
    combinedData,
    currentPage: scrollingParams.page,
    currentSort: scrollingParams.sort,
    currentFilter: scrollingParams.filter,
    totalPages,
    refresh,
    refetch,
    readMore,
    setCombinedDataForce: setCombinedData,
    onSetParams: handleSetParams,
    onChangeSort: changeSort,
    isLoading,
  }
}

export default useInfiniteScroll
