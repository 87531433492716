import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar, Box, Collapse, Divider, Grid, ListItemButton, Typography } from '@mui/material'

import { ExpandMore } from '@mui/icons-material'
import { useAuth } from '@hooks'

export const ProfileMenu: FC = () => {
  const [itemsShown, setItemsShown] = useState(false)
  const { username, doLogout } = useAuth()
  const { t } = useTranslation()
  const handleClick = () => setItemsShown(prev => !prev)

  return (
    <Box>
      <Box mx={2} mb={2} onClick={handleClick} sx={{ cursor: 'pointer' }}>
        <Avatar
          variant='circular'
          sx={{
            width: 35,
            height: 35,
            mb: 1,
            background: theme => theme.palette.grey[400],
          }}
        />
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Box>
              <Typography variant='body2'>{username}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <ExpandMore
              sx={{ transition: '0.1s', transform: !itemsShown ? 'rotate(-90deg)' : {} }}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Collapse in={itemsShown} timeout='auto' unmountOnExit>
        {/* {SIDEBAR_PROFILE_LINKS.map(link => (
          <Link
            sx={{ color: theme => theme.palette.text.primary, textDecoration: 'none' }}
            component={RouterLink}
            to={link.path}
            key={link.name}
          >
            <ListItemButton>{link.name}</ListItemButton>
          </Link>
        ))} */}

        <ListItemButton onClick={() => doLogout()}>{t('logout')}</ListItemButton>
      </Collapse>
    </Box>
  )
}
