import { GridColumnVisibilityModel } from '@microservices/wiskey-react-components'
import { useMemo } from 'react'

type UseFinalColumnVisibilityModelOptions = {
  isRowCreatedOrCopied: boolean
  isRowOrCellEditable: boolean
  columnVisibilityModelByRestrictions: GridColumnVisibilityModel
  columnVisibilityModel: GridColumnVisibilityModel
}

export const useFinalColumnVisibilityModel = ({
  isRowCreatedOrCopied,
  isRowOrCellEditable,
  columnVisibilityModelByRestrictions,
  columnVisibilityModel,
}: UseFinalColumnVisibilityModelOptions) => {
  return useMemo(
    () =>
      isRowCreatedOrCopied || isRowOrCellEditable
        ? columnVisibilityModelByRestrictions
        : columnVisibilityModel,
    [
      isRowCreatedOrCopied,
      isRowOrCellEditable,
      columnVisibilityModelByRestrictions,
      columnVisibilityModel,
    ]
  )
}
