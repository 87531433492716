import { createContext, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Skeleton, SxProps, Theme, Typography } from '@mui/material'
import { blue, red } from '@mui/material/colors'

import {
  Button,
  DataGrid,
  GridLoadingOverlay,
  GridHeaderWithScrollableDragging,
  GridEditModes,
} from '@microservices/wiskey-react-components'
import { PageContentLayout } from '@layouts/PageContentLayout'
import { CustomColumnMenuComponent } from './components/CustomColumnMenuComponent'
import { MultipleRowsDeletion } from './components/MultipleRowsDeletion'

import { PageContextType } from './types'
import { useConfiguredView, useFinalColumnVisibilityModel } from './hooks'
import { ConfigSettingsModal } from '@pages/ConfiguredView/components/ConfigSettingsModal'
import { ValidationErrorsButton } from '@pages/ConfiguredView/components/ValidationErrorsButton'
import { ValidationErrorsGrid } from '@pages/ConfiguredView/components/ValidationErrorsGrid'
import { USER_ROLES, VIEW_ROWS_AMOUNT, VISIBLE_HIDDEN } from '@constants'
import { useAuth } from '@hooks'
import { RowStylesWrapper } from './components/RowStylesWrapper'
import { DeleteDialog } from './components/DeleteDialog'
import { DeleteLinkedRecordDialog } from './components/DeleteLinkedRecordDialog'
import { PageTitle } from '@components/PageTitle'
import { CustomToolbarComponent } from '@pages/ConfiguredView/components/CustomToolbarComponent'
import { CustomPanelComponent } from '@pages/ConfiguredView/components/CustomPanelComponent'

const sxGrid: SxProps<Theme> = {
  '.MuiDataGrid-cell': {},
  '.MuiDataGrid-cell:focus': {},
  '.MuiDataGrid-cell:hover': {},
  '.MuiDataGrid-cell:focus-within': {},
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'none',
  },
  '& .MuiDataGrid-row--editing': {
    boxShadow: 'none',
  },
  '& .MuiDataGrid-actionsCell': {
    transform: 'rotateZ(90deg)',
  },
  '& .Mui-edit': {
    backgroundColor: theme => theme.palette.background.editRow,
    borderBottom: `1px solid ${blue[800]}`,

    '& .MuiFormControl-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: theme => theme.palette.background.errorRow,
    borderBottom: `1px solid ${red[800]}`,

    '& .MuiInputBase-root': {
      color: red[600],
      height: '100%',
    },
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
    outline: 'none',
    border: 'none',
    boxShadow: 'none !important',
    p: 0,

    '&:focus-within': {
      outline: 'none',
      border: 'none',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      outline: 'none',
      border: 'none',
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },

  '.MuiDataGrid-columnHeader': {
    '&:focus-within,': {
      outlineOffset: -2,
    },
  },
}

export type ConfiguredViewProps = {
  viewId: number
  path: string
  title: string
}

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const ConfiguredView: FC<ConfiguredViewProps> = ({ viewId, path, title }) => {
  const { t } = useTranslation()
  const { hasRole } = useAuth()
  const { handlers, state, data } = useConfiguredView({ viewId })

  const {
    handleAddRow,
    handleRowEditStop,
    handleProcessRowUpdate,
    handleShowConfigSettings,
    handleOpenShowColumnsSettings,
    handleCloseDeleteMultipleRows,
    handleDeleteMultipleRows,
    handleShowDeleteMultipleRows,
    handleSetSelectionModel,
    handleKeyDown,
    handleChangeModelSort,
    handleObjectDataPageChange,
    handleColumnWidthChange,
    handleChangeColumnVisibilityModel,
    handlePinnedColumnsChange,
    handleShowValidationGrid,
    handleDeleteRow,
    handleCancelDeleteRow,
    handleSetObjectData,
    handleCancelDeleteLinkedDialog,
    handleSetDeleteMultipleDialog,
    handleRefreshObjectData,
    handleEditRowStart,
  } = handlers

  const {
    objectData,
    currentSort,
    totalPages,
    currentPage,
    isLoadingObjectData,
    view,
    pinnedColumns,
    objectValidationErrors,
  } = data

  const {
    apiRef,
    columns,
    rowModesModel,
    gridLoading,
    isShowConfigSettings,
    isShowDeleteMultipleRows,
    isRowOrCellEditable,
    selectionModel,
    boxRef,
    deleteIdRow,
    showValidationGrid,
    showDeleteLinkedDialog,
    showDeleteMultipleDialog,
    areAllColumnsHidden,
  } = state

  const contextValue = {
    viewId,
    view,
    rows: objectData,
    totalPages,
    isShowDeleteMultipleRows,
    apiRef,
    showDeleteMultipleDialog,
    gridLoading,
    handleShowDeleteMultipleRows,
    handleDeleteMultipleRows,
    handleCloseDeleteMultipleRows,
    handleShowConfigSettings,
    handleSetDeleteMultipleDialog,
    handleRefreshObjectData,
  }

  const finalColumnVisibilityModel = useFinalColumnVisibilityModel(state)

  const isColumnsEmpty = !Object.values(finalColumnVisibilityModel).some(Boolean)

  const disableColumnResize = gridLoading
  const disableColumnReorder = gridLoading
  const disableColumnMenu = gridLoading || isRowOrCellEditable

  return (
    <PageContext.Provider value={contextValue}>
      {isShowConfigSettings && (
        <ConfigSettingsModal
          isShow={isShowConfigSettings}
          onClose={() => handleShowConfigSettings(false)}
          viewId={viewId}
          onSaveCallback={handleRefreshObjectData}
        />
      )}
      <PageTitle
        start={
          <Grid container direction={'column'}>
            <Grid item>
              <Typography pt={0.5} variant={'h6'}>
                {title ? title : <Skeleton />}
              </Typography>
            </Grid>
          </Grid>
        }
        end={
          !showValidationGrid && (
            <Grid container justifyContent={'flex-end'} mb={0.5}>
              <ValidationErrorsButton
                objectValidationErrors={objectValidationErrors}
                onClick={() => handleShowValidationGrid(true)}
              />
              {hasRole([USER_ROLES.ADMIN]) && (
                <Button
                  variant='outlined'
                  sx={{ mr: 2 }}
                  onClick={() => handleShowConfigSettings(true)}
                >
                  {t('configuredView.configBtn')}
                </Button>
              )}
              <Button
                variant='contained'
                onClick={handleAddRow}
                disabled={isLoadingObjectData || isColumnsEmpty}
              >
                {t('configuredView.addBtn')}
              </Button>
            </Grid>
          )
        }
      />

      <PageContentLayout>
        <Box
          px={4}
          py={2}
          sx={{
            ...(showValidationGrid && VISIBLE_HIDDEN),
          }}
          onKeyDown={handleKeyDown}
          ref={boxRef}
        >
          {deleteIdRow && (
            <DeleteDialog
              isShow={Boolean(deleteIdRow)}
              onSave={handleDeleteRow}
              onClose={handleCancelDeleteRow}
            />
          )}
          {showDeleteLinkedDialog && (
            <DeleteLinkedRecordDialog
              isShow={showDeleteLinkedDialog}
              onClose={handleCancelDeleteLinkedDialog}
              onSave={() => console.log('Edit Button')}
            />
          )}
          {isShowDeleteMultipleRows && <MultipleRowsDeletion />}
          <RowStylesWrapper
            currentPage={currentPage}
            pageSize={VIEW_ROWS_AMOUNT}
            objectData={objectData}
          >
            <DataGrid
              pinnedColumns={pinnedColumns}
              apiRef={apiRef}
              checkboxSelection={isShowDeleteMultipleRows}
              columns={columns}
              rows={areAllColumnsHidden ? [] : objectData}
              experimentalFeatures={{ newEditingApi: true }}
              loading={gridLoading}
              pageCount={totalPages}
              sortingMode='server'
              sortModel={currentSort}
              onSortModelChange={handleChangeModelSort}
              fetchNextPage={handleObjectDataPageChange}
              enableMultiSort
              onRowEditStop={handleRowEditStop}
              disableColumnResize={disableColumnResize}
              disableColumnReorder={disableColumnReorder}
              disableColumnMenu={disableColumnMenu}
              // disableColumnMenu={Object.keys(editRows).length > 0}
              rowModesModel={rowModesModel}
              processRowUpdate={handleProcessRowUpdate}
              onRowEditStart={handleEditRowStart}
              onSelectionModelChange={handleSetSelectionModel}
              onColumnWidthChange={handleColumnWidthChange}
              onPinnedColumnsChange={handlePinnedColumnsChange}
              disableMultipleSelection={false}
              columnVisibilityModel={finalColumnVisibilityModel}
              selectionModel={selectionModel}
              // keepNonExistentRowsSelected TODO: При подключении бэка проверить необходимость этого пропса
              components={{
                Header: GridHeaderWithScrollableDragging,
                ColumnMenu: props => (
                  <CustomColumnMenuComponent
                    {...props}
                    onShowConfig={handleOpenShowColumnsSettings}
                  />
                ),
                Toolbar: () => (
                  <CustomToolbarComponent
                    columns={columns}
                    showColumnsButton={areAllColumnsHidden}
                  />
                ),
                Panel: CustomPanelComponent,

                LoadingOverlay: () => <GridLoadingOverlay />,
              }}
              editMode={GridEditModes.Row}
              onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
              sx={sxGrid}
              isRowSelectable={() => isShowDeleteMultipleRows}
              disableVirtualization
            />
          </RowStylesWrapper>
        </Box>
        {showValidationGrid && (
          <ValidationErrorsGrid
            onSetGlobalObjectData={handleSetObjectData}
            handleShowValidationGrid={handleShowValidationGrid}
            sx={sxGrid}
          />
        )}
      </PageContentLayout>
    </PageContext.Provider>
  )
}
