import { commonAPI, TAG_TYPE } from './common.api'
import { CopyModelDataType, GETAllViewsType, GETViewType, POSTViewType } from '../../types'

export const ViewAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchViewById: build.query<GETViewType, number | string>({
      query: viewId => ({
        url: `view/id/${viewId}`,
      }),
      providesTags: [TAG_TYPE.VIEW],
    }),
    fetchAllView: build.query<GETAllViewsType[], string | undefined>({
      query: searchPattern => ({
        url: 'view/all',
        params: {
          searchPattern,
        },
      }),
      providesTags: [TAG_TYPE.ALL_VIEWS],
    }),
    createOrUpdateView: build.mutation<GETViewType, POSTViewType>({
      query: view => ({
        url: `view`,
        method: 'POST',
        body: view,
      }),
      invalidatesTags: [
        TAG_TYPE.ALL_VIEWS,
        TAG_TYPE.VIEW,
        TAG_TYPE.MENU,
        TAG_TYPE.VIEWS_IN_MENU,
        TAG_TYPE.UNUSED_VIEWS_IN_MENU,
      ],
    }),
    deleteView: build.mutation<void, number>({
      query: viewId => ({
        url: `view/id/${viewId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        TAG_TYPE.ALL_VIEWS,
        TAG_TYPE.MENU,
        TAG_TYPE.VIEWS_IN_MENU,
        TAG_TYPE.UNUSED_VIEWS_IN_MENU,
      ],
    }),
    copyView: build.mutation<void, CopyModelDataType>({
      query: ({ viewId, title }) => ({
        url: `view/copy/id/${viewId}`,
        method: 'POST',
        params: {
          title,
        },
      }),
      invalidatesTags: [TAG_TYPE.ALL_VIEWS],
    }),
  }),
})

export const {
  useFetchViewByIdQuery,
  useFetchAllViewQuery,
  useCreateOrUpdateViewMutation,
  useDeleteViewMutation,
  useCopyViewMutation,
} = ViewAPI
