export const REGEX = {
  LINE_BREAK: /[\r\n]+/g,
  SPACE: /\s+/g,
  ENGLISH_LETTERS: /^[a-zA-Z]+$/,
  RUSSIAN_LETTERS: /[\u0401\u0451\u0410-\u044f]/g,
  MODEL_CODE_REPLACE_PATTERN: /[^a-zA-Z0-9_-]/g,
  CONTAINER_ID: /(containerId):/g,
  FIELD_NAMES: /^(?:(.+?)\/\/)?(.+)$/,
  VALIDATE_INTEGER_FOR_REQUEST: /^(?:>|<|==|<=|>=)\d+$/,
  EXCLUDE_EQUAL: /^(?!.*==).*$/,
}
