import i18next from 'i18next'
import { v4 as uuid } from 'uuid'
import { MENU_CODES } from '@constants'
import { OptionType } from '../types'

export const getSidebarDefaultOptions = (isAdmin: boolean): OptionType[] => {
  return [
    ...(isAdmin
      ? [
          {
            id: uuid(),
            code: MENU_CODES.UI_CONFIGS,
            title: i18next.t('sidebar.links.uiConfigs'),
            viewId: null,
            hasChild: true,
            childrenMenu: [
              {
                id: uuid(),
                code: MENU_CODES.VIEWS,
                title: i18next.t('sidebar.links.views'),
                viewId: null,
                hasChild: false,
                childrenMenu: [],
                expanded: false,
              },
              {
                id: uuid(),
                code: MENU_CODES.MENU_SETTING,
                title: i18next.t('sidebar.links.menuSettings'),
                viewId: null,
                hasChild: false,
                childrenMenu: [],
                expanded: false,
              },
            ],
            expanded: false,
          },
        ]
      : []),
  ]
}
