import { FC } from 'react'

import {
  GridColumnMenuProps,
  GridColumnMenuContainer,
  HideGridColMenuItem,
  GridColumnsMenuItem,
  GridColumnPinningMenuItems,
} from '@microservices/wiskey-react-components'

type CustomColumnMenuComponentProps = {
  onShowConfig: (value: boolean) => void
} & GridColumnMenuProps

export const CustomColumnMenuComponent: FC<CustomColumnMenuComponentProps> = props => {
  const { currentColumn, hideMenu, onShowConfig } = props

  return (
    <GridColumnMenuContainer {...props}>
      <HideGridColMenuItem
        column={currentColumn}
        onClick={e => {
          hideMenu(e)
        }}
      />
      <GridColumnsMenuItem
        column={currentColumn}
        onClick={e => {
          hideMenu(e)
          console.log(currentColumn)
          onShowConfig(true)
        }}
      />
      <GridColumnPinningMenuItems
        column={currentColumn}
        onClick={e => {
          hideMenu(e)
          console.log(currentColumn)
        }}
      />
    </GridColumnMenuContainer>
  )
}
