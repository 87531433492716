import { createContext, FC } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, Button, Grid, Typography } from '@mui/material'
import { FormGenerator } from '@microservices/wiskey-react-components'
import { ConfigSettingsModal } from '@pages/ConfiguredView/components/ConfigSettingsModal'
import { PageContentLayout } from '@layouts/PageContentLayout'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { useViewCreateOrEdit } from './hooks'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const ViewCreateOrEdit: FC = () => {
  const { t } = useTranslation()

  const { state, data, handlers } = useViewCreateOrEdit()

  const { isShowConfigSettings, formIsLoading } = state

  const { view, type, objectOptions, isEdit, methods } = data

  const { handleSaveView, handleCancel, handleShowConfigSettings } = handlers

  const contextValue: PageContextType = {}

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods

  return (
    <PageContext.Provider value={contextValue}>
      <PageContentLayout>
        <Box sx={{ width: '100%' }}>
          {view?.viewId && isShowConfigSettings && (
            <ConfigSettingsModal
              isShow={isShowConfigSettings}
              onClose={() => handleShowConfigSettings(false)}
              viewId={view.viewId}
            />
          )}
          <form>
            <FormProvider {...methods}>
              <Box p={3}>
                <Grid container mb={2} mt={2} justifyContent={'space-between'}>
                  <Grid item>
                    <Typography variant='h5'>{t(`viewCreateOrEdit.title.${type}`)}</Typography>
                  </Grid>
                  {isEdit && (
                    <Grid item>
                      <Button variant='outlined' onClick={() => handleShowConfigSettings(true)}>
                        {t('configuredView.configBtn')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <FormGenerator
                  inputs={[
                    {
                      name: 'viewTitle',
                      inputType: GENERATOR_INPUT_TYPE.INPUT,
                      placeholder: t('viewCreateOrEdit.placeholder.viewTitle'),
                      label: t('viewCreateOrEdit.label.viewTitle'),
                      rules: { required: true, validate: value => value.trim().length !== 0 },
                    },
                    {
                      name: 'object',
                      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                      placeholder: t('viewCreateOrEdit.placeholder.object'),
                      label: t('viewCreateOrEdit.label.object'),
                      autocompleteOptions: objectOptions,
                      rules: {
                        required: true,
                      },
                      disabled: isEdit,
                    },
                  ]}
                  loading={formIsLoading}
                />
              </Box>
            </FormProvider>
          </form>
          <Grid container justifyContent={'flex-end'}>
            <Box p={3}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button variant='outlined' onClick={handleCancel}>
                    {t('button.cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={!isDirty}
                    variant='contained'
                    onClick={handleSubmit(handleSaveView)}
                  >
                    {t('button.save')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </PageContentLayout>
    </PageContext.Provider>
  )
}
