import { FC, useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'

import { Button, FormInput } from '@microservices/wiskey-react-components'

import { PageContext } from '@pages/ConfigViewList'
import { ViewList } from '@components/ViewList'
import { VIEW_LIST_COLUMNS, GENERATOR_INPUT_TYPE, MODAL_TYPE } from '@constants'

import { AutocompleteOption } from '../../../../types'

type FormValues = {
  searchPattern: string
  objectCode: AutocompleteOption | null
}

const defaultValues = {
  searchPattern: '',
  objectCode: null,
}

export const AllViews: FC = () => {
  const methods = useForm<FormValues>({ defaultValues })
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    entitiesTables,
    viewsIsLoading,
    onDeleteView,
    onShowDialog,
    onEditView,
    // onSetSorting,
    // currentSort,
    // objects,
    onSetObjectCode,
    onSetSearchPattern,
  } = useContext(PageContext)

  const { watch } = methods
  const watchObjectCode = watch('objectCode')
  const watchSearchPattern = watch('searchPattern')

  useEffect(() => {
    onSetObjectCode(watchObjectCode !== null ? (watchObjectCode.id as string) : '')
  }, [watchObjectCode])

  useEffect(() => {
    onSetSearchPattern(watchSearchPattern)
  }, [watchSearchPattern])

  return (
    <>
      <Grid
        item
        gap={1}
        container
        flexWrap='nowrap'
        xs={12}
        sm={12}
        mb={2}
        justifyContent='start'
        alignItems='center'
      >
        <Grid item>
          <Button sx={{ mb: 1 }} onClick={() => navigate(MODAL_TYPE.CREATE)} variant={'contained'}>
            {t(`configViewList.addButton`)}
          </Button>
        </Grid>
        <Grid item>
          <FormProvider {...methods}>
            <form>
              <FormInput
                sx={{ pl: 10, mb: 0 }}
                name='searchPattern'
                inputType={GENERATOR_INPUT_TYPE.INPUT}
                size='small'
                placeholder={t(`configViewList.search`)}
              />

              {/*<Grid item xs={6} sm={6}>*/}
              {/*  <FormInput*/}
              {/*    name='objectCode'*/}
              {/*    sx={{ maxWidth: '100%' }}*/}
              {/*    inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}*/}
              {/*    autocompleteOptions={objects?.map(obj => ({*/}
              {/*      id: obj.code,*/}
              {/*      label: obj.title,*/}
              {/*    }))}*/}
              {/*    size='small'*/}
              {/*    placeholder={t(`configEntity.view.object`)}*/}
              {/*  />*/}
              {/*</Grid>*/}
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      <ViewList
        columns={VIEW_LIST_COLUMNS}
        rows={entitiesTables}
        onDelete={onDeleteView}
        loading={viewsIsLoading}
        onCopy={onShowDialog}
        onEdit={onEditView}
        // currentSort={currentSort}
        // onSetSorting={onSetSorting}
        // type={type}
      />
    </>
  )
}
