import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'

import { Box, Grid, Typography } from '@mui/material'

import {
  ConfirmModal,
  FormLabel,
  ModalWrapper,
  TextField,
} from '@microservices/wiskey-react-components'
import {
  useFetchObjectWithConfiguredRestrictionsQuery,
  useUpdateConfigSettingsRestrictionsMutation,
} from '@redux/api/restriction.api'
import { ConfigSettingsTitle } from '@pages/ConfiguredView/components/ConfigSettingsModal/components/ConfigSettingsTitle'
import { RestrictionsForm } from '@pages/ConfiguredView/types'
import { transformRestrictionsFieldsForSending } from '@pages/ConfiguredView/helpers'
import { getDirtyValues } from '@helpers'

import { ConfigSettingsItem } from './components/ConfigSettingsItem'
import { POSTRestriction } from '../../../../types'
import { mergeBaseAndUserRequired } from '../../../../helpers/mergeBaseAndUserRequired'

type ConfigSettingsProps = {
  isShow: boolean
  onClose: () => void
  viewId: number
  onSaveCallback?: () => void
}

export const ConfigSettingsModal: FC<ConfigSettingsProps> = ({
  isShow,
  onClose,
  viewId,
  onSaveCallback,
}) => {
  const { t } = useTranslation()

  const { data: objectWithRestrictions, isFetching: isFetchingRestrictions } =
    useFetchObjectWithConfiguredRestrictionsQuery(viewId, { skip: !viewId })

  const [updateRestrictions, { isLoading: isUpdatingRestrictions }] =
    useUpdateConfigSettingsRestrictionsMutation()

  const [search, setSearch] = useState('')
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [formIsCompleted, setFormCompleted] = useState(false)

  const paramsMethods = useForm<RestrictionsForm>()

  const {
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = paramsMethods

  const handleSaveBtnClick = handleSubmit(data => {
    const postRestrictions: POSTRestriction[] = transformRestrictionsFieldsForSending(
      getDirtyValues(dirtyFields, data),
      viewId
    )
    updateRestrictions(postRestrictions).then(() => {
      onSaveCallback?.()
    })
  })

  const handleChangeSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    if (objectWithRestrictions) {
      const defaultValues = objectWithRestrictions.columns.map(
        ({
          field,
          enabled,
          baseRequired,
          userRequired,
          unique,
          symbolsNumLimit,
          embeddedFields,
        }) => [
          field,
          {
            enabled,
            userRequired: mergeBaseAndUserRequired({ baseRequired, userRequired }),
            unique,
            symbolsNumLimit: symbolsNumLimit?.toString() ?? '',
            embeddedFieldsEnable: Object.fromEntries(
              embeddedFields?.map(({ name, enabled }) => [name, enabled]) || []
            ),
          },
        ]
      )

      reset(Object.fromEntries(defaultValues))
      setFormCompleted(true)
    }
  }, [objectWithRestrictions])

  const filteredColumns = useMemo(
    () =>
      objectWithRestrictions?.columns.filter(({ field }) =>
        field.toLowerCase().includes(search.toLowerCase())
      ) || [],
    [objectWithRestrictions?.columns, search]
  )

  const handleCloseConfigSettings = (isDirty: boolean) => {
    if (isDirty) {
      setShowCancelModal(true)

      return
    }

    setShowCancelModal(false)
    onClose()
    reset()
  }

  const handleCloseConfirmModal = () => {
    setShowCancelModal(false)
  }

  return (
    <>
      <ConfirmModal
        title={t('modal.cancelMenuChanges.title')}
        text={t('modal.cancelMenuChanges.text')}
        actionBtnText={t('modal.cancelBtnText')}
        cancelBtnText={t('modal.closeBtnText')}
        isShow={showCancelModal}
        onClose={handleCloseConfirmModal}
        onConfirm={() => handleCloseConfigSettings(false)}
      />
      <ModalWrapper
        hideDivider
        isShow={isShow}
        onClose={() => handleCloseConfigSettings(isDirty)}
        onSave={handleSaveBtnClick}
        disabledSave={!isDirty}
        backdropLoading={isFetchingRestrictions || isUpdatingRestrictions}
        focusSave
        focusCancel
      >
        <Box sx={{ height: 500, width: 700, overflowY: 'auto', pr: 2 }}>
          <Typography variant={'h6'} mb={3}>
            {t('configSettings.modalTitle')}
          </Typography>
          <Box sx={{ mb: 3 }}>
            <FormLabel
              name={'search'}
              label={t('configSettings.searchInput.label')}
              labelPlacement='top'
              labelSx={{ fontSize: 12 }}
              control={
                <TextField
                  onChange={handleChangeSearch}
                  value={search}
                  name={'search'}
                  variant='standard'
                  fullWidth
                  placeholder={t('configSettings.searchInput.placeholder')}
                />
              }
            />
          </Box>

          <Grid container>
            <ConfigSettingsTitle />
            <FormProvider {...paramsMethods}>
              {formIsCompleted &&
                filteredColumns.map(column => (
                  <ConfigSettingsItem key={column.field} column={column} />
                ))}
            </FormProvider>
          </Grid>
        </Box>
      </ModalWrapper>
    </>
  )
}
