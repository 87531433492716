import { duration } from '@mui/material'
import { RefObject, useEffect } from 'react'

type ScollToItemProps = {
  ref: RefObject<HTMLDivElement>
  isScroll: boolean
}

type ReturnScrollToItem = {
  scrollToItem: () => void
}

export const useScrollToItem = ({ isScroll, ref }: ScollToItemProps): ReturnScrollToItem => {
  const scrollToItem = () => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }

  useEffect(() => {
    if (isScroll) {
      setTimeout(() => scrollToItem(), duration.shorter)
    }
  }, [isScroll, ref.current])

  return { scrollToItem }
}
