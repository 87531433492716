import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useCreateOrUpdateViewMutation, useFetchViewByIdQuery } from '@redux/api'

import { FormValues } from '@pages/ViewCreateOrEdit/types'
import { ROUTES } from '@constants'

export const useHandlers = () => {
  const navigate = useNavigate()
  const { id = '' } = useParams()
  const isEdit = Boolean(id)

  const [isShowConfigSettings, setShowConfigSettings] = useState(false)

  const { data: view, isFetching: isFetchingView } = useFetchViewByIdQuery(id, { skip: !id })
  const formIsLoading = isFetchingView

  const [createOrUpdateView] = useCreateOrUpdateViewMutation()

  const handleCancel = () => {
    navigate(ROUTES.VIEWS)
  }

  const handleSaveView = ({ viewId, viewTitle, object }: FormValues) => {
    if (!object) {
      return
    }

    if (isEdit) {
      createOrUpdateView({
        viewId,
        viewTitle,
        objectCode: object.id,
      })
      return
    }
    createOrUpdateView({
      viewTitle,
      objectCode: object.id,
    })
      .unwrap()
      .then(res => {
        navigate(`${ROUTES.VIEWS_EDIT}/${res.viewId}`)
      })
  }

  const handleShowConfigSettings = (value: boolean) => {
    setShowConfigSettings(value)
  }

  return {
    state: { formIsLoading, isShowConfigSettings },
    data: { view },
    handlers: { handleCancel, handleSaveView, handleShowConfigSettings },
  }
}
