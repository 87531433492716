import { OptionType } from 'src/types'
import { findNavOptionParent } from './findNavOptionParent'

export const getNavOptionCodesToExpand = (
  navOptions: OptionType[],
  initialNavOption: OptionType
): string[] => {
  let output: string[] = []
  const parentMenu = findNavOptionParent(navOptions, initialNavOption)

  if (!parentMenu) {
    return []
  }

  output.push(parentMenu.code)

  const nextParent = getNavOptionCodesToExpand(navOptions, parentMenu)

  output = output.concat(nextParent)

  return output
}
