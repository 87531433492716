import { MIN_DRAWER_WIDTH, MAX_DRAWER_WIDTH } from '@constants'
import { useState, useCallback } from 'react'

export const useMenuResize = () => {
  const [drawerWidth, setDrawerWidth] = useState(MIN_DRAWER_WIDTH)

  const handleMouseDown = () => {
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback((e: MouseEvent) => {
    e.preventDefault()
    const newWidth = e.clientX - document.body.offsetLeft
    if (newWidth <= MIN_DRAWER_WIDTH) {
      setDrawerWidth(MIN_DRAWER_WIDTH)
    }
    if (newWidth > MIN_DRAWER_WIDTH && newWidth < MAX_DRAWER_WIDTH) {
      setDrawerWidth(newWidth)
    }
  }, [])

  return {
    onMouseDown: handleMouseDown,
    drawerWidth,
  }
}
