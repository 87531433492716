import { ObjectDataRecord } from 'src/types'
import { GridColumnType } from '../hooks'
import { ROW_ID } from '@constants'

export const getCopiedRow = (row: ObjectDataRecord, columns: GridColumnType[]) => {
  const result: Omit<ObjectDataRecord, 'id'> = {}

  columns.forEach(({ field }) => {
    const value = row[field]
    const isRefField = field.includes('_ref')

    if (field === ROW_ID) {
      return
    }

    if (isRefField) {
      result[field] = value ? { id: value, label: value } : null
      return
    }

    result[field] = value
  })

  return result
}
