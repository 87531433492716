import { FC } from 'react'
import { TooltipProps, Tooltip, tooltipClasses, styled } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    whiteSpace: 'normal !important',
  },
}))

type ErrorIconWithTooltipProps = {
  errorMessage?: string | null
}

export const ErrorIconWithTooltip: FC<ErrorIconWithTooltipProps> = ({ errorMessage }) => {
  return errorMessage ? (
    <StyledTooltip title={errorMessage}>
      <ErrorOutline color='error' fontSize='small' />
    </StyledTooltip>
  ) : null
}
