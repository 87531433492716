import { OptionType } from 'src/types'

export const findNavOptionByCode = (
  navOptions: OptionType[],
  code: string,
  searchByViewId?: boolean
): OptionType | null => {
  let result: OptionType | null = null

  navOptions.forEach(option => {
    if (result) {
      return
    }

    if (searchByViewId) {
      if (option.viewId?.toString() === code) {
        result = option

        return
      }
    }

    if (option.code === code) {
      result = option

      return
    }

    if (option.childrenMenu.length) {
      result = findNavOptionByCode(option.childrenMenu, code, searchByViewId)
    }
  })

  return result
}
