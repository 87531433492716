import { createContext, FC, ReactNode } from 'react'
import { Box, Container } from '@mui/material'

import { Sidebar } from '@components/Sidebar'
import { AppBar } from '@components/AppBar'
import { useSidebar } from '@hooks'

type AppLayoutContextType = {
  sidebarOpen: boolean
}

type AppLayoutProps = {
  children: ReactNode
}

export const AppLayoutContext = createContext<AppLayoutContextType>({
  sidebarOpen: true,
})

export const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const {
    sidebarOpen,
    navOptions,
    handleOpenSidebar,
    handleMPress,
    searchValue,
    handleChangeSearchValue,
  } = useSidebar()

  return (
    <AppLayoutContext.Provider value={{ sidebarOpen }}>
      <AppBar open={sidebarOpen} setOpen={handleOpenSidebar} />
      <Sidebar
        options={navOptions}
        open={sidebarOpen}
        setOpen={handleOpenSidebar}
        handleMPress={handleMPress}
        searchValue={searchValue}
        handleChangeSearchValue={handleChangeSearchValue}
      >
        <Box id='page-container'>
          <Container maxWidth={false}>{children}</Container>
        </Box>
      </Sidebar>
    </AppLayoutContext.Provider>
  )
}
