import { AutocompleteOption } from 'src/types'
import { getParsedFields } from './getParsedFields'
import { FIELDS_META_DATE_ROW } from '@constants'

export const getRowWithTransformRefFields = (
  row: Record<string, string | AutocompleteOption | number>
) => {
  const result: Record<string, number | string | null> = {}

  Object.keys(row).forEach(field => {
    const { fieldName: initalFieldName, parentFieldName } = getParsedFields(field)
    const value = row[field]
    const isRefField = initalFieldName.includes('_ref')
    const isMetaDateField =
      initalFieldName === FIELDS_META_DATE_ROW.CREATED ||
      initalFieldName === FIELDS_META_DATE_ROW.UPDATED
    const hasNotParentFieldInRow =
      parentFieldName && !row[parentFieldName] && row[parentFieldName] !== 0

    if (!isRefField && hasNotParentFieldInRow && !isMetaDateField) {
      return
    }

    result[field] = value && typeof value === 'object' ? value.label : value
  })

  return result
}
