import {
  ChangeEvent,
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import isEqual from 'lodash.isequal'

import { Box } from '@mui/material'

import {
  Checkbox,
  DateTimePicker,
  GridRenderEditCellParams,
  TextField,
  useGridApiContext,
} from '@microservices/wiskey-react-components'

import { ErrorRow } from '@pages/ConfiguredView/hooks'
import { getLinkedObjectCode } from '@pages/ConfiguredView/helpers'
import { LinkedObjectAutocomplete } from '@components/LinkedObjectAutocomplete'
import { FIELD_VALUE_TYPE, ROW_ID } from '@constants'
import { AutocompleteOption, ErrorDescriptionRows } from 'src/types'
import { ViewColumn } from '../../../../types/view'
import { getParsedFields } from '@pages/ConfiguredView/helpers/getParsedFields'
import { ErrorIconWithTooltip } from '../ErrorIconWithTooltip'
import { useFetchObjectDataMutation } from '@redux/api'

export type EditCellGeneratorProps = {
  isConfirmSave: boolean
  error?: ErrorRow
  onUpdateConfirmSave?: (value: boolean) => void
  onSetError: Dispatch<SetStateAction<ErrorDescriptionRows | null>>
  column: ViewColumn
  onSetDirtyFields: Dispatch<SetStateAction<Record<string, boolean>>>
} & GridRenderEditCellParams

export const EditCellGenerator: FC<EditCellGeneratorProps> = ({
  isConfirmSave,
  error,
  onUpdateConfirmSave,
  onSetError,
  column,
  onSetDirtyFields,
  ...params
}) => {
  const { id, value, field, row } = params
  const { valueType } = column
  const creationInProgress = Boolean(row.isNew)
  const [initialValue, setInitialValue] = useState(value)
  const [initialValueLinkedObject, setInitialValueLinkedObject] = useState(null)
  const [isFirstRender, setFirstRender] = useState(true)
  const apiRef = useGridApiContext()
  const columnIndex = useMemo(() => apiRef.current?.getColumnIndex(field), [field])
  const errorMessage = error && error[field]
  const showError = error && error[field] && (!isFirstRender || isConfirmSave)
  const linkedObjectCode = useMemo(() => getLinkedObjectCode(column), [column])
  const { parentFieldName } = getParsedFields(field)
  const isDisable = field === ROW_ID
  // const isDisable = Boolean(parentFieldName) && creationInProgress
  const [fetchObjectData] = useFetchObjectDataMutation()

  const datePickerInputRef = useRef<HTMLInputElement>(null)

  // usePrompt({ when: value !== initialValue })

  // useEffect(() => {
  //   switch (valueType) {
  //     case FIELD_VALUE_TYPE.STRING:
  //     case FIELD_VALUE_TYPE.INTEGER:
  //     case FIELD_VALUE_TYPE.DATETIME:
  //       apiRef.current.setEditCellValue({ id, field, value })
  //       break
  //     case FIELD_VALUE_TYPE.OBJ_EMBEDDED:
  //       apiRef.current.setEditCellValue({ id, field, value: value || null })
  //       break
  //     default:
  //   }
  // }, [])

  useEffect(() => {
    if (!initialValueLinkedObject && initialValue === null) {
      setInitialValueLinkedObject(initialValue)
      return
    }

    if (!initialValueLinkedObject && typeof value === 'object') {
      setInitialValueLinkedObject(value)
      return
    }
  }, [value])

  const resetValidate = (field: string) => {
    const copyError = { ...error }
    delete copyError[field]

    if (!Object.keys(copyError).length) {
      onUpdateConfirmSave?.(false)
      setFirstRender(false)
    }
    // Для валидации строки при создании нужен id, т.к. нет _id
    onSetError(prev => ({ ...prev, [row._id || id]: copyError }))
  }

  const handleChangeTextField = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    apiRef.current.setEditCellValue({ id, field, value })
    onSetDirtyFields(prev => ({ ...prev, [field]: value !== initialValue }))

    if (error?.[field]) {
      resetValidate(field)
    }
  }

  const handleChangeDatetimePicker = (value: string) => {
    apiRef.current.setEditCellValue({ id, field, value })
    onSetDirtyFields(prev => ({ ...prev, [field]: value !== initialValue }))
    if (error?.[field]) {
      resetValidate(field)
    }
  }

  const handleChangeLinkedObjectAutocomplete = (newValue: AutocompleteOption<string> | null) => {
    // apiRef.current.setEditCellValue({ id, field, value: value || null })
    const { parentFieldName, fieldName } = getParsedFields(params.field)
    const hasParentField = Boolean(column.parentField)

    const refFields = Object.keys(params.row).flatMap(refField => {
      const { parentFieldName: refParentFieldName, fieldName: refFieldName } =
        getParsedFields(refField)

      const refFieldHasParentField = Boolean(refParentFieldName)

      // country_ref//name и country_ref//remark
      if (hasParentField && parentFieldName === refParentFieldName) {
        return [refField]
      }
      // country_ref//name и country_ref
      if (hasParentField && !refFieldHasParentField && parentFieldName === refFieldName) {
        return [refField]
      }
      // country_ref и country_ref//name
      if (!hasParentField && fieldName === refParentFieldName) {
        return [refField]
      }
      // country_ref и country_ref
      if (!hasParentField && !refFieldHasParentField && fieldName === refFieldName) {
        return [refField]
      }

      return []
    })

    if (newValue?.id) {
      fetchObjectData({ objectCode: linkedObjectCode, objectId: newValue.id })
        .unwrap()
        .then(data => {
          refFields.forEach(refField => {
            const { parentFieldName, fieldName } = getParsedFields(refField)
            onSetDirtyFields(prev => ({
              ...prev,
              [refField]: !isEqual(newValue, initialValueLinkedObject),
            }))

            apiRef.current.setEditCellValue({
              id,
              field: refField,
              value: {
                id: newValue.id,
                label: (parentFieldName ? data[fieldName] : newValue.id) || '',
              },
            })
          })
        })
    }
    if (newValue === null) {
      refFields.forEach(refField => {
        onSetDirtyFields(prev => ({
          ...prev,
          [refField]: !isEqual(newValue, initialValueLinkedObject),
        }))

        apiRef.current.setEditCellValue({
          id,
          field: refField,
          value: null,
        })
      })
    }

    if (error?.[field]) {
      resetValidate(field)
    }
  }

  const handleChangeCheckbox = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    apiRef.current.setEditCellValue({ id, field, value: checked })
    onSetDirtyFields(prev => ({ ...prev, [field]: checked !== initialValue }))

    if (error?.[field]) {
      resetValidate(field)
    }
  }

  const handleDatePickerClose = () => {
    setTimeout(() => {
      if (datePickerInputRef.current) {
        datePickerInputRef.current.focus()
      }
    }, 1)
  }

  const renderField = (): ReactElement => {
    const fieldAutoFocus = (creationInProgress && columnIndex === 0) || params.hasFocus
    const errorEndAdornment = (
      <Box sx={{ position: 'relative', left: '8px' }}>
        <ErrorIconWithTooltip errorMessage={errorMessage} />
      </Box>
    )

    // Для редактирования вложенных ссылочных полей, имеющих родительское поле
    if (column.parentField) {
      return (
        <LinkedObjectAutocomplete
          editCellParams={params}
          objectCode={linkedObjectCode}
          value={value}
          valueType={column.valueType}
          field={column.field}
          type={column.valueType}
          onChange={handleChangeLinkedObjectAutocomplete}
          autoFocus={fieldAutoFocus}
          disabled={isDisable}
          disablePortal
          inputProps={{
            disabled: isDisable,
          }}
        />
      )
    }

    switch (valueType) {
      case FIELD_VALUE_TYPE.STRING:
        return (
          <TextField
            value={value}
            onChange={handleChangeTextField}
            fullWidth
            autoFocus={fieldAutoFocus}
            InputProps={{
              endAdornment: errorEndAdornment,
            }}
            disabled={isDisable}
          />
        )
      case FIELD_VALUE_TYPE.INTEGER:
        return (
          <TextField
            value={value}
            onChange={handleChangeTextField}
            fullWidth
            type={'number'}
            focused={true}
            autoFocus={fieldAutoFocus}
            InputProps={{
              endAdornment: errorEndAdornment,
            }}
            disabled={isDisable}
          />
        )
      case FIELD_VALUE_TYPE.DATETIME:
        return (
          <DateTimePicker
            value={value}
            onChange={handleChangeDatetimePicker}
            onChangeInput={handleChangeDatetimePicker}
            fullWidth
            size='medium'
            inputRef={datePickerInputRef}
            onClose={handleDatePickerClose}
            autoFocus={fieldAutoFocus}
            OpenPickerButtonProps={{ onKeyDown: e => e.stopPropagation() }}
            disabled={isDisable}
            InputProps={{
              disabled: isDisable,
            }}
          />
        )
      // Для редактирования ссылочных родительских полей
      case FIELD_VALUE_TYPE.OBJ_EMBEDDED:
      case FIELD_VALUE_TYPE.OBJ_PK_LINK:
      case FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK:
        return (
          <LinkedObjectAutocomplete
            editCellParams={params}
            objectCode={linkedObjectCode}
            value={value}
            valueType={column.valueType}
            onChange={handleChangeLinkedObjectAutocomplete}
            autoFocus={fieldAutoFocus}
            disabled={isDisable}
            disablePortal
            inputProps={{
              endAdornment: errorEndAdornment,
              disabled: isDisable,
            }}
          />
        )
      case FIELD_VALUE_TYPE.BOOLEAN:
        return (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Checkbox
              checked={Boolean(value)}
              onChange={handleChangeCheckbox}
              disabled={isDisable}
              autoFocus={fieldAutoFocus}
            />
          </Box>
        )
      default:
        return <></>
    }
  }

  return (
    <Box className={showError ? 'Mui-error' : 'Mui-edit'} width={'100%'} height={'100%'}>
      {renderField()}
    </Box>
  )
}
