import { GridColumns } from '@mui/x-data-grid'
import { ShortViewDTO } from 'src/types'
import i18next from 'i18next'

export const VIEW_ROWS_AMOUNT = 10
export const SIZE_PAGE = 50

export enum COLUMNS {
  ACTIONS = 'actions',
}

export const VIEW_LIST_COLUMNS: GridColumns<ShortViewDTO> = [
  {
    field: 'viewId',
    headerName: i18next.t('configViewList.columns.viewId'),
  },
  {
    field: 'viewTitle',
    headerName: i18next.t('configViewList.columns.viewTitle'),
    flex: 1,
  },
  // {
  //   field: 'objectCode',
  //   headerName: i18next.t('viewListColumns.objectCode'),
  //   flex: 1,
  // },
  {
    field: 'objectTitle',
    headerName: i18next.t('configViewList.columns.objectTitle'),
    flex: 1,
  },
]
