import { ObjectDataRecordError } from 'src/types'

export const getErrorDescriptionByField = (
  errors: ObjectDataRecordError[]
): Record<string, string> => {
  const result: Record<string, string> = {}

  errors.forEach(({ fieldName, errorDescription }) => {
    if (!result[fieldName]) {
      result[fieldName] = errorDescription
    } else {
      result[fieldName] += `\n${errorDescription}`
    }
  })

  return result
}
