import { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { t } from 'i18next'
import { useCopyViewMutation, useDeleteViewMutation } from '@redux/api'

import { ROUTES } from '@constants'
import { CopyModelDataType, DataOfCopiedModelType, ShortViewDTO } from '../../../types'

export const useHandlers = () => {
  const navigate = useNavigate()
  const [objectCode, setObjectCode] = useState<string>()
  const [searchPattern, setSearchPattern] = useState<string>()
  const [currentTab, setCurrentTab] = useState(0)
  const [isDirty, setDirty] = useState(false)
  const [allEntities, setAllEntities] = useState<ShortViewDTO[]>([])
  const [showCopyDialog, setShowCopyDialog] = useState(false)
  const [copyViewData, setCopyViewData] = useState<DataOfCopiedModelType | null>(null)

  const [copyView, { isLoading: isLoadingCopyView }] = useCopyViewMutation()
  const [deleteView] = useDeleteViewMutation()

  const handleDeleteView = (id: number) => deleteView(id)

  const handleEditView = (viewId: number) => {
    navigate(`${ROUTES.VIEWS_EDIT}/${viewId}`)
  }

  const handleSetObjectCode = (code: string) => setObjectCode(code)

  const handleSetSearchPattern = (pattern: string) => {
    setSearchPattern(pattern)
  }

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    if (isDirty && confirm(t('notifications.leave'))) {
      setCurrentTab(newValue)
      setDirty(false)

      return
    }

    if (!isDirty) {
      setCurrentTab(newValue)
    }
  }

  const handleSetDirty = (value: boolean) => setDirty(value)

  const handleSetAllEntities = (value: ShortViewDTO[]) => setAllEntities(value)

  const handleShowDialog = (data: DataOfCopiedModelType) => {
    setCopyViewData(data)
    setShowCopyDialog(true)
  }

  const handleCloseDialog = () => {
    setCopyViewData(null)
    setShowCopyDialog(false)
  }

  const handleCopyView = (data: CopyModelDataType) => {
    const { viewId, title } = data
    copyView({ viewId, title })
      .unwrap()
      .then(newViewId => {
        setShowCopyDialog(false)
        navigate(`${ROUTES.VIEWS_EDIT}/${newViewId}`)
      })
  }

  return {
    state: {
      objectCode,
      searchPattern,
      currentTab,
      isDirty,
      allEntities,
      showCopyDialog,
      copyViewData,
    },
    data: {
      isLoadingCopyView,
    },
    handlers: {
      handleDeleteView,
      handleEditView,
      handleSetObjectCode,
      handleSetSearchPattern,
      handleSetDirty,
      handleSetAllEntities,
      handleTabChange,
      handleShowDialog,
      handleCloseDialog,
      handleCopyView,
    },
  }
}
