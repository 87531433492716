import { useEffect, useMemo, useRef, useState } from 'react'
import { t } from 'i18next'
import { Grid, useTheme } from '@mui/material'
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Close,
  ContentCopy as ContentCopyIcon,
  DeleteForever as DeleteForeverIcon,
  Done,
} from '@mui/icons-material'

import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridEnrichedColDef,
  GridPinnedColumns,
  GridRenderEditCellParams,
  GridRowModes,
  GridValueGetterParams,
  PINNED_COLUMN,
  useGridApiRef,
} from '@microservices/wiskey-react-components'
import { EditCellGenerator } from '../components/EditCellGenerator'
import { useHandlers } from './useHandlers'

import { EVENT_NAME } from '@events'
import { useKeyPress, usePrompt, useSubscribeEvent } from '@hooks'
import { useFetchViewByIdQuery } from '@redux/api'
import {
  checkRowEditMode,
  getCellError,
  getColumnFieldName,
  getColumnVisibilityByRestrictions,
  getDataGridElementClassName,
  getDotFormatIfReference,
  isNotValidFieldRefId,
  sortColumnsByOrder,
  transformFixRestrictions,
} from '@pages/ConfiguredView/helpers'
import { UseConfiguredViewOptions } from '@pages/ConfiguredView/types'
import {
  FIELD_VALUE_TYPE,
  KEYBOARD_EVENT_PROPS,
  KEYS_CODES,
  MIN_VIEW_COLUMN_WIDTH,
} from '@constants'
import { CellGenerator } from '@pages/ConfiguredView/components/CellGenerator'
import { ObjectDataRecord, RESTRICTION_TYPE, ViewColumn } from '../../../types'
import { useFetchRestrictionsByViewIdQuery } from '@redux/api/restriction.api'
import { useFetchViewValidationErrorsQuery } from '@redux/api/validate.api'
import { mergeBaseAndUserRequired } from '../../../helpers/mergeBaseAndUserRequired'
import { useHandleColumnDrag } from '@pages/ConfiguredView/hooks/useHandleColumnDrag'

export type ErrorRow = Record<string, string> | null

export type GridColumnType = GridColDef & {
  required: boolean
  order: number
  type: FIELD_VALUE_TYPE
  pinnedColumn?: PINNED_COLUMN | null
}

export const useConfiguredView = ({ viewId }: UseConfiguredViewOptions) => {
  const apiRef = useGridApiRef()
  const theme = useTheme()
  const boxRef = useRef<HTMLDivElement>(null)
  const [columns, setColumns] = useState<GridColumnType[]>([])
  const [dirtyFields, setDirtyFields] = useState({})
  const isDirty = useMemo(() => Object.values(dirtyFields).some(Boolean), [dirtyFields])
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({})
  const [columnVisibilityModelByRestrictions, setColumnVisibilityModelByRestrictions] =
    useState<GridColumnVisibilityModel>({})
  const [initialColumnVisibilityModel, setInitialColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({})

  const areAllColumnsHidden = useMemo(
    () => Object.values(columnVisibilityModel).every(visible => !visible),
    [columnVisibilityModel]
  )

  const { data: view, isFetching: isFetchingView } = useFetchViewByIdQuery(viewId)
  const { data: pinnedColumnsRestrictions } = useFetchRestrictionsByViewIdQuery({
    viewId,
    restrictionType: RESTRICTION_TYPE.FIX,
  })
  const { data: objectValidationErrors } = useFetchViewValidationErrorsQuery(viewId)

  const handleSetColumnsVisibilityModel = (value: GridColumnVisibilityModel) =>
    setColumnVisibilityModel(value)

  const handleSetDirtyFields = (value: Record<string, boolean>) => setDirtyFields(value)

  const { handlers, state, data } = useHandlers({
    viewId,
    apiRef,
    initialColumnVisibilityModel,
    onSetColumnVisibilityModel: handleSetColumnsVisibilityModel,
    onSetDirtyFields: handleSetDirtyFields,
    columns,
    areAllColumnsHidden,
    isFetchingView,
  })

  const {
    handleRefreshObjectData,
    handleSetObjectData,
    handleSetConfirmSave,
    handleSetError,
    handleSelectClick,
    handleCopyRow,
    handleDeleteRow,
    handleDeleteClick,
    handleSubmitEditRow,
    handleCloseEditRow,
    handleAddRow,
  } = handlers

  const { errors, isConfirmSave, rowModesModel, hasEditableRowModes, isShowDeleteMultipleRows } =
    state

  const { objectData } = data

  const transformedData = objectData.map(value => {
    const result: Record<string, unknown> = {}

    Object.keys(value).forEach(field => {
      if (isNotValidFieldRefId(field, value)) {
        result[field] = null
        return
      }

      return (result[field] = value[field])
    })

    return result
  })

  const isRowCreatedOrCopied = useMemo(() => Boolean(objectData[0]?.isNew), [objectData])
  const isRowOrCellEditable = useMemo(() => hasEditableRowModes, [hasEditableRowModes])

  const createRowTriggerPress = useKeyPress(KEYS_CODES.KEY_I, KEYBOARD_EVENT_PROPS.SHIFT_KEY)

  usePrompt({ when: isDirty && isRowOrCellEditable })

  const renderCell = (
    params: GridRenderEditCellParams<any, ObjectDataRecord>,
    column: ViewColumn
  ) => {
    if (column.valueType !== FIELD_VALUE_TYPE.BOOLEAN) {
      return undefined
    }

    // renderCell необходим только для boolean
    return (
      <CellGenerator
        {...params}
        error={getCellError(errors, params.row)}
        column={column}
        handleSetObjectData={handleSetObjectData}
      />
    )
  }

  const renderEditCell = (
    params: GridRenderEditCellParams<any, ObjectDataRecord>,
    column: ViewColumn
  ) => {
    return (
      <EditCellGenerator
        {...params}
        column={column}
        error={getCellError(errors, params.row)}
        onSetError={handleSetError}
        onUpdateConfirmSave={handleSetConfirmSave}
        isConfirmSave={isConfirmSave}
        onSetDirtyFields={setDirtyFields}
      />
    )
  }

  const handleGetValue = (params: GridValueGetterParams) => {
    if (params.value === undefined || isNotValidFieldRefId(params.field, params.row)) {
      return null
    }

    return params.value
  }

  useEffect(() => {
    if (view) {
      setColumns(
        // TODO: Сортировка должна быть на бэке
        sortColumnsByOrder([...view.columns]).map(column => ({
          field: getColumnFieldName(column),
          headerName: column.desc,
          pinnedColumn: column.restrictions.fix,
          order: column.restrictions.order,
          // flex: 1,
          editable: true,
          required: mergeBaseAndUserRequired({
            baseRequired: column.baseRequired,
            userRequired: column.userRequired,
          }),
          valueGetter: handleGetValue,
          width: column.restrictions.width ?? MIN_VIEW_COLUMN_WIDTH,
          minWidth: MIN_VIEW_COLUMN_WIDTH,
          type: column.valueType,
          valueFormatter: ({ value }) => (typeof value === 'object' ? value?.label || '' : value),
          renderCell: params => renderCell(params, column),
          renderEditCell: params => renderEditCell(params, column),
          sortKey: getDotFormatIfReference(column),
          sortable: !(objectData && objectData[0]?.isNew),
        }))
      )
      const initialColumnVisibilityModel = Object.fromEntries(
        view.columns.map(column => [getColumnFieldName(column), column.restrictions.show ?? true])
      )
      const columnVisibilityModelByRestrictions = Object.fromEntries(
        view.columns.map(column => [
          getColumnFieldName(column),
          getColumnVisibilityByRestrictions(column),
        ])
      )
      setColumnVisibilityModel(initialColumnVisibilityModel)
      setColumnVisibilityModelByRestrictions(columnVisibilityModelByRestrictions)
      setInitialColumnVisibilityModel(initialColumnVisibilityModel)
    }
  }, [view, errors, objectData])

  // Еффект для стилизации обязательных колонок
  useEffect(() => {
    if (!view) {
      return
    }

    setColumns(prev =>
      prev.map(column => ({
        ...column,
        headerClassName: getDataGridElementClassName(column, theme, 'header'),
        cellClassName: getDataGridElementClassName(column, theme, 'cell'),
      }))
    )
  }, [view, theme.palette.mode])

  useEffect(() => {
    if (createRowTriggerPress) {
      handleAddRow()
    }
  }, [createRowTriggerPress])

  useHandleColumnDrag(apiRef, viewId)

  const colWithActions = useMemo(
    () => [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        pinnedColumn: PINNED_COLUMN.RIGHT,
        minWidth: 100,
        resizable: false,
        hideable: false,
        getActions: (params: GridCellParams) => {
          const isEdit = apiRef.current.getRowMode(params.id) === GridRowModes.Edit
          const isEditRowMode = checkRowEditMode(rowModesModel)

          if (isEdit) {
            return [
              <Grid key={params.field} container flexDirection={'column-reverse'} sx={{ mt: 5 }}>
                <GridActionsCellItem
                  icon={<Done color='success' fontSize='medium' />}
                  sx={{ transform: 'rotate(-90deg)' }}
                  label={t('configuredView.rowContextMenu.done')}
                  onClick={() => handleSubmitEditRow(params.row)}
                  showInMenu={false}
                />
                <GridActionsCellItem
                  icon={<Close color='error' fontSize='medium' />}
                  sx={{ transform: 'rotate(-90deg)' }}
                  label={t('configuredView.rowContextMenu.close')}
                  onClick={() => handleCloseEditRow(params.row)}
                  showInMenu={false}
                />
              </Grid>,
            ]
          }

          if (isShowDeleteMultipleRows || isEditRowMode) {
            return []
          }

          return [
            <GridActionsCellItem
              sx={{
                pr: 10,
                py: 1.8,
              }}
              key={params.field}
              icon={<ContentCopyIcon sx={{ mr: 2 }} />}
              label={t('configuredView.rowContextMenu.copy')}
              onClick={() => handleCopyRow(params.row)}
              showInMenu={!isEdit}
            />,
            <GridActionsCellItem
              sx={{
                pr: 10,
                py: 1.5,
              }}
              key={params.field}
              icon={<CheckCircleOutlineIcon sx={{ mr: 2 }} />}
              label={t('configuredView.rowContextMenu.select')}
              onClick={() => handleSelectClick(params.id)}
              showInMenu={!isEdit}
            />,
            <GridActionsCellItem
              sx={{
                pr: 10,
                py: 1.5,
              }}
              key={params.field}
              icon={<DeleteForeverIcon sx={{ mr: 2 }} />}
              label={t('configuredView.rowContextMenu.delete')}
              onClick={() => handleDeleteClick(params.row)}
              showInMenu={!isEdit}
            />,
          ]
        },
      } as GridEnrichedColDef,
    ],
    [columns, objectData, rowModesModel, isShowDeleteMultipleRows]
  )

  const pinnedColumns = useMemo(() => {
    const transformPinnedColumns: GridPinnedColumns = pinnedColumnsRestrictions
      ? transformFixRestrictions(pinnedColumnsRestrictions)
      : { left: [], right: [] }

    transformPinnedColumns.right?.push('actions')

    return transformPinnedColumns
  }, [pinnedColumnsRestrictions])

  const handleSidebarTrigger = (e: Event) => {
    const event = e as CustomEvent
    boxRef.current?.click()

    if (!event.detail.sidebarOpen) {
      const rowId = apiRef.current.getAllRowIds()[0]
      const allColumns = apiRef.current.state.columns.all
      if (allColumns[0]) apiRef.current.setCellFocus(rowId, allColumns[0])
    }
  }

  useSubscribeEvent(EVENT_NAME.SIDEBAR_TRIGGER, handleSidebarTrigger)

  return {
    state: {
      columns: colWithActions,
      columnVisibilityModel,
      columnVisibilityModelByRestrictions,
      apiRef,
      boxRef,
      isRowCreatedOrCopied,
      isRowOrCellEditable,
      isFetchingView,
      areAllColumnsHidden,
      ...state,
    },
    data: {
      ...data,
      view,
      objectValidationErrors,
      pinnedColumns,
      objectData: transformedData,
    },
    handlers: {
      ...handlers,
      handleSetColumnsVisibilityModel,
    },
  }
}
