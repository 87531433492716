import { commonAPI } from './common.api'

export const userAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchUserLogged: build.query<void, void>({
      query: () => ({
        url: `/user/logged`,
      }),
    }),
  }),
})

export const { useFetchUserLoggedQuery } = userAPI
