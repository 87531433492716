import { commonAPI, TAG_TYPE } from './common.api'
import {
  GETObjectRestrictionsParams,
  GETObjectWithConfiguredRestrictions,
  POSTRestriction,
} from '../../types'

export const restrictionsAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchObjectWithConfiguredRestrictions: build.query<GETObjectWithConfiguredRestrictions, number>(
      {
        query: viewId => ({
          url: `restriction/configurationsByView/${viewId}`,
        }),
        providesTags: [TAG_TYPE.RESTRICTIONS],
      }
    ),
    fetchRestrictionsByViewId: build.query<POSTRestriction[], GETObjectRestrictionsParams>({
      query: ({ viewId, restrictionType }) => ({
        url: `restriction/getByView/${viewId}`,
        params: {
          restrictionType,
        },
      }),
      providesTags: [TAG_TYPE.RESTRICTIONS],
    }),
    updateRestrictions: build.mutation<POSTRestriction[], POSTRestriction[]>({
      query: restrictions => ({
        url: 'restriction',
        method: 'POST',
        body: restrictions,
      }),
      invalidatesTags: [TAG_TYPE.VIEW, TAG_TYPE.RESTRICTIONS],
    }),
    updateConfigSettingsRestrictions: build.mutation<POSTRestriction[], POSTRestriction[]>({
      query: restrictions => ({
        url: 'restriction',
        method: 'POST',
        body: restrictions,
      }),
      invalidatesTags: [TAG_TYPE.RESTRICTIONS, TAG_TYPE.VIEW, TAG_TYPE.VIEW_VALIDATION_ERRORS],
    }),
  }),
})

export const {
  useFetchObjectWithConfiguredRestrictionsQuery,
  useFetchRestrictionsByViewIdQuery,
  useUpdateRestrictionsMutation,
  useUpdateConfigSettingsRestrictionsMutation,
} = restrictionsAPI
