import React, { ChangeEvent, createContext, FC, useEffect, useMemo, useState } from 'react'

import { Box, Divider, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import { ItemId, TreeData, TreeItem } from '@atlaskit/tree'
import { useTranslation } from 'react-i18next'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { PageTitle } from '@components/PageTitle'
import { getTree, transformTreeToMenu } from '@helpers'
import { useDebounce, useDraggableTree } from '@hooks'
import {
  useFetchMenuQuery,
  useFetchUnusedViewsMenuWithSearchQuery,
  useUpdateMenuMutation,
} from '@redux/api'
import { GETViewsMenu, OptionType, POSTOptionGroupType } from 'src/types'
import { DraggableGroupList } from './DraggableGroupList'
import { SearchTextField } from './SearchTextField'
import { UncertainList } from './UncertainList'

type MenuSettingContextType = {
  tree: TreeData | null
  setTree: (tree: TreeData) => void
  onDeleteTreeItem: (itemId: ItemId) => void
  onEditTreeItem: (treeItem: TreeItem) => void
  onAddTreeItem: (option: POSTOptionGroupType, treeItem?: TreeItem) => void
  updateMenuOptions: () => void
  isDirty: boolean
  allData: any[]
  unusedObjectsMenu?: GETViewsMenu
  onCancel: () => void
  transformTree: OptionType[]
}

export const MenuSettingContext = createContext<MenuSettingContextType>(
  {} as MenuSettingContextType
)

export const MenuSetting: FC = () => {
  const { t } = useTranslation()
  const [isListEdit, setIsListEdit] = useState(false)
  const [firstTree, setFirstTree] = useState<TreeData | null>(null)
  const { tree, setTree, deleteTreeItem, addTreeItem } = useDraggableTree()
  const transformFirstTree = useMemo(() => firstTree && transformTreeToMenu(firstTree), [firstTree])
  const transformTree = useMemo(() => tree && transformTreeToMenu(tree), [tree])
  const isDirty = JSON.stringify(transformFirstTree) !== JSON.stringify(transformTree)

  const [updateMenu, { isSuccess }] = useUpdateMenuMutation()
  const { data: menu } = useFetchMenuQuery()

  const [searchValue, setSearchValue] = useState('')
  const debounceSearchValue = useDebounce(searchValue, 500)
  const { data: unusedObjectsMenu, isFetching: isFetchingUnusedObjects } =
    useFetchUnusedViewsMenuWithSearchQuery(searchValue ? debounceSearchValue : '')

  const sortedUnusedObjectsMenu = useMemo(
    () =>
      unusedObjectsMenu
        ? Object.entries(unusedObjectsMenu).sort(([aId], [bId]) => Number(bId) - Number(aId))
        : [],
    [unusedObjectsMenu]
  )
  const handleSetIsListEdit = (value: boolean) => setIsListEdit(value)

  useEffect(() => {
    if (menu && !isSuccess) {
      setTree(getTree(menu))
      setFirstTree(getTree(menu))
    }
  }, [menu])

  const handleDeleteTreeItem = (itemId: ItemId) => {
    deleteTreeItem(itemId)
  }

  const handleEditTreeItem = (treeItem: TreeItem) => {
    if (tree) {
      const newTree: TreeData = { ...tree }
      newTree.items[treeItem.id] = { ...treeItem }
      setTree(newTree)
    }
  }

  const handleAddTreeItem = (option: POSTOptionGroupType, treeItem?: TreeItem) => {
    addTreeItem(option as OptionType, treeItem)
  }

  const removeTemporaryCodeFromMenu = (options: OptionType[]): POSTOptionGroupType[] => {
    return options.map(option => {
      if (option?.childrenMenu.length > 0) {
        return {
          ...option,
          code: undefined,
          hasChild: undefined,
          childrenMenu: removeTemporaryCodeFromMenu(option.childrenMenu),
        }
      }
      return { ...option, code: undefined, hasChild: undefined }
    }) as POSTOptionGroupType[]
  }

  const handleUpdateMenuOptions = () => {
    if (tree && menu) {
      const newMenu = removeTemporaryCodeFromMenu(transformTreeToMenu(tree, true))
      updateMenu(newMenu)
        .unwrap()
        .then(res => {
          setTree(getTree(res))
          setFirstTree(getTree(res))
        })
    }
  }

  const handleChangeSearchValue = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(value)
  }

  const handleClearSearchValue = () => {
    setSearchValue('')
  }

  const handleCancelMenu = () => {
    if (menu) {
      setTree(getTree(menu))
    }
  }

  return (
    <MenuSettingContext.Provider
      value={{
        onAddTreeItem: handleAddTreeItem,
        onDeleteTreeItem: handleDeleteTreeItem,
        onEditTreeItem: handleEditTreeItem,
        updateMenuOptions: handleUpdateMenuOptions,
        setTree,
        tree,
        isDirty,
        allData: [],
        unusedObjectsMenu,
        onCancel: handleCancelMenu,
        transformTree: transformTree ?? [],
      }}
    >
      <Box>
        <PageTitle
          start={
            <Typography display={'inline'} variant={'h6'} sx={{ mr: 1 }}>
              {t('menuSetting.title')}
            </Typography>
          }
          end={false}
        />
        <PageContentLayout>
          <Grid container p={3}>
            <Grid item container flex={1} flexDirection={'column'} mr={2}>
              <Grid item mb={2}>
                <Typography
                  variant='subtitle2'
                  color={theme => theme.palette.text.secondary}
                  fontWeight='bold'
                >
                  {t('menuSetting.notAssigned')}
                </Typography>
                <Box sx={{ height: 20, display: 'flex', alignItems: 'center' }}>
                  {isFetchingUnusedObjects && <LinearProgress sx={{ width: '100%' }} />}
                </Box>
              </Grid>
              <Grid container item>
                <Grid container item mb={1}>
                  <SearchTextField
                    value={searchValue}
                    onChange={handleChangeSearchValue}
                    onClear={handleClearSearchValue}
                  />
                </Grid>
                <Grid container item sx={{ maxHeight: '55vh', overflowY: 'auto' }}>
                  <Stack>
                    {sortedUnusedObjectsMenu.map(([id, title]) => (
                      <UncertainList key={id} code={id} title={title} />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider sx={{ width: 1, mx: 0.2, p: 0 }} orientation='vertical' variant='middle' />
            </Grid>
            <Grid item container flex={3}>
              <Box sx={{ ml: 2, width: '100%' }}>
                <Typography
                  variant='subtitle2'
                  color={theme => theme.palette.text.secondary}
                  fontWeight='bold'
                >
                  {t('menuSetting.groups.title')}
                </Typography>
                <Box sx={{ height: 20, display: 'flex', alignItems: 'center' }}>
                  {/* {isFetchingGroups && <LinearProgress sx={{ width: '100%' }} />} */}
                </Box>
              </Box>
              <DraggableGroupList isListEdit={isListEdit} handleSetEdit={handleSetIsListEdit} />
            </Grid>
          </Grid>
        </PageContentLayout>
      </Box>
    </MenuSettingContext.Provider>
  )
}
