import { commonAPI, TAG_TYPE } from './common.api'
import { GETOptionGroupType, GETViewsMenu, POSTOptionGroupType } from '../../types'

export const menuAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchMenu: build.query<GETOptionGroupType[], void>({
      query: () => ({
        url: 'menu',
      }),
      // transformResponse: (res: OptionType[], meta: FetchBaseQueryMeta) =>
      //   transformMenuResponse(res, meta),
      providesTags: [TAG_TYPE.MENU],
    }),
    fetchMenuWithSearch: build.query<GETOptionGroupType[], string>({
      query: searchPattern => ({
        url: 'menu',
        params: {
          searchPattern,
        },
      }),
      // transformResponse: (res: OptionType[], meta: FetchBaseQueryMeta) =>
      //   transformMenuResponse(res, meta),
      providesTags: [TAG_TYPE.MENU],
    }),
    fetchViewsMenu: build.query<GETViewsMenu, void>({
      query: () => ({
        url: 'menu/all',
      }),
      providesTags: [TAG_TYPE.VIEWS_IN_MENU],
    }),
    fetchUnusedViewsMenuWithSearch: build.query<GETViewsMenu, string>({
      query: searchPattern => ({
        url: 'menu/unused',
        params: {
          searchPattern,
        },
      }),
      providesTags: [TAG_TYPE.UNUSED_VIEWS_IN_MENU],
    }),
    updateMenu: build.mutation<GETOptionGroupType[], POSTOptionGroupType[]>({
      query: newMenu => ({
        url: 'menu',
        method: 'POST',
        body: newMenu,
      }),
      invalidatesTags: [TAG_TYPE.MENU, TAG_TYPE.UNUSED_VIEWS_IN_MENU],
    }),
  }),
})

export const {
  useFetchMenuQuery,
  useFetchMenuWithSearchQuery,
  useFetchViewsMenuQuery,
  useFetchUnusedViewsMenuWithSearchQuery,
  useUpdateMenuMutation,
} = menuAPI
