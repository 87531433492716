import { getParsedFields } from './getParsedFields'

export const isNotValidFieldRefId = (field: string, row: Record<string, unknown>) => {
  const { fieldName, parentFieldName } = getParsedFields(field)
  const isMainRefField = fieldName.includes('_ref') && !parentFieldName
  // если у ref поля его _id невалидный => не приходят обогащенные поля
  const hasEmbeddedFieldsInRow = Object.keys(row).some(
    field => getParsedFields(field).parentFieldName === fieldName
  )

  return isMainRefField && !hasEmbeddedFieldsInRow
}
