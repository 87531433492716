export enum EVENT_NAME {
  SIDEBAR_TRIGGER = 'SIDEBAR_TRIGGER',
}

export type EventListener = (e: Event) => void

type EventData = unknown

export const subscribe = (eventName: EVENT_NAME, listener: EventListener) => {
  document.addEventListener(eventName, listener)
}

export const unsubscribe = (eventName: EVENT_NAME, listener: EventListener) => {
  document.removeEventListener(eventName, listener as EventListener)
}

export const publish = (eventName: EVENT_NAME, data?: EventData) => {
  const event = new CustomEvent(eventName, { detail: data })
  document.dispatchEvent(event)
}
