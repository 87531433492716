import { FC } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '@microservices/wiskey-react-components'

type DeleteDialogProps = {
  isShow: boolean
  count?: number
  onClose: () => void
  onSave: () => void
}

export const DeleteDialog: FC<DeleteDialogProps> = ({ isShow, onClose, onSave, count }) => {
  const { t } = useTranslation()

  return (
    <ModalWrapper
      isShow={isShow}
      onClose={onClose}
      onSave={onSave}
      title={t('configuredView.deleteModal.title')}
      btnText={t('configuredView.deleteModal.confirm')}
      hideDivider
      focusSave
      focusCancel
    >
      <Typography my={2}>
        {count
          ? t('configuredView.deleteModal.multiple', { count })
          : t('configuredView.deleteModal.single')}
      </Typography>
    </ModalWrapper>
  )
}
