import { GridPinnedColumns } from '@microservices/wiskey-react-components'
import { RestrictionDTO, RESTRICTION_TYPE, RESTRICTION_NAME } from '../../../types'
import { PinnedColumnPosition } from '../types'
import { getParsedFields } from './getParsedFields'
import { COLUMNS } from '@constants'

// Хелпер для поиска значений в втором массиве, которых нет в первом и для преобразования их в RestrictionDTO
export const findPinnedColumnsDifferences = (
  viewId: number,
  firstColumns: GridPinnedColumns,
  secondColumns: GridPinnedColumns,
  restrictionValue?: unknown
) => {
  const output: RestrictionDTO[] = []

  Object.keys(firstColumns).forEach(columnPosition => {
    const typedPosition = columnPosition as PinnedColumnPosition

    secondColumns[typedPosition]?.forEach(pinnedColumnFromHandler => {
      const columnIsNotPresent = !firstColumns[typedPosition]?.includes(pinnedColumnFromHandler)

      if (columnIsNotPresent) {
        const { parentFieldName, fieldName } = getParsedFields(pinnedColumnFromHandler)

        if (fieldName !== COLUMNS.ACTIONS) {
          output.push({
            viewId,
            parentFieldName,
            fieldName,
            restrictionType: RESTRICTION_TYPE.FIX,
            restrictionName: RESTRICTION_NAME.FIX,
            value: restrictionValue || restrictionValue === null ? restrictionValue : typedPosition,
          })
        }
      }
    })
  })

  return output
}
