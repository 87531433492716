import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { findNavOptionByCode, getNavOptionCodesToExpand } from '../helpers'

import { OptionType } from 'src/types'

type useSetNavigationUIByUrlParams = {
  options: OptionType[]
  handleSetSelected: (value: string) => void
  handleSetExpanded: (value: string[]) => void
}

export const useSetNavigationUIByUrl = ({
  options,
  handleSetSelected,
  handleSetExpanded,
}: useSetNavigationUIByUrlParams) => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname && options.length) {
      const currentUrlOption = pathname.split('/')[1]

      if (!currentUrlOption) {
        return
      }

      const currentOption = findNavOptionByCode(options, currentUrlOption)

      if (!currentOption) {
        return
      }

      handleSetSelected(currentUrlOption)

      const ids = getNavOptionCodesToExpand(options, currentOption)

      if (!ids) {
        return
      }

      handleSetExpanded(ids)
    }
  }, [pathname, options])
}
