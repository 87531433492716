import { RestrictionDTO } from 'src/types'
import { GridPinnedColumns } from '@microservices/wiskey-react-components'

// Хелпер для преобразования ограничений типа RestrictionDTO в тип,
// подходящий для pinnedColumns в datagrid: { left: string[], right: string[] } (GridPinnedColumns)
export const transformFixRestrictions = (
  pinnedColumnsRestrictions: RestrictionDTO[]
): GridPinnedColumns => {
  return pinnedColumnsRestrictions?.reduce(
    (prev: GridPinnedColumns, current: RestrictionDTO) => {
      if (current.value === 'right' || current.value === 'left') {
        const fieldName = current.parentFieldName
          ? `${current.parentFieldName}//${current.fieldName}`
          : current.fieldName

        prev[current.value]?.push(fieldName)
      }

      return prev
    },
    { right: [], left: [] }
  )
}
