import { ChangeEvent, FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { Box, Drawer } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { NavList } from '../NavList'
// import { ProfileMenu } from '../ProfileMenu'
import { MIN_DRAWER_WIDTH } from '@constants'
import { useMenuResize } from '@hooks'
import { TextField } from '@microservices/wiskey-react-components'
import { Search } from '@mui/icons-material'
import { OptionType } from '../../types'
import { ProfileMenu } from '@components/ProfileMenu'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}))

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  position: 'relative',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${MIN_DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

const Dragger = styled('div')<{ drawerWidth: number }>(({ theme, drawerWidth }) => ({
  width: '5px',
  cursor: 'ew-resize',
  height: '100%',
  padding: '4px 0 0',
  borderTop: `1px solid ${theme.palette.dragger}`,
  borderLeft: `1px solid ${theme.palette.grey[300]}`,
  position: 'fixed',
  left: drawerWidth,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  backgroundColor: theme.palette.dragger,
}))

type SidebarProps = {
  children: ReactNode
  options: OptionType[]
  open: boolean
  setOpen: (sidebarOpen: boolean) => void
  handleMPress: () => void
  searchValue: string
  handleChangeSearchValue({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
}

export const Sidebar: FC<SidebarProps> = ({
  children,
  options,
  open,
  setOpen,
  handleMPress,
  searchValue,
  handleChangeSearchValue,
}) => {
  const { t } = useTranslation()
  const { drawerWidth, onMouseDown } = useMenuResize()

  const handleDrawerClose = (): void => setOpen(!open)

  return (
    <Main open={open}>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant='persistent'
          open={open}
          anchor='left'
          PaperProps={{
            style: {
              maxWidth: drawerWidth,
              width: drawerWidth,
              overflowX: 'hidden',
              borderRight: 0,
            },
          }}
          sx={{
            flexShrink: 0,
            width: drawerWidth,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
            },
          }}
        >
          <Dragger onMouseDown={onMouseDown} drawerWidth={drawerWidth} />
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <ProfileMenu />
          <Box m={2} ml={1.5}>
            <TextField
              value={searchValue}
              onChange={handleChangeSearchValue}
              variant='outlined'
              size='small'
              placeholder={t('sidebar.search')}
              InputProps={{
                endAdornment: <Search fontSize='small' />,
              }}
            />
          </Box>
          <Box
            sx={{
              position: 'relative',
              overflowY: 'auto',
            }}
          >
            <NavList
              sidebarOpen={open}
              options={options}
              handleMPress={handleMPress}
              drawerWidth={drawerWidth}
            />
          </Box>
        </Drawer>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            p: 3,
            minWidth: 0,
          }}
        >
          {children}
        </Box>
      </Box>
    </Main>
  )
}
