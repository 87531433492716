import { combineReducers } from '@reduxjs/toolkit'

import themeSliceReducer from './theme.reducer'
import snackbarsReducer from './snackbar.reducer'
import { commonAPI } from '../api'

export const rootReducer = combineReducers({
  theme: themeSliceReducer,
  snackbar: snackbarsReducer,
  [commonAPI.reducerPath]: commonAPI.reducer,
})
