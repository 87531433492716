import { ItemId, TreeData, TreeItem } from '@atlaskit/tree'
import { TreeBuilder } from '@services/TreeBuilder'

import { OptionType } from '../types'

const menuTransformation = (obj: TreeBuilder, options: OptionType[], parentItem?: OptionType) => {
  let parent: null | TreeBuilder = null

  if (parentItem) {
    parent = new TreeBuilder(parentItem.id, parentItem)
  }

  options.forEach(item => {
    if (!parentItem && !item.childrenMenu?.length) {
      obj.withLeaf(item.id, item)
      return
    }

    if (parent) {
      parent.withLeaf(item.id, item)

      if (item.childrenMenu?.length) {
        menuTransformation(parent, item.childrenMenu, item)
      }

      obj.withSubTree(parent)
    }

    if (item.childrenMenu?.length && !parentItem) {
      menuTransformation(obj, item.childrenMenu, item)
    }
  })
}

export const getTree = (options: OptionType[]): TreeData | null => {
  if (options) {
    const tree = new TreeBuilder(0, { id: 0, title: 'tree' })

    menuTransformation(tree, options)

    return tree.build()
  }

  return null
}

// Преобразование дерева items в пункты меню
export const transformTreeToMenu = (newTree: TreeData, isMutation?: boolean): OptionType[] => {
  const treeItems = newTree.items

  // Получение data из tree item без options и title
  const getTreeItemData = (itemId: ItemId): TreeItem => {
    return treeItems[itemId]
  }

  // Рекурсивное создание пунктов меню на основе children каждого tree item
  const createOptions = (itemId: ItemId): OptionType[] => {
    const {
      children,
      hasChildren,
      data: { options },
    } = treeItems[itemId]

    if (!hasChildren && !options) {
      return []
    }

    return children.map(childrenId => {
      const { data: itemData, isExpanded } = getTreeItemData(childrenId)

      return {
        ...itemData,
        childrenMenu: createOptions(childrenId),
        ...(isMutation && { expanded: isExpanded }),
      }
    })
  }

  return createOptions(newTree.rootId) || []
}
