export const compareObjects = (obj1: Record<string, unknown>, obj2: Record<string, unknown>) => {
  // Получаем массив ключей из обоих объектов
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Если количество ключей не совпадает, объекты точно различны
  if (keys1.length !== keys2.length) {
    return false
  }

  // Проходим по всем ключам и сравниваем значения
  for (const key of keys1) {
    // Если значение ключа не существует в obj2 или значения различны, объекты различны
    if (!Object.prototype.hasOwnProperty.call(obj2, key) || obj1[key] !== obj2[key]) {
      return false
    }
  }

  // Если выполнение дошло до этой точки, объекты идентичны
  return true
}
