import { FC, useRef, useState, SyntheticEvent, useEffect } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Link, Typography, useTheme } from '@mui/material'
import { TreeView } from '@mui/lab'

import { ExpandMore } from '@mui/icons-material'
import { ChevronRight } from '@mui/icons-material'

import { StyledTreeItem, textStyles, titleStyles } from './NavListStyles'

import { useImitateLinkCtrlBehavior, useSetNavigationUIByUrl } from './hooks'
import { findNavOptionByCode } from './helpers'
import { OptionType } from '../../types'
import { EVENT_NAME } from '@events'
import { useSubscribeEvent } from '@hooks'

type NavListProps = {
  options: OptionType[]
  sidebarOpen: boolean
  handleMPress: () => void
  drawerWidth: number
}

export const NavList: FC<NavListProps> = ({ options, sidebarOpen, handleMPress, drawerWidth }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { pathname } = useLocation()

  const [selected, setSelected] = useState<string>('')
  const [expanded, setExpanded] = useState<string[]>([])

  const focusWrapper = useRef<HTMLDivElement | null>(null)
  const { isItemSelectionDisabled } = useImitateLinkCtrlBehavior()

  const handleNodeFocus = (event: SyntheticEvent, value: string) => {
    const currentItem = document.querySelector(`[id$="${value}"]`)
    if (!currentItem) {
      return
    }

    currentItem.scrollIntoView({
      block: 'nearest',
      inline: 'start',
    })
  }

  useSetNavigationUIByUrl({
    options,
    handleSetSelected: (value: string) => setSelected(value),
    handleSetExpanded: (value: string[]) => setExpanded(value),
  })

  const handleSidebarTrigger = (e: Event) => {
    const event = e as CustomEvent

    setTimeout(() => {
      if (event.detail.sidebarOpen && focusWrapper.current) {
        focusWrapper.current.focus({ preventScroll: true })
      }
    }, theme.transitions.duration.leavingScreen) // как у Drawer в Sidebar
  }

  useSubscribeEvent(EVENT_NAME.SIDEBAR_TRIGGER, handleSidebarTrigger)

  const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event: SyntheticEvent, nodeIds: Array<string> | string) => {
    // setTimeout(() => {
    //   focusWrapper.current && focusWrapper.current.focus({ preventScroll: true })
    // })

    if (nodeIds === selected) {
      return
    }

    const menuOptions = [...options]

    const selectedOption = menuOptions && findNavOptionByCode(menuOptions, nodeIds as string)
    if (selectedOption && selectedOption.childrenMenu.length === 0) {
      navigate(selectedOption.code)
      if (selectedOption.code === pathname) {
        setSelected(nodeIds as string)
      }
    }
  }

  useEffect(() => {
    setSelected(pathname.slice(1))
  }, [pathname])

  const renderTree = (navOption: OptionType) => {
    return (
      <StyledTreeItem key={navOption.code} nodeId={navOption.code} label={navOption.title}>
        {Array.isArray(navOption.childrenMenu) &&
          navOption.childrenMenu.map(node => (
            <Link
              sx={textStyles}
              component={node.childrenMenu?.length ? Box : RouterLink}
              to={node.code}
              key={node.id}
            >
              {renderTree(node)}
            </Link>
          ))}
      </StyledTreeItem>
    )
  }

  return (
    <>
      <Typography variant='caption' sx={titleStyles}>
        {t('navigation')}
      </Typography>
      <TreeView
        ref={focusWrapper}
        sx={{ overflowY: 'auto' }}
        aria-label='rich object'
        defaultCollapseIcon={<ExpandMore fontSize='large' sx={{ width: 24, height: 24 }} />}
        expanded={expanded}
        selected={selected}
        defaultExpandIcon={<ChevronRight fontSize='large' sx={{ width: 24, height: 24 }} />}
        defaultSelected={options[0]?.code}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        disableSelection={isItemSelectionDisabled}
        onNodeFocus={handleNodeFocus}
      >
        {options.map(option => renderTree(option))}
      </TreeView>
    </>
  )
}
