import {
  Dispatch,
  KeyboardEvent,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { v4 as uuid } from 'uuid'
import isEqual from 'lodash.isequal'
import {
  DataGridProProps as MUIDataGridProps,
  GridApiPro,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridEventListener,
  GridPinnedColumns,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridSelectionModel,
  GridSortModel,
  GridValidRowModel,
  GridRowEditStartReasons,
  GridRowId,
} from '@microservices/wiskey-react-components'

import { ScrollingParams, SortType, useAppDispatch, useDebounceFn, useInfiniteScroll } from '@hooks'
import {
  compareModels,
  getDefaultRowByObject,
  getErrorDescriptionByField,
  getRowWithTransformRefFields,
  getSortParam,
  getTransformResponse,
  transformFixRestrictions,
} from '../helpers'
import {
  useDeleteObjectDataRecordsMutation,
  useFetchObjectDataListEnrichedByViewIdMutation,
  useFetchObjectDataEnrichedByViewIdMutation,
  useUpdateObjectDataRecordMutation,
} from '@redux/api'
import {
  useFetchRestrictionsByViewIdQuery,
  useUpdateRestrictionsMutation,
} from '@redux/api/restriction.api'
import { getParsedFields } from '../helpers/getParsedFields'

import { HOTKEYS_FOR_TABLE, KEYS, SIZE_PAGE } from '@constants'

import { GridColumnType } from './useConfiguredView'
import {
  ErrorDescriptionRows,
  GETObjectDataParams,
  ObjectDataRecord,
  POSTObjectDataFilter,
  RESTRICTION_NAME,
  RESTRICTION_TYPE,
  RestrictionDTO,
} from '../../../types'
import { findPinnedColumnsDifferences } from '../helpers/findPinnedColumnsDifferences'
import { getSortErrorsByColumns } from '../helpers/getSortErrorsByColumns'
import { showMessage } from '@redux/reducers/snackbar.reducer'
import { compareObjects, getResponseMessageText } from '@helpers'
import { getCopiedRow } from '../helpers/getCopiedRow'
import { useTranslation } from 'react-i18next'
import { checkRowEditMode } from '@pages/ConfiguredView/helpers'

type UseHandlersProps = {
  viewId: number
  apiRef: MutableRefObject<GridApiPro>
  onSetColumnVisibilityModel: (value: Record<string, boolean>) => void
  onSetDirtyFields: (value: Record<string, boolean>) => void
  initialColumnVisibilityModel: GridColumnVisibilityModel
  columns: GridColumnType[]
  areAllColumnsHidden: boolean
  isFetchingView: boolean
}

export const useHandlers = ({
  viewId,
  apiRef,
  initialColumnVisibilityModel,
  onSetColumnVisibilityModel,
  onSetDirtyFields,
  columns,
  areAllColumnsHidden,
  isFetchingView,
}: UseHandlersProps) => {
  const [isShowConfigSettings, setShowConfigSettings] = useState(false)
  const [isOpenShowColumnsSettings, setOpenShowColumnsSettings] = useState(false)

  // const [rows, setRows] = useState<Record<string, unknown>[]>(rowsData)
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  const hasEditableRowModes = useMemo(() => checkRowEditMode(rowModesModel), [rowModesModel])

  const [isConfirmSave, setConfirmSave] = useState(false)
  // TODO необходимо убрать это состояние. Нужно использовать состояния загрузки мутаций
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<ErrorDescriptionRows | null>(null)
  const [isShowDeleteMultipleRows, setShowDeleteMultipleRows] = useState(false)
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const [showValidationGrid, setShowValidationGrid] = useState(false)
  const [deleteIdRow, setDeleteIdRow] = useState<string | null>(null)
  const [showDeleteMultipleDialog, setShowDeleteMultipleDialog] = useState(false)
  const [showDeleteLinkedDialog, setShowDeleteLinkedDialog] = useState(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { data: pinnedColumnsRestrictions } = useFetchRestrictionsByViewIdQuery({
    viewId,
    restrictionType: RESTRICTION_TYPE.FIX,
  })
  const [updateRestrictions, { isLoading: isLoadingRestrictions }] = useUpdateRestrictionsMutation()
  const [updateObjectDataRecord, { isLoading: isUpdateLoading }] =
    useUpdateObjectDataRecordMutation()
  const [deleteObjectDataRecords] = useDeleteObjectDataRecordsMutation({
    fixedCacheKey: 'shareDeleteIds',
  })
  const [fetchEnrichedObjectData] = useFetchObjectDataEnrichedByViewIdMutation()

  const {
    combinedData: objectData,
    isLoading: isLoadingObjectData,
    totalPages,
    refresh,
    onChangeSort,
    readMore,
    currentSort,
    setCombinedDataForce: setObjectData,
    currentPage,
  } = useInfiniteScroll<GETObjectDataParams, ObjectDataRecord>(
    useFetchObjectDataListEnrichedByViewIdMutation,
    {
      viewId: viewId,
      size: SIZE_PAGE,
    },
    getTransformResponse
  )

  const gridLoading = loading || isLoadingObjectData || isLoadingRestrictions || isFetchingView

  const handleSetObjectData = (
    value: ((prevState: ObjectDataRecord[]) => ObjectDataRecord[]) | ObjectDataRecord[]
  ) => setObjectData(value)

  const handleScrollToTop = () => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 })
  }

  const handleRefreshObjectData = (params?: Omit<ScrollingParams, 'page'>) => {
    handleScrollToTop()
    return refresh(params)
  }

  const handleClickSearchFilter = (body: POSTObjectDataFilter) => {
    handleRefreshObjectData({ body })
  }

  const refreshChangeSortData = (sort: SortType[]) => {
    handleScrollToTop()

    return onChangeSort(sort)
  }

  const handleObjectDataPageChange: MUIDataGridProps['onRowsScrollEnd'] = () => {
    if (areAllColumnsHidden) {
      return
    }
    readMore()
  }

  const handleChangeModelSort = (value: GridSortModel | null) => {
    if (gridLoading) {
      return
    }

    if (viewId) {
      const isSorting = value && value.length
      refreshChangeSortData(isSorting ? getSortParam(value, apiRef) : [])
    }
  }

  const handleSetError: Dispatch<SetStateAction<ErrorDescriptionRows | null>> = value =>
    setErrors(value)

  const handleSetLoading = (value: boolean) => setLoading(value)

  const handleSetConfirmSave = (value: boolean) => setConfirmSave(value)

  const handleOpenShowColumnsSettings = (value: boolean) => setOpenShowColumnsSettings(value)

  const handleSetRowModesModel = (value: GridRowModesModel) => setRowModesModel(value)

  const handleShowDeleteMultipleRows = (value: boolean) => setShowDeleteMultipleRows(value)

  const handleSetSelectionModel = (value: GridSelectionModel) => setSelectionModel(value)

  const doIfNotCreatingRow = (successCallback: () => void) => {
    // Дополнительная проверка на редактируемую строку
    if (!hasEditableRowModes) {
      successCallback()

      return
    }

    const editableRowId = Object.keys(rowModesModel).find(
      id => rowModesModel[id].mode === GridRowModes.Edit
    )
    if (editableRowId) {
      const findRow = apiRef.current.getRow(editableRowId)

      if (findRow) {
        handleCloseEditRow(findRow, successCallback)
      }
    }
  }

  const handleShowConfigSettings = (value: boolean) => {
    doIfNotCreatingRow(() => setShowConfigSettings(value))
  }

  const handleShowValidationGrid = (value: boolean) => {
    doIfNotCreatingRow(() => setShowValidationGrid(value))
  }

  const handleCloseDeleteMultipleRows = () => {
    handleShowDeleteMultipleRows(false)
    // Из документации:
    // Updates the selected rows to be those passed to the rowIds argument.
    // > Any row already selected will be unselected.
    setSelectionModel([])
  }

  const handleSetDeleteMultipleDialog = (value: boolean) => setShowDeleteMultipleDialog(value)

  const handleEditRowStart: GridEventListener<'rowEditStart'> = params => {
    const { id, field, reason, key, row } = params

    if (hasEditableRowModes || isLoadingObjectData) {
      return
    }

    if (key && HOTKEYS_FOR_TABLE.COPY.includes(key.toLowerCase())) {
      handleCopyRow(row, field)
      return
    }

    if (key && HOTKEYS_FOR_TABLE.SELECT.includes(key.toLowerCase())) {
      handleSelectClick(id)
      return
    }

    if (reason === GridRowEditStartReasons.printableKeyDown) {
      return
    }

    if (key === KEYS.DELETE && reason === GridRowEditStartReasons.deleteKeyDown) {
      const indexRow = objectData.findIndex(({ id: rowId }) => rowId === id)

      if (apiRef.current.getSelectedRows().size > 0) {
        setShowDeleteMultipleDialog(true)
        return
      }

      if (!isShowDeleteMultipleRows) {
        handleDeleteClick(row)

        if (objectData.length > 1 && indexRow !== -1) {
          setTimeout(() => {
            if (objectData[indexRow + 1]) {
              apiRef.current.setCellFocus(objectData[indexRow + 1].id, field)
              return
            }
            if (objectData[indexRow - 1]) {
              apiRef.current.setCellFocus(objectData[indexRow - 1].id, field)
              return
            }
          }, 0)
        }

        return
      }
      return
    }

    const backspaceKey = key === KEYS.BACKSPACE && reason === GridRowEditStartReasons.deleteKeyDown

    setRowModesModel(prevModel => ({
      ...prevModel,
      [id]: {
        mode: GridRowModes.Edit,
        fieldToFocus: field,
        deleteValue: backspaceKey,
      },
    }))
  }

  const handleSelectClick = (id: GridRowId) => {
    handleShowDeleteMultipleRows(true)

    if (selectionModel.includes(id)) {
      setSelectionModel(selectionModel.filter(selectedRowId => selectedRowId !== id))
      return
    }
    setSelectionModel(prevSelectedRows => [...prevSelectedRows, id])
  }

  const handleAddRow = () => {
    // Блокировать если уже есть новая строка в процессе созданиия
    if (hasEditableRowModes) {
      return
    }

    const newId = uuid()
    const newRow = { id: newId, isNew: true, ...getDefaultRowByObject(columns) }

    setErrors(null)
    setObjectData([newRow, ...objectData])
    setRowModesModel(old => ({
      ...old,
      [newId]: { mode: GridRowModes.Edit, ignoreModifications: true, fieldToFocus: 'name' },
    }))
  }

  const handleSubmitEditRow = (oldRow: GridRowModel<ObjectDataRecord>) => {
    const {
      isNew,
      id: idForTable,
      _id,
      copyRow,
      ...row
    } = apiRef.current.unstable_getRowWithUpdatedValuesFromRowEditing(oldRow.id)
    setConfirmSave(true)
    setLoading(true)

    // Прокидываем в значение id из AutocompleteOption при наличии и фильтруем от ref полей
    const transformedRow = Object.fromEntries(
      Object.entries(row)
        .map(([key, value]) => [key, value?.id ? value.id : value])
        .filter(([field]) => !getParsedFields(field).parentFieldName)
    )

    updateObjectDataRecord({
      viewId,
      rows: [{ ...transformedRow, ...(!isNew && { _id }) }],
    })
      .unwrap()
      .then(rows => {
        const { errors } = rows[0]

        if (errors) {
          const sortedErrors = getSortErrorsByColumns(columns, errors)
          const descriprtionErrors = getErrorDescriptionByField(sortedErrors)
          setErrors(prev => ({ ...prev, [idForTable]: descriprtionErrors }))

          apiRef.current.scrollToIndexes({
            colIndex: apiRef.current.getColumnIndex(sortedErrors[0].fieldName),
          })

          setLoading(false)
          return
        }

        if (rows[0]._id) {
          fetchEnrichedObjectData({ ids: [rows[0]._id], viewId })
            .unwrap()
            .then(([updatedRow]) => {
              setObjectData(
                objectData.map(prevRow =>
                  prevRow._id === updatedRow._id || prevRow.isNew
                    ? { id: idForTable, ...updatedRow }
                    : prevRow
                )
              )
              setRowModesModel(old => ({
                ...old,
                [oldRow.id]: { mode: GridRowModes.View },
              }))

              onSetDirtyFields({})
              setConfirmSave(false)
              setLoading(false)
              setErrors(null)
            })
        }
      })
      .catch(err => {
        dispatch(
          showMessage({
            type: 'error',
            statusCode: err.status,
            text: getResponseMessageText(err, err.data),
          })
        )
        setLoading(false)
      })
  }

  const handleCloseEditRow = (
    oldRow: GridRowModel<ObjectDataRecord>,
    successCallback?: () => void
  ) => {
    const { _id, ...row } = apiRef.current.unstable_getRowWithUpdatedValuesFromRowEditing(oldRow.id)
    // При нажатии на ESC или анфокусе не сохранять данные и выйти из режима редактирования
    if (
      !isEqual(oldRow, getRowWithTransformRefFields({ ...row, ...(row.isNew ? {} : { _id }) })) &&
      !confirm(t('notifications.leave'))
    ) {
      return
    }

    setErrors(null)
    setConfirmSave(false)
    if (row.isNew) {
      setObjectData(rows => rows.filter(row => !row.isNew))
    }
    setRowModesModel(old => ({
      ...old,
      [oldRow.id]: { mode: GridRowModes.View },
    }))
    onSetDirtyFields({})
    successCallback?.()

    // для того чтобы фокус возвращался на ячейку из которой копировали строку
    if (oldRow.copyRow && oldRow.copyRow.copyId && oldRow.copyRow.prevField) {
      setTimeout(() => {
        // TODO Исправить ошибку ts
        apiRef.current.setCellFocus(oldRow.copyRow.copyId, oldRow.copyRow.prevField)
      }, 0)
    }
  }

  // TODO ???
  // При анфокусе, ESC сетится старая строка
  const handleProcessRowUpdate = (
    _newRow: GridRowModel<ObjectDataRecord>,
    oldRow: GridRowModel<ObjectDataRecord>
  ) => oldRow

  const handleRowEditStop: GridEventListener<'rowEditStop'> = ({ reason, row: oldRow }) => {
    if (reason === GridRowEditStopReasons.enterKeyDown) {
      handleSubmitEditRow(oldRow)
    }
    if (reason === GridRowEditStopReasons.escapeKeyDown) {
      handleCloseEditRow(oldRow)
    }
  }

  const handleCopyRow = (copiedRow: ObjectDataRecord, field?: string) => {
    // Блокировать если уже есть новая строка в процессе созданиия или есть выделенные строки
    if (hasEditableRowModes) {
      return
    }
    const newId = uuid()
    const row = getCopiedRow(copiedRow, columns)

    const newRow = {
      ...row,
      isNew: true,
      id: newId,
      ...(field && {
        // для того чтобы контролировать фокус, который был на ячейке
        copyRow: {
          copyId: row.id,
          prevField: field,
        },
      }),
    }
    setErrors(null)
    setObjectData([newRow, ...objectData])
    setRowModesModel(old => ({
      ...old,
      [newId]: { mode: GridRowModes.Edit, ignoreModifications: true },
    }))

    handleScrollToTop()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KEYS.ESCAPE && isShowDeleteMultipleRows) {
      handleCloseDeleteMultipleRows()
      return
    }
  }

  const handleCancelDeleteRow = () => {
    setDeleteIdRow(null)
  }

  const handleCancelDeleteLinkedDialog = () => setShowDeleteLinkedDialog(false)

  const handleDeleteClick = (row: ObjectDataRecord) => {
    if (row._id) {
      setDeleteIdRow(row._id)
      return
    }
  }

  const handleDeleteRow = () => {
    if (deleteIdRow) {
      deleteObjectDataRecords({ viewId, objectIds: [deleteIdRow] })
        .unwrap()
        .then(data => {
          const isLinkedError = Object.values(data).some(refCount => {
            if (refCount !== true) {
              setShowDeleteLinkedDialog(true)
              setDeleteIdRow(null)
              return true
            }
            return false
          })

          if (!isLinkedError) {
            handleRefreshObjectData()
            setDeleteIdRow(null)
          }
        })
    }
  }

  const handleDeleteMultipleRows = () => {
    const objectIds: string[] = []
    const rowsMap = apiRef.current.getSelectedRows()

    rowsMap.forEach((value: GridValidRowModel) => {
      objectIds.push(value._id)
    })

    deleteObjectDataRecords({ viewId, objectIds })
      .unwrap()
      .then(data => {
        const isLinkedErrors = Object.values(data).some(refCount => refCount !== true)

        if (isLinkedErrors) {
          setShowDeleteLinkedDialog(true)
        }
        handleRefreshObjectData()
      })
    handleCloseDeleteMultipleRows()
    setShowDeleteMultipleDialog(false)
  }

  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    if (!params) {
      return
    }

    const { parentFieldName, fieldName } = getParsedFields(params.colDef.field)

    updateRestrictions([
      {
        viewId,
        parentFieldName,
        fieldName,
        restrictionType: RESTRICTION_TYPE.WIDTH,
        restrictionName: RESTRICTION_NAME.WIDTH,
        value: Math.round(params.width),
      },
    ])
  }

  const handleHideColumnsDebounce = useCallback(
    useDebounceFn({
      fn: (newModel: GridColumnVisibilityModel, prevModel: GridColumnVisibilityModel) => {
        const resultModel = compareModels(prevModel, newModel)
        const modelEqualityCheck = compareObjects(prevModel, newModel)

        if (modelEqualityCheck) {
          return
        }

        const columnVisibModel = Object.keys(resultModel).map((field): RestrictionDTO => {
          const { parentFieldName, fieldName } = getParsedFields(field)
          return {
            viewId,
            parentFieldName,
            fieldName,
            restrictionType: RESTRICTION_TYPE.SHOW,
            restrictionName: RESTRICTION_NAME.SHOW,
            value: resultModel[field],
          }
        })

        updateRestrictions(columnVisibModel)
          .then(() => onSetColumnVisibilityModel(newModel))
          .catch(() => onSetColumnVisibilityModel(prevModel))
      },
      delay: 1000,
    }),
    []
  )

  const handleChangeColumnVisibilityModel = (model: GridColumnVisibilityModel) => {
    if (Object.entries(model).length === 0) {
      columns.forEach(column => {
        model[column.field] = true
      })
    }

    onSetColumnVisibilityModel(model)
    handleHideColumnsDebounce(model, initialColumnVisibilityModel)
  }

  const handlePinnedColumnsChange = (pinnedColumns: GridPinnedColumns) => {
    if (pinnedColumnsRestrictions === undefined) {
      return
    }

    const currentPinnedColumns = pinnedColumnsRestrictions
      ? transformFixRestrictions(pinnedColumnsRestrictions)
      : ([] as GridPinnedColumns)

    // Поиск и преобразование новых закрепленные колонки
    const newPinnedColumns = findPinnedColumnsDifferences(
      viewId,
      currentPinnedColumns,
      pinnedColumns
    )

    // Поиск и преобразование колонок, которые были откреплены
    const unpinnedColumns = findPinnedColumnsDifferences(
      viewId,
      pinnedColumns,
      currentPinnedColumns,
      null
    )

    updateRestrictions([...unpinnedColumns, ...newPinnedColumns])
  }

  return {
    state: {
      errors,
      loading,
      gridLoading,
      isConfirmSave,
      isShowConfigSettings,
      isOpenShowColumnsSettings,
      isShowDeleteMultipleRows,
      isLoadingRestrictions,
      rowModesModel,
      selectionModel,
      showValidationGrid,
      deleteIdRow,
      showDeleteLinkedDialog,
      showDeleteMultipleDialog,
      hasEditableRowModes,
    },
    data: {
      currentSort,
      objectData,
      totalPages,
      currentPage,
      isLoadingObjectData,
    },
    handlers: {
      handleSetError,
      handleSetLoading,
      handleSetConfirmSave,
      handleShowConfigSettings,
      handleOpenShowColumnsSettings,
      handleAddRow,
      handleProcessRowUpdate,
      handleRowEditStop,
      handleSetRowModesModel,
      handleShowDeleteMultipleRows,
      handleCloseDeleteMultipleRows,
      handleSelectClick,
      handleDeleteMultipleRows,
      handleCopyRow,
      handleDeleteRow,
      handleSetSelectionModel,
      handleKeyDown,
      handleRefreshObjectData,
      handleSetObjectData,
      handleClickSearchFilter,
      handleChangeModelSort,
      handleObjectDataPageChange,
      handleColumnWidthChange,
      handleChangeColumnVisibilityModel,
      handlePinnedColumnsChange,
      handleShowValidationGrid,
      handleDeleteClick,
      handleCancelDeleteRow,
      handleSubmitEditRow,
      handleCloseEditRow,
      handleCancelDeleteLinkedDialog,
      handleSetDeleteMultipleDialog,
      handleEditRowStart,
    },
  }
}
