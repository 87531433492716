import { ViewColumn } from '../../../types'

export const getLinkedObjectCode = (column: ViewColumn): string | undefined => {
  const hasParentField = Boolean(column.parentField)

  if (hasParentField) {
    return column.objectCode
  }

  if (column.model) {
    return column.model
  }

  return undefined
}
