import { GridSortModel, GridApi } from '@microservices/wiskey-react-components'
import { SortType } from '@hooks'
import { MutableRefObject } from 'react'

export const getSortParam = (
  value: GridSortModel,
  apiRef: MutableRefObject<GridApi>
): SortType[] => {
  return value.map(sortModel => ({
    ...sortModel,
    sortKey: apiRef.current.getColumn(sortModel.field).sortKey || '',
  }))
}
