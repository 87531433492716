import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { FormValues } from '@pages/ViewCreateOrEdit/types'
import { useFetchAllObjectsQuery } from '@redux/api'
import { useHandlers } from './useHandlers'
import { AutocompleteOption } from '../../../types'

export const useViewCreateOrEdit = () => {
  const defaultValues: FormValues = { viewTitle: '', object: null }
  const methods = useForm<FormValues>({ defaultValues })
  const { reset } = methods

  const { id = '' } = useParams()

  const isEdit = Boolean(id)
  const type = isEdit ? 'edit' : 'create'

  const { data: objects } = useFetchAllObjectsQuery()
  const objectOptions: AutocompleteOption<string>[] = useMemo(
    () => objects?.map(({ code, title }) => ({ id: code, label: title })) || [],
    [objects]
  )

  const { state, data, handlers } = useHandlers()

  const { view } = data

  useEffect(() => {
    if (view) {
      reset({
        viewId: view.viewId,
        viewTitle: view.viewTitle,
        object: objectOptions.find(object => object.id === view.objectCode) || null,
      })
    }
  }, [view, objectOptions])

  return {
    state: { ...state },
    data: { ...data, type, objectOptions, methods, isEdit },
    handlers,
  }
}
