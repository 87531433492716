import { FC, useContext } from 'react'

import { Backdrop, CircularProgress } from '@mui/material'

import {
  GridPanel,
  GridPanelProps,
  GridColumnsPanel,
  GridPanelWrapper,
} from '@microservices/wiskey-react-components'
import { PageContext } from '@pages/ConfiguredView'

type CustomPanelComponentProps = {
  loading: boolean
} & GridPanelProps

export const CustomPanelComponent: FC<CustomPanelComponentProps> = props => {
  const { gridLoading } = useContext(PageContext)

  return (
    <GridPanelWrapper>
      <GridPanel {...props} sx={{ position: 'relative' }}>
        <GridColumnsPanel />
        <Backdrop sx={{ color: '#fff', position: 'absolute' }} open={gridLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </GridPanel>
    </GridPanelWrapper>
  )
}
