import { FC } from 'react'
import { Box, Grid, ListItemText } from '@mui/material'

type UncertainListProps = {
  title: string
  code: string
}

export const UncertainList: FC<UncertainListProps> = ({ title, code }) => {
  return (
    <Box mb={2}>
      <Grid container flexWrap={'nowrap'} pl={1}>
        <Grid item sx={{ maxWidth: 230, overflowX: 'hidden' }}>
          <ListItemText primary={title} />
        </Grid>
      </Grid>
    </Box>
  )
}
