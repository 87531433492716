import { useState, useEffect } from 'react'

type useImitateLinkCtrlBehaviorReturn = {
  isItemSelectionDisabled: boolean
}

export const useImitateLinkCtrlBehavior = (): useImitateLinkCtrlBehaviorReturn => {
  // Нужно для того случая, когда пользователь хочет открыть ссылку в новой вкладке по ctrl
  const [isItemSelectionDisabled, setItemSelectionDisabled] = useState(false)

  useEffect(() => {
    const handleKeyboard = (e: KeyboardEvent, type: 'up' | 'down') => {
      if (type === 'up') {
        setItemSelectionDisabled(prev => (prev ? false : prev))
      }

      if (type === 'down') {
        setItemSelectionDisabled((prev: boolean) => {
          if (e.ctrlKey && !prev) {
            return true
          }

          return prev
        })
      }
    }

    document.addEventListener('keydown', e => handleKeyboard(e, 'down'))
    document.addEventListener('keyup', e => handleKeyboard(e, 'up'))

    return () => {
      document.removeEventListener('keydown', e => handleKeyboard(e, 'down'))
      document.removeEventListener('keyup', e => handleKeyboard(e, 'up'))
    }
  }, [])

  return { isItemSelectionDisabled }
}
