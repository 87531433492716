import { FIELD_TYPE } from '@constants'

/** Модель для отрисовки окна с конфигурацией табличного представления */
export type ObjectWithConfiguredRestrictions = {
  /** Заголовок объекта */
  objectTitle: string
  /** Код объекта */
  objectCode: string
  /** Список колонок объекта с ограничениями для отрисовки окна конфигурации */
  columns: ColumnWithConfiguredRestrictions[]
}

/** Колонка в конфигурации табличного представления */
export type ColumnWithConfiguredRestrictions = {
  /** Активация колонки */
  enabled: boolean
  /** Наименование поля */
  field: string
  /** Описание поля */
  desc: string
  /** Тип поля */
  type: FIELD_TYPE
  /** Базовая обязательность поля */
  baseRequired: boolean
  /** Выставленная пользовательская обязательность */
  userRequired: boolean
  /** Уникальность */
  unique: boolean
  /** Ограничение по символам */
  symbolsNumLimit: number | null
  /** Список вложенных полей, если текущее поле является ссылочным */
  embeddedFields: ConfiguredColumnEmbeddedField[] | null
}

/** Вложенное поле для ссылочного поля в конфигурации табличного представления  */
export type ConfiguredColumnEmbeddedField = {
  /** Наименование вложенного поля */
  name: string
  /** Активация колонки вложенного поля */
  enabled: boolean
}

/** Перечисление типов ограничения  */
export enum RESTRICTION_TYPE {
  /** Уникальность */
  UNIQUE = 'UNIQUE',
  /** Обязательность */
  USER_REQUIRED = 'USER_REQUIRED',
  /** Активность */
  ENABLED = 'ENABLED',
  /** Количество символов */
  SYMBOLS_NUMBER = 'SYMBOLS_NUMBER',
  /** Сортировка поля */
  SORTING = 'SORTING',
  /** Отображение колонки с данным полем у пользователя */
  SHOW = 'SHOW',
  /** Ширина колонки с данным полем у пользователя */
  WIDTH = 'WIDTH',
  /** Порядок колонки с данным полем в таблице у пользователя */
  ORDER = 'ORDER',
  /** Закрепление колонки справа/слева в таблице у пользователя */
  FIX = 'FIX',
  /** Неизвестный тип. Используется на сервере */
  UNDEFINED = 'UNDEFINED',
}

/** Перечисление названий ограничений  */
export enum RESTRICTION_NAME {
  /** Уникальность */
  UNIQUE = 'unique',
  /** Обязательность */
  USER_REQUIRED = 'userRequired',
  /** Активность */
  ENABLED = 'enabled',
  /** Количество символов */
  SYMBOLS_NUMBER = 'symbolsNumLimit',
  /** Сортировка поля */
  SORTING = 'sorting',
  /** Отображение колонки с данным полем у пользователя */
  SHOW = 'show',
  /** Ширина колонки с данным полем у пользователя */
  WIDTH = 'width',
  /** Порядок колонки с данным полем в таблице у пользователя */
  ORDER = 'order',
  /** Закрепление колонки справа/слева в таблице у пользователя */
  FIX = 'fix',
  /** Неизвестный тип. Используется на сервере */
  UNDEFINED = 'undefined',
}

/** Модель ограничения */
export type RestrictionDTO = {
  /** Идентификатор представления */
  viewId: number
  /** Наименование родительского поля при наличии */
  parentFieldName: string | null
  /** Наименование поля */
  fieldName: string
  /** Тип ограничения */
  restrictionType: RESTRICTION_TYPE
  /** Наименование ограничения */
  restrictionName: RESTRICTION_NAME
  /** Значение ограничения */
  value: unknown
}
