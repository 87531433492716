export const localizationEn = {
  logout: 'Logout',
  sidebar: {
    search: 'Search',
    links: {
      uiConfigs: 'UI configs',
      views: 'Views',
      menuSettings: 'Menu Settings',
    },
  },
  modal: {
    saveBtnText: 'Save',
    closeBtnText: 'Close',
    cancelBtnText: 'Cancel',
    edit: {
      title: 'Edit',
    },
    cancelMenuChanges: {
      title: 'Canceling',
      text: 'Are you sure you want to cancel?',
    },
    delete: {
      title: 'Removing',
      warning: 'Are you sure you want to delete?',
      btn: 'Delete',
    },
  },
  menuSetting: {
    notAssigned: 'Not assigned',
    title: 'Menu settings',
    elementType: {
      placeholder: 'Select an element type',
    },
    element: { name: 'View', placeholder: 'Select a View' },
    menuTitle: { name: 'Title', placeholder: 'Menu title' },
    group: { name: 'Group of views', placeholder: 'Group name' },
    alreadyUsed: 'is already used',
    groups: {
      title: 'Groups',
    },
  },
  button: {
    edit: 'Edit',
    close: 'Close',
    save: 'Save',
    cancel: 'Cancel',
  },
  notifications: {
    success: 'Success',
    error: 'Something went wrong',
    leave: 'You have unsaved changes. Are you sure?',
  },
  configSettings: {
    modalTitle: 'Config settings',
    searchInput: {
      label: 'Find column',
      placeholder: 'Column title',
    },
    settingTitle: {
      column: 'Column title',
      activation: 'Activation',
      required: 'Required',
      uniqueness: 'Uniqueness',
      numberOfSymbols: 'Number of symbols',
    },
    embeddedFieldsPopover: {
      hideBtn: 'Hide all',
      showBtn: 'Show all',
    },
  },
  configuredView: {
    configBtn: 'Config settings',
    addBtn: 'Add',
    validationErrorsBtn: 'Validation errors',
    deleteLinkedModal: {
      title: 'Delete',
      successDeleted: '{{count}} entries have been deleted successfully.',
      single: 'You cannot delete this entry because there are reference fields.',
      multiple:
        'Attention! You cannot delete these entries because there are reference fields ({{count}} entries).',
      end: 'Edit the data in the linked table or cancel the operation.',
      edit: 'Edit',
      id: 'ID {{id}} has reference with objects:',
    },
    deleteModal: {
      title: 'Delete',
      single: 'Are you sure you want to delete this record?',
      multiple: 'Are you sure you want to delete these records? ({{count}} records)',
      confirm: 'Yes',
    },
    rowContextMenu: {
      done: 'Done',
      close: 'Close',
      copy: 'Copy',
      select: 'Select',
      delete: 'Delete',
    },
    multipleRowsDeletion: {
      select: 'Select',
      delete: 'Delete',
      cancel: 'Cancel',
    },
  },
  configViewList: {
    columns: {
      viewId: 'ID',
      viewTitle: 'Title',
      objectTitle: 'Object',
    },
    addButton: 'Add View',
    search: 'Search',
    tabs: {
      all: 'All',
    },
    models: 'Models',
    modelsControl: 'UI Models Control',
  },
  validation: {
    required: 'Required',
  },
  viewCreateOrEdit: {
    title: {
      edit: 'Edit View',
      create: 'New View',
    },
    placeholder: {
      object: 'Select an Object',
      viewTitle: 'Title',
    },
    label: {
      object: 'Object',
      viewTitle: 'Title',
    },
  },
  copying: {
    view: {
      dialogTitle: 'Copying view',
    },
    inputs: {
      title: {
        label: 'Title',
        placeholder: 'Title',
      },
      objectCode: {
        label: 'Object',
      },
    },
  },
}
