import { useState } from 'react'
import {
  ItemId,
  moveItemOnTree,
  TreeData,
  TreeDestinationPosition,
  TreeItem,
  TreeSourcePosition,
} from '@atlaskit/tree'

export const useDraggableTree = () => {
  const [tree, setTree] = useState<TreeData | null>(null)

  const deleteTreeItem = (itemId: ItemId) => {
    if (tree) {
      const newTree = { ...tree }
      let parentId: ItemId = ''

      delete newTree.items[itemId]

      for (const value of Object.values(tree.items)) {
        if (value.children.includes(itemId)) {
          parentId = value.id
        }
      }
      if (parentId) {
        const parent = newTree.items[parentId]
        const children = parent.children.filter(id => id !== itemId)
        newTree.items[parentId] = {
          ...parent,
          children,
          hasChildren: children.length > 0,
        }
        setTree(newTree)
      }
    }
  }

  const addTreeItem = <T extends { code: string; title: string }>(
    option: T,
    treeItem?: TreeItem
  ) => {
    if (tree && option) {
      const itemId = `0-${option.code}`
      const newTree: TreeData = { ...tree }
      newTree.items['0'].children.push(itemId)
      newTree.items[itemId] = {
        id: itemId,
        children: [],
        data: { ...option, title: option.title },
        hasChildren: false,
        isChildrenLoading: false,
        isExpanded: false,
      }
      const source: TreeSourcePosition = {
        index: newTree.items['0'].children.length - 1,
        parentId: '0',
      }
      const destination: TreeDestinationPosition = {
        parentId: treeItem ? treeItem.id : '0',
      }
      setTree(moveItemOnTree(newTree, source, destination))
    }
  }

  return {
    tree,
    setTree,
    deleteTreeItem,
    addTreeItem,
  }
}
