import { FC, useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'

import { Button, GridEvents } from '@microservices/wiskey-react-components'

import { PageContext } from '@pages/ConfiguredView/ConfiguredView'
import { DeleteDialog } from '../DeleteDialog'

export const MultipleRowsDeletion: FC = () => {
  const { t } = useTranslation()
  const {
    handleDeleteMultipleRows,
    handleCloseDeleteMultipleRows,
    handleSetDeleteMultipleDialog,
    showDeleteMultipleDialog,
    apiRef,
  } = useContext(PageContext)

  const [selectedRowsAmount, setSelectedRowsAmount] = useState(0)

  useEffect(() => {
    apiRef.current.subscribeEvent(GridEvents.selectionChange, params => {
      setSelectedRowsAmount(params.length)
    })
  }, [])

  return (
    <Grid container alignItems='center' gap={2} mb={2}>
      {showDeleteMultipleDialog && (
        <DeleteDialog
          count={selectedRowsAmount}
          isShow={showDeleteMultipleDialog}
          onSave={handleDeleteMultipleRows}
          onClose={() => handleSetDeleteMultipleDialog(false)}
        />
      )}
      <Box>
        <Typography sx={{ display: 'inline' }}>
          {t('configuredView.multipleRowsDeletion.select')}:{' '}
        </Typography>
        <Typography sx={{ display: 'inline', color: theme => theme.palette.primary.main }}>
          {selectedRowsAmount}
        </Typography>
      </Box>
      <Box>
        <Button
          variant='contained'
          onClick={() => handleSetDeleteMultipleDialog(true)}
          size='small'
          disabled={selectedRowsAmount === 0}
          sx={{ mr: 2 }}
        >
          {t('configuredView.multipleRowsDeletion.delete')}
        </Button>
        <Button variant='outlined' size='small' onClick={() => handleCloseDeleteMultipleRows()}>
          {t('configuredView.multipleRowsDeletion.cancel')}
        </Button>
      </Box>
    </Grid>
  )
}
