import { FC, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@mui/material'

const Title: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Typography
      variant={'subtitle2'}
      sx={{
        color: theme => theme.palette.text.secondary,
        textTransform: 'uppercase',
        fontWeight: 500,
      }}
    >
      {children}
    </Typography>
  )
}

export const ConfigSettingsTitle: FC = () => {
  const { t } = useTranslation()

  return (
    <Grid item container direction={'row'} sx={{ mb: 2 }}>
      <Grid item container xs={3}>
        <Title>{t('configSettings.settingTitle.column')}</Title>
      </Grid>
      <Grid item container justifyContent={'center'} xs={2}>
        <Title>{t('configSettings.settingTitle.activation')}</Title>
      </Grid>
      <Grid item container justifyContent={'center'} xs={2}>
        <Title>{t('configSettings.settingTitle.required')}</Title>
      </Grid>
      <Grid item container justifyContent={'center'} xs={2}>
        <Title>{t('configSettings.settingTitle.uniqueness')}</Title>
      </Grid>
      <Grid item container xs={3}>
        <Title>{t('configSettings.settingTitle.numberOfSymbols')}</Title>
      </Grid>
    </Grid>
  )
}
