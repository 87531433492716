import { EventListener, EVENT_NAME, subscribe, unsubscribe } from '@events'
import { useEffect } from 'react'

export const useSubscribeEvent = (eventName: EVENT_NAME, listener: EventListener) => {
  useEffect(() => {
    subscribe(eventName, listener)
    return () => {
      unsubscribe(eventName, listener)
    }
  }, [])
}
