import { FC, ReactNode } from 'react'
import { Paper } from '@mui/material'

import { borderWidthByMode } from '@helpers'

type PageContentLayoutProps = {
  children: ReactNode
  hidden?: boolean
}

export const PageContentLayout: FC<PageContentLayoutProps> = ({ children, hidden }) => {
  if (hidden) {
    return <>{children}</>
  }
  return (
    <Paper
      sx={{ borderRadius: 1, borderWidth: theme => borderWidthByMode(theme) }}
      variant='outlined'
    >
      {children}
    </Paper>
  )
}
