import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ThemeState } from '../../types'
import { PaletteMode } from '@mui/material'

const initialState: ThemeState = {
  mode: 'light',
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeMode: (state, action: PayloadAction<PaletteMode>) => {
      state.mode = action.payload
    },
  },
})

export default themeSlice.reducer
export const { setThemeMode } = themeSlice.actions
