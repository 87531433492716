import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Badge } from '@mui/material'

import { Button, ButtonProps } from '@microservices/wiskey-react-components'

import { ReturnUpdateObjectDataRecord } from '../../../../types'

type ValidationErrorsButtonProps = {
  objectValidationErrors: ReturnUpdateObjectDataRecord[] | undefined
  onClick: ButtonProps['onClick']
}

export const ValidationErrorsButton: FC<ValidationErrorsButtonProps> = ({
  objectValidationErrors = [],
  onClick,
}) => {
  const { t } = useTranslation()

  if (!objectValidationErrors?.length) {
    return <></>
  }

  return (
    <Badge badgeContent={objectValidationErrors?.length} color='error' sx={{ mr: 2 }}>
      <Button variant={'outlined'} color='error' onClick={onClick}>
        {t('configuredView.validationErrorsBtn')}
      </Button>
    </Badge>
  )
}
