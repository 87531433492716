export enum MODAL_TYPE {
  EDIT = 'edit',
  CREATE = 'create',
}

export enum MODEL_TYPE {
  VIEW = 'view',
  FORM = 'forms',
  ACTION = 'action',
  COLUMN = 'column',
  FIELD = 'field',
  GANTT = 'gantt',
  LIST_CONTROL = 'listControl',
  GANTT_ACTION_JOBS = 'gantt_action_jobs',
  GANTT_ACTION_TIMELINES = 'gantt_action_timelines',
}

export enum ACTION_CODE {
  OPEN_FORM_MODEL = 'open_form_model',
}

export enum EVENT_CODE {
  ON_ROW_CLICK = 'on_row_click',
  ON_ROW_CREATE = 'on_row_create',
  ON_ROW_EDIT = 'on_row_edit',
}
