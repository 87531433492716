import { TreeItemProps, treeItemClasses, TreeItem } from '@mui/lab'
import { SxProps, styled, Theme } from '@mui/material'
import { THEME_MODE } from '@constants'

export const textStyles: SxProps<Theme> = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: (theme: Theme) =>
    theme.palette.mode === THEME_MODE.LIGHT ? theme.palette.grey[900] : theme.palette.text.primary,
  textDecoration: 'none',
}

export const titleStyles: SxProps<Theme> = {
  ml: 1.5,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: (theme: Theme) =>
    theme.palette.mode === THEME_MODE.LIGHT ? theme.palette.grey[600] : theme.palette.text.primary,
  textTransform: 'uppercase',
  userSelect: 'none',
}

export const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(
  ({ theme, sx }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      position: 'absolute',
      right: 20,
      top: 'calc(50% - 12px)',
    },
    [`& .${treeItemClasses.label}`]: {
      marginRight: 40,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [`& .${treeItemClasses.content}`]: {
      paddingRight: theme.spacing(1),
      position: 'relative',
      height: 50,
      fontSize: 20,
      width: '100%',
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 0,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
    },
    ...sx,
  })
)
