//https://www.toptal.com/developers/keycode инфа о любой клавише на клавиатуре (перейдя на сайт, нажми на любую клавишу)

export enum KEYS {
  // TODO исправить на enum во всем проекте
  C = 'c',
  DELETE = 'Delete',
  S = 's',
  BACKSPACE = 'Backspace',
  Ы = 'ы',
  С = 'с',
  ESCAPE = 'Escape',
}

export enum KEYS_CODES {
  KEY_M = 'KeyM', //  Ь, ь, M, m
  KEY_I = 'KeyI', //  Ш, ш, I, i
}

export enum KEYBOARD_EVENT_PROPS {
  SHIFT_KEY = 'shiftKey',
  CTRL_KEY = 'ctrlKey',
  ALT_KEY = 'altKey',
}

export const HOTKEYS_FOR_TABLE = {
  // TODO привести к единообразному виду
  SELECT: ['s', 'ы'],
  COPY: ['c', 'с'],
}
