import { ChangeEvent, FC, ReactElement } from 'react'

import { Box, Grid, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'

import { Checkbox, GridRenderEditCellParams } from '@microservices/wiskey-react-components'
import { ObjectDataRecord, ViewColumn } from '../../../../types'
import { FIELD_VALUE_TYPE } from '@constants'
import { ErrorRow } from '@pages/ConfiguredView/hooks'
import { ErrorIconWithTooltip } from '../ErrorIconWithTooltip'

export type CellGeneratorProps = {
  column: ViewColumn
  error?: ErrorRow
  handleSetObjectData: (
    value: ((prevState: ObjectDataRecord[]) => ObjectDataRecord[]) | ObjectDataRecord[]
  ) => void
  pkFieldName?: string
} & GridRenderEditCellParams<any, ObjectDataRecord>

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    whiteSpace: 'normal !important',
  },
}))

export const CellGenerator: FC<CellGeneratorProps> = ({
  column,
  error,
  handleSetObjectData,
  pkFieldName = '_id',
  ...params
}) => {
  const { value, field, row } = params
  const { valueType } = column
  const errorMessage = error && error[field]

  const handleChangeCheckbox = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    handleSetObjectData(prevRows => {
      const foundRowIndex = prevRows.findIndex(prevRow => prevRow[pkFieldName] === row[pkFieldName])
      if (foundRowIndex !== -1) {
        const newRows = [...prevRows]
        newRows[foundRowIndex] = {
          ...newRows[foundRowIndex],
          [field]: checked,
        }
        return newRows
      }
      return prevRows
    })

    // apiRef.current.setEditCellValue({ id, field, value: checked })
    // resetValidate()
  }

  const renderField = (): ReactElement => {
    switch (valueType) {
      case FIELD_VALUE_TYPE.BOOLEAN:
        return (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {/* TODO дизейблим пока не реализовано редактирование значения без ручного открытия режима редактирования клетки*/}
            <Checkbox checked={Boolean(value)} onChange={handleChangeCheckbox} disabled />
          </Box>
        )
      default:
        return value
    }
  }

  return (
    <Grid
      container
      className={errorMessage ? 'Mui-error' : undefined}
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        px: 1,
        position: 'relative',
      }}
    >
      <Grid
        item
        sx={{
          flex: errorMessage ? '0 0 90%' : 'auto',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {renderField()}
      </Grid>
      {errorMessage && (
        <Grid
          item
          sx={{
            flex: errorMessage ? '0 0 10%' : 'auto',
            display: 'flex',
          }}
        >
          <ErrorIconWithTooltip errorMessage={errorMessage} />
        </Grid>
      )}
    </Grid>
  )
}
