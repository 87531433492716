import { TextField } from '@microservices/wiskey-react-components'
import { Clear } from '@mui/icons-material'
import { IconButton, TextFieldProps } from '@mui/material'
import { FC } from 'react'

export type SearchTextFieldProps = {
  onClear: () => void
} & TextFieldProps

export const SearchTextField: FC<SearchTextFieldProps> = ({ onClear, ...props }) => {
  return (
    <TextField
      {...props}
      fullWidth
      variant='outlined'
      size='small'
      InputProps={{
        endAdornment: (
          <>
            <IconButton onClick={onClear}>
              <Clear fontSize='small' />
            </IconButton>
          </>
        ),
      }}
    />
  )
}
