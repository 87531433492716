import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CopyModelDataType, DataOfCopiedModelType, DialogCopyModelType } from 'src/types'
import { GENERATOR_INPUT_TYPE } from '@constants'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

type CopyDialogProps = {
  isShow: true
  onClose: () => void
  copyData: DataOfCopiedModelType
  onCopy: (data: CopyModelDataType) => void
  dialogTitle: string
  loading?: boolean
}

export const CopyDialog: FC<CopyDialogProps> = ({
  isShow,
  onClose,
  copyData,
  onCopy,
  dialogTitle,
  loading,
}) => {
  const methods = useForm<DialogCopyModelType>()
  const { t } = useTranslation()

  const { handleSubmit, reset } = methods

  useEffect(() => {
    reset({ title: copyData.viewTitle, objectCode: copyData.objectCode })
  }, [copyData])

  const handleSave = (formValue: DialogCopyModelType) => {
    const { title } = formValue

    onCopy({ title, viewId: copyData.viewId })
  }

  return (
    <ModalWrapper
      isShow={isShow}
      onClose={onClose}
      title={dialogTitle}
      onSave={handleSubmit(handleSave)}
      disabledSave={loading}
    >
      <Box my={2}>
        <FormProvider {...methods}>
          <FormGenerator
            inputs={[
              {
                name: 'title',
                inputType: GENERATOR_INPUT_TYPE.INPUT,
                label: t('copying.inputs.title.label'),
                placeholder: t('copying.inputs.title.placeholder'),
                rules: { required: true },
              },
              {
                name: 'objectCode',
                inputType: GENERATOR_INPUT_TYPE.SELECT,
                selectOptions: [{ id: 0, value: copyData.objectCode, name: copyData.objectTitle }],
                disabled: true,
                label: t('copying.inputs.objectCode.label'),
              },
            ]}
          />
        </FormProvider>
      </Box>
    </ModalWrapper>
  )
}
