import { useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ConfirmModal,
  DataGrid,
  GridCellParams,
  GridColumns,
} from '@microservices/wiskey-react-components'

import { DataOfCopiedModelType, ShortViewDTO } from '../../types'
import { generateDataGridActionsColumn } from '@helpers'

type ViewListProps<T> = {
  columns: GridColumns<ShortViewDTO>
  rows: T[]
  onDelete?: (id: number) => void
  onCopy: (data: DataOfCopiedModelType) => void
  onEdit: (viewId: number) => void
  loading?: boolean
  // onSetSorting: (value: GridSortModel | null) => void
  // currentSort: GridSortModel
}

export const ViewList = <T extends { viewId: number }>({
  columns,
  rows,
  onDelete,
  onCopy,
  onEdit,
  loading,
}: ViewListProps<T>) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [currentRowId, setCurrentRowId] = useState<number>()
  const { t } = useTranslation()

  const handleEdit = (params: GridCellParams<T>) => {
    onEdit?.(params.row.viewId)
  }

  const handleClickDelete = (params: GridCellParams<T>) => {
    if (params.row.viewId) {
      setShowDeleteModal(true)
      setCurrentRowId(params.row.viewId)
    }
  }

  const handleDelete = () => {
    if (currentRowId) {
      onDelete?.(currentRowId)
      setShowDeleteModal(false)
    }
  }

  const handleClose = () => setShowDeleteModal(false)

  // const handleChangeModelSort = (value: GridSortModel | null) => {
  //   if (value?.length) {
  //     onSetSorting(value)
  //     return
  //   }
  //   onSetSorting(null)
  // }

  const handleCopy = (params: GridCellParams<T>) => {
    const { viewId, objectCode, viewTitle, objectTitle } = params.row
    onCopy?.({ viewId, objectCode, viewTitle, objectTitle })
  }

  const columnsWithActions = useMemo(
    () => [
      ...columns,
      generateDataGridActionsColumn({
        onEdit: handleEdit,
        onCopy: handleCopy,
        onDelete: handleClickDelete,
      }),
    ],
    [columns]
  )

  return (
    <Grid container spacing={2}>
      {showDeleteModal && (
        <ConfirmModal
          title={t('modal.delete.title')}
          text={t('modal.delete.warning')}
          actionBtnText={t('modal.delete.btn')}
          isShow={showDeleteModal}
          onClose={handleClose}
          onConfirm={handleDelete}
        />
      )}
      <Grid item container>
        <DataGrid
          rows={rows}
          columns={columnsWithActions}
          loading={loading}
          hideFooter
          // onSortModelChange={handleChangeModelSort}
          // sortModel={currentSort}
          disableMultipleColumnsSorting
          // sortingMode={'server'}
          sortingMode={'client'}
          getRowId={row => row.viewId}
        />
      </Grid>
    </Grid>
  )
}
