import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import i18next from 'i18next'

import { SnackbarsState, UtilitySnack } from '../../types'

const initialState: SnackbarsState = {
  text: 'Success',
  type: 'success',
  isShown: false,
  statusCode: 200,
}

const snackbarsSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showMessage: (state, action: PayloadAction<UtilitySnack>) => {
      const payload = action.payload
      state.type = payload.type
      state.isShown = true

      if (payload.statusCode === 302) {
        state.statusCode = 302
        state.text = 'Session expired'
        location.reload()

        return
      }

      if (!payload.text) {
        state.text =
          payload.type === 'success'
            ? i18next.t('notifications.success')
            : i18next.t('notifications.error')
      } else {
        state.text = payload.text
      }

      // if (!payload.statusCode) {
      //   state.statusCode = 200
      //
      //   return
      // }

      state.statusCode = payload.statusCode
    },
    hideMessage: state => {
      state.isShown = false
    },
  },
})

export default snackbarsSlice.reducer
export const { showMessage, hideMessage } = snackbarsSlice.actions
