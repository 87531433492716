import { ROW_ID } from '@constants'
import { GridColumnType } from '../hooks'

export const getDefaultRowByObject = (columns: GridColumnType[]): Record<string, null> => {
  const result: Record<string, null> = {}

  columns.forEach(({ field }) => {
    if (field !== ROW_ID) {
      result[field] = null
    }
  })
  return result
}
