export enum FIELD_VALUE_TYPE {
  STRING = 'string',
  INTEGER = 'integer',
  DATETIME = 'datetime',
  BOOLEAN = 'boolean',
  /** @deprecated **/
  OBJ_EMBEDDED = 'obj_embedded',
  OBJ_PK_LINK = 'obj_pk_link',
  OBJ_INTERNAL_ID_LINK = 'obj_internal_id_link',
}

export const OBJECT_VALUE_TYPE: FIELD_VALUE_TYPE[] = [
  FIELD_VALUE_TYPE.OBJ_EMBEDDED,
  FIELD_VALUE_TYPE.OBJ_PK_LINK,
  FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
]

export enum FIELD_TYPE {
  SINGLE = 'single',
  OBJECT_LINK = 'object_link',
}
