export enum GENERATOR_INPUT_TYPE {
  INPUT = 'input',
  SELECT = 'select',
  TEXTAREA = 'textarea',
  SWITCH = 'switch',
  CHECKBOX = 'checkbox',
  DATE_TIME_PICKER = 'datepicker',
  AUTOCOMPLETE = 'autocomplete',
  DATE_RANGE_PICKER = 'daterangepicker',
}
