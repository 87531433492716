import { FC, useMemo } from 'react'
import {
  GridEnrichedColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@microservices/wiskey-react-components'
import { COLUMNS } from '@constants'

type CustomToolbarComponentProps = {
  columns: GridEnrichedColDef<any, any, any>[]
  showColumnsButton: boolean
}

export const CustomToolbarComponent: FC<CustomToolbarComponentProps> = ({
  columns,
  showColumnsButton,
}) => {
  const columnsLength = useMemo(
    () => columns.filter(col => col.field !== COLUMNS.ACTIONS).length,
    [columns]
  )

  return (
    <GridToolbarContainer>
      {columnsLength > 0 && showColumnsButton && <GridToolbarColumnsButton />}
    </GridToolbarContainer>
  )
}
