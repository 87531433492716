import {
  GridActionsCellItem,
  GridCellParams,
  GridEnrichedColDef,
  PINNED_COLUMN,
} from '@microservices/wiskey-react-components'
import { ContentCopy, Delete, Edit } from '@mui/icons-material'

type HandlerActionClick = (params: GridCellParams<any, any, any>) => void

type DataGridActionsColumnParams = {
  onCopy?: HandlerActionClick
  onEdit?: HandlerActionClick
  onDelete?: HandlerActionClick
  columnDef?: GridEnrichedColDef
}

export const generateDataGridActionsColumn = ({
  onCopy,
  onEdit,
  onDelete,
  columnDef,
}: DataGridActionsColumnParams): GridEnrichedColDef => {
  return {
    field: 'actions',
    type: 'actions',
    align: 'right',
    minWidth: onCopy ? 150 : 100,
    resizable: false,
    pinnedColumn: PINNED_COLUMN.RIGHT, // Для промежуточных расчётов. Не влияет на реальное закрепление.
    getActions: (params: GridCellParams) => [
      ...(onCopy
        ? [
            <GridActionsCellItem
              key={params.id}
              icon={<ContentCopy />}
              label={'Copy'}
              onClick={() => onCopy?.(params)}
              showInMenu={false}
            />,
          ]
        : []),
      <GridActionsCellItem
        key={params.id}
        icon={<Edit />}
        label={'Edit'}
        onClick={() => onEdit?.(params)}
        showInMenu={false}
      />,
      <GridActionsCellItem
        key={params.id}
        icon={<Delete />}
        label={'Delete'}
        onClick={() => onDelete?.(params)}
        showInMenu={false}
      />,
    ],
    ...columnDef,
  } as GridEnrichedColDef
}
