import { FC, MouseEvent, useState } from 'react'

import { Grid, IconButton, Typography } from '@mui/material'
import { Link as LinkIcon } from '@mui/icons-material'
import { useFormContext } from 'react-hook-form'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { EmbeddedFieldsPopover } from '../EmbeddedFieldsPopover'
import { ColumnWithConfiguredRestrictions } from '../../../../../../types'
import { FormInput } from '@microservices/wiskey-react-components'
import { RestrictionsForm } from '@pages/ConfiguredView/types'

type ConfigSettingsItemProps = {
  column: ColumnWithConfiguredRestrictions
  pkFieldName?: string
}

export const ConfigSettingsItem: FC<ConfigSettingsItemProps> = ({ column, pkFieldName = '_id' }) => {
  const { field, embeddedFields, baseRequired } = column
  const { getValues } = useFormContext<RestrictionsForm>()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const itemState = getValues(field)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const disabledSwitch = field === pkFieldName && itemState.enabled
  const disabledUserRequired = !itemState.enabled || baseRequired

  return (
    <Grid item container direction={'row'}>
      <Grid item container xs={3} alignItems={'start'} wrap={'nowrap'}>
        <Grid item zeroMinWidth>
          <Typography
            title={field}
            noWrap
            sx={{ color: theme => theme.palette.text.secondary, textTransform: 'capitalize' }}
          >
            {field}
          </Typography>
        </Grid>
        {embeddedFields && (
          <Grid item>
            <IconButton onClick={handleClick} disabled={!itemState?.enabled} sx={{ ml: 1, p: 0 }}>
              <LinkIcon color={!itemState.enabled ? 'disabled' : 'primary'} />
            </IconButton>
            <EmbeddedFieldsPopover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              field={field}
              embeddedFields={embeddedFields || []}
            />
          </Grid>
        )}
      </Grid>
      <Grid item container justifyContent={'center'} xs={2}>
        <FormInput
          disabled={disabledSwitch}
          name={`${field}.enabled`}
          inputType={GENERATOR_INPUT_TYPE.SWITCH}
        />
      </Grid>
      <Grid item container justifyContent={'center'} xs={2}>
        <FormInput
          disabled={disabledUserRequired}
          name={`${field}.userRequired`}
          inputType={GENERATOR_INPUT_TYPE.CHECKBOX}
        />
      </Grid>
      <Grid item container justifyContent={'center'} xs={2}>
        <FormInput
          disabled={!itemState.enabled}
          name={`${field}.unique`}
          inputType={GENERATOR_INPUT_TYPE.CHECKBOX}
        />
      </Grid>
      <Grid item container justifyContent={'center'} xs={3}>
        <FormInput
          disabled={!itemState.enabled}
          name={`${field}.symbolsNumLimit`}
          inputType={GENERATOR_INPUT_TYPE.INPUT}
          variant={'standard'}
          type={'number'}
          placeholder={'Number'}
          rules={{ min: 0 }}
        />
      </Grid>
    </Grid>
  )
}
