import { useState, useEffect } from 'react'
import { KEYBOARD_EVENT_PROPS } from '@constants'

export const useKeyPress = (targetKeyCode: string, extraKey?: KEYBOARD_EVENT_PROPS): boolean => {
  const [keyPressed, setKeyPressed] = useState(false)

  const downHandler = (e: KeyboardEvent) => {
    if (extraKey) {
      if (e[extraKey] && targetKeyCode === e.code) {
        setKeyPressed(true)
      }

      return
    }

    if (targetKeyCode === e.code) {
      setKeyPressed(true)
    }
  }

  const upHandler = (e: KeyboardEvent) => {
    if (extraKey) {
      if (e[extraKey] && targetKeyCode === e.code) {
        setKeyPressed(false)
      }

      return
    }

    if (targetKeyCode === e.code) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', downHandler)
    document.addEventListener('keyup', upHandler)

    return () => {
      document.removeEventListener('keydown', downHandler)
      document.removeEventListener('keyup', upHandler)
    }
  }, [])

  return keyPressed
}
