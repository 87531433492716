import { FC, ReactNode, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { v4 as uuid } from 'uuid'

import { getScrollbarWidth } from '@helpers'
import { ObjectDataRecord } from '../../../../types'

type RowStylesWrapperProps = {
  currentPage?: number
  objectData?: ObjectDataRecord[]
  pageSize: number
  children: ReactNode
}

const wrapperClassName = `configured-view-wrapper-${uuid()}`

export const RowStylesWrapper: FC<RowStylesWrapperProps> = ({
  currentPage = 0,
  pageSize,
  objectData,
  children,
}) => {
  // TODO: Найти лучший способ отслеживания padding-right.
  // На январь 2023, это проблема самой библиотеки:
  // https://material-ui-docs.netlify.app/x/react-data-grid/#commercial-version
  // (для воспроизведения бага в одном из примеров нужно вручную закрепить колонку)
  const [resizeWidth, setResizeWidth] = useState<number | null>(null)
  const [scrollBarWidth, setScrollbarWidth] = useState(getScrollbarWidth())

  useEffect(() => {
    if (!objectData) {
      return
    }

    if (objectData.length === 0) {
      return
    }

    if (objectData?.length > pageSize) {
      setScrollbarWidth(getScrollbarWidth())

      return
    }

    if (currentPage > 1 || objectData?.length < pageSize) {
      setScrollbarWidth(0)
    }
  }, [objectData, pageSize])

  useEffect(() => {
    if (!objectData?.length) {
      return
    }

    const resizeListenerCallback = (e: UIEvent) => {
      const rightPinnedColumnsContainer = document.querySelector(
        '.MuiDataGrid-pinnedColumns.MuiDataGrid-pinnedColumns--right'
      )

      if (!rightPinnedColumnsContainer) {
        return
      }

      setResizeWidth(rightPinnedColumnsContainer.getBoundingClientRect().width)
      setScrollbarWidth(getScrollbarWidth())
    }

    window.addEventListener('resize', resizeListenerCallback)

    return () => {
      window.removeEventListener('resize', resizeListenerCallback)
    }
  }, [objectData])

  return (
    <Box
      sx={{
        [`.${wrapperClassName} .MuiDataGrid-pinnedColumnHeaders--right`]: {
          paddingRight: `${scrollBarWidth}px !important`,
          width:
            resizeWidth && objectData && objectData.length >= 10
              ? `${resizeWidth + scrollBarWidth}px !important`
              : `${resizeWidth}px`,
        },
      }}
    >
      <Box className={wrapperClassName}>{children}</Box>
    </Box>
  )
}
