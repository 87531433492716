import { MutableRefObject, useEffect, useRef } from 'react'
import { GridApiPro, GridEvents, GridStateColDef } from '@microservices/wiskey-react-components'
import { getParsedFields } from '@pages/ConfiguredView/helpers/getParsedFields'
import { RESTRICTION_NAME, RESTRICTION_TYPE } from '../../../types'
import { useUpdateRestrictionsMutation } from '@redux/api/restriction.api'

/**
 * Обработчик перемещения колонки
 * @param apiRef
 * @param viewId
 */
export const useHandleColumnDrag = (apiRef: MutableRefObject<GridApiPro>, viewId: number) => {
  const [updateRestrictions] = useUpdateRestrictionsMutation()

  const handleColumnOrderChange = (field: string, order: number) => {
    const { parentFieldName, fieldName } = getParsedFields(field)
    updateRestrictions([
      {
        fieldName,
        viewId,
        parentFieldName,
        restrictionName: RESTRICTION_NAME.ORDER,
        restrictionType: RESTRICTION_TYPE.ORDER,
        value: order,
      },
    ])
  }

  const startVisibleColumns = useRef<GridStateColDef<any, any, any>[]>([])

  useEffect(() => {
    return apiRef.current.subscribeEvent(GridEvents.columnHeaderDragStart, () => {
      startVisibleColumns.current = apiRef?.current?.getVisibleColumns() || []
    })
  }, [apiRef])

  useEffect(() => {
    return apiRef.current.subscribeEvent(GridEvents.columnHeaderDragEnd, ({ field }) => {
      const newColumnOrder = apiRef.current.getColumnIndex(field)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const orderOfPrevColumn = startVisibleColumns.current[newColumnOrder]?.order

      if (isNaN(orderOfPrevColumn)) {
        return
      }

      handleColumnOrderChange(field, orderOfPrevColumn)
    })
  }, [apiRef])
}
