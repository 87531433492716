import { FC, useContext, useEffect, useMemo, useState } from 'react'

import { Box, SxProps } from '@mui/material'

import {
  Button,
  ConfirmModal,
  DataGrid,
  GridEventListener,
  GridLoadingOverlay,
  useGridApiRef,
} from '@microservices/wiskey-react-components'

import {
  getColumnFieldName,
  getErrorDescriptionByRow,
  getTransformResponse as addUniqueId,
  sortColumnsByOrder,
} from '@pages/ConfiguredView/helpers'
import {
  ChangedObjectDataRecord,
  ErrorDescriptionRows,
  ObjectDataRecord,
  ReturnUpdateObjectDataRecord,
} from '../../../../types'
import { useTranslation } from 'react-i18next'
import { MIN_VIEW_COLUMN_WIDTH } from '@constants'
import { PageContext } from '@pages/ConfiguredView/ConfiguredView'
import {
  useFetchObjectDataEnrichedByViewIdMutation,
  useUpdateObjectDataRecordMutation,
} from '@redux/api'
import { GridColumnType } from '@pages/ConfiguredView/hooks'
import { CellGenerator } from '../CellGenerator'
import { EditCellGenerator } from '../EditCellGenerator'
import { getSortErrorsByColumns } from '@pages/ConfiguredView/helpers/getSortErrorsByColumns'
import { useFetchViewValidationErrorsQuery } from '@redux/api/validate.api'
import { mergeBaseAndUserRequired } from '../../../../helpers/mergeBaseAndUserRequired'

type ValidationErrorsGridProps = {
  onSetGlobalObjectData: (
    value: ObjectDataRecord[] | ((prevState: ObjectDataRecord[]) => ObjectDataRecord[])
  ) => void
  handleShowValidationGrid: (value: boolean) => void
  sx: SxProps
}

export const ValidationErrorsGrid: FC<ValidationErrorsGridProps> = ({
  handleShowValidationGrid,
  onSetGlobalObjectData,
  sx,
}) => {
  const { t } = useTranslation()
  const { viewId, view } = useContext(PageContext)
  const apiRef = useGridApiRef()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [objectData, setObjectData] = useState<ObjectDataRecord[]>([])
  const [changedObjectData, setChangedObjectData] = useState<
    Record<number, ChangedObjectDataRecord>
  >([])
  const disabledSaveButton = useMemo(
    () => Object.keys(changedObjectData).length === 0,
    [changedObjectData]
  )
  const [errors, setErrors] = useState<ErrorDescriptionRows | null>(null)
  const [columns, setColumns] = useState<GridColumnType[]>([])
  const [editRowIndex, setEditRowIndex] = useState<number>()
  const [isDirtyCells, setDirtyCells] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)

  const [updateObjectDataRecord, { isLoading: isLoadingUpdateObjectDataRecord }] =
    useUpdateObjectDataRecordMutation()
  const [fetchEnrichedObjectData] = useFetchObjectDataEnrichedByViewIdMutation()

  const {
    data: objectValidationErrors,
    isLoading: isLoadingObjectValidation,
    isFetching,
  } = useFetchViewValidationErrorsQuery(viewId)
  const loading = isLoadingObjectValidation || isLoadingUpdateObjectDataRecord || isFetching

  const setDataByValidationErrors = (data: ReturnUpdateObjectDataRecord[]) => {
    const objectData = addUniqueId(data)
    setObjectData(objectData)
    setErrors(getErrorDescriptionByRow(objectData))
  }

  const scrollToCellError = (data: ObjectDataRecord[]) => {
    const sortedErrors = getSortErrorsByColumns(columns, data[editRowIndex || 0]?.errors)
    const column = view?.columns.find(
      ({ field }) => field === sortedErrors[editRowIndex || 0]?.fieldName
    )
    const columnFieldName = column && getColumnFieldName(column)
    const colIndex = columnFieldName && apiRef.current.getColumnIndex(columnFieldName)

    if (colIndex && colIndex !== -1) {
      Promise.resolve().then(() => {
        apiRef.current.scrollToIndexes({
          colIndex,
          rowIndex: editRowIndex || undefined,
        })

        setIsFirstRender(false)
      })
    }
  }

  useEffect(() => {
    if (objectValidationErrors && objectValidationErrors.length) {
      setDataByValidationErrors(objectValidationErrors)
    }
  }, [objectValidationErrors])

  useEffect(() => {
    if (columns.length && apiRef.current && objectData.length && isFirstRender) {
      scrollToCellError(objectData)
    }
  }, [columns, objectData, apiRef])

  useEffect(() => {
    if (view) {
      setColumns(
        sortColumnsByOrder([...view.columns]).map(column => ({
          field: getColumnFieldName(column),
          headerName: column.desc,
          pinnedColumn: column.restrictions.fix,
          // flex: 1,
          editable: true,
          required: mergeBaseAndUserRequired({
            baseRequired: column.baseRequired,
            userRequired: column.userRequired,
          }),
          width: column.restrictions.width ?? MIN_VIEW_COLUMN_WIDTH,
          minWidth: MIN_VIEW_COLUMN_WIDTH,
          type: column.valueType,
          valueFormatter: ({ value }) => (typeof value === 'object' ? value?.label || '' : value),
          // TODO: Нужна оптимизация, лагает при быстрой печати
          renderCell: params => (
            <CellGenerator
              {...params}
              error={errors?.[params.row._id]}
              column={column}
              handleSetObjectData={setObjectData}
            />
          ),
          renderEditCell: params => (
            <EditCellGenerator
              {...params}
              column={column}
              error={errors?.[params.row._id]}
              onSetError={setErrors}
              isConfirmSave
            />
          ),
        }))
      )
    }
  }, [view, errors])

  const handleCancel = (isDirtyCells: boolean) => {
    if (isDirtyCells) {
      setShowCancelModal(true)

      return
    }

    handleShowValidationGrid(false)
    setShowCancelModal(false)
  }

  const handleSave = () => {
    const updatedRows = Object.values(changedObjectData)

    // const updatedRows: GetObjectDataRecord[] = Object.values(
    //   apiRef.current.state.rows.idRowsLookup
    // ).map(({ errors, id, ...data }) => data)

    updateObjectDataRecord({ viewId, rows: updatedRows })
      .unwrap()
      .then(errorRows => {
        setChangedObjectData({})
        setDirtyCells(false)

        if (errorRows.some(({ errors }) => errors)) {
          fetchEnrichedObjectData({ ids: errorRows.map(({ _id }) => _id), viewId })
            .unwrap()
            .then(updatedRows => {
              const updatedObjectData = objectData.map(prevRow => {
                // полностью полученная строка
                const updatedRow = updatedRows.find(({ _id }) => _id === prevRow._id)
                // для получения новой ошибки и новых значений изменяемых св-в
                const errorRow = errorRows.find(({ _id }) => _id === prevRow._id)
                // для получения id, чтобы не ломалась таблица + скролл
                const initialRow = objectValidationErrors?.find(({ _id }) => _id === prevRow._id)

                return updatedRow
                  ? { id: prevRow.id, ...updatedRow, ...errorRow }
                  : { ...prevRow, ...initialRow }
              })

              setObjectData(updatedObjectData)
              setErrors(getErrorDescriptionByRow(updatedObjectData))
              scrollToCellError(updatedObjectData)
            })
          return
        }

        // Обновление данных в основной таблице
        onSetGlobalObjectData(prevData =>
          prevData.map(prevRow => {
            const updatedDataRow = errorRows.find(({ _id }) => prevRow._id === _id)

            if (updatedDataRow) {
              return { ...prevRow, ...updatedDataRow }
            }

            return prevRow
          })
        )

        const filteredErrorsData = objectData.filter(
          ({ _id }) => errorRows.findIndex(errorRow => _id === errorRow._id) === -1
        )

        // Если ошибок нет, то закрыть валидатор ошибок
        if (!filteredErrorsData.length) {
          handleShowValidationGrid(false)
          return
        }

        setObjectData(filteredErrorsData)
      })
  }

  const handleCellEditStart: GridEventListener<'cellEditStart'> = ({ id }) =>
    setEditRowIndex(apiRef.current.getRowIndexRelativeToVisibleRows(id))

  const handleCellEditStop: GridEventListener<'cellEditStop'> = (params, event, { api }) => {
    const { field, id } = params

    const editRow = apiRef.current.unstable_getRowWithUpdatedValuesFromCellEditing(id, field)

    if (!editRow && !editRow?.['_id']) {
      return
    }

    const rowIndex = api.getRowIndexRelativeToVisibleRows(id)

    const oldRow = apiRef.current.getRow(id)

    const isEqualValue = oldRow?.[field] === editRow[field]

    if (isEqualValue) {
      return
    }

    setChangedObjectData(prevState => {
      const newState = { ...prevState }
      newState[rowIndex] = { ...newState[rowIndex], _id: editRow['_id'], [field]: editRow[field] }

      return newState
    })

    setDirtyCells(true)
  }

  return (
    <>
      <ConfirmModal
        title={t('modal.cancelMenuChanges.title')}
        text={t('modal.cancelMenuChanges.text')}
        actionBtnText={t('modal.cancelBtnText')}
        cancelBtnText={t('modal.closeBtnText')}
        isShow={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={() => handleCancel(false)}
      />
      <Box px={4} py={2}>
        <DataGrid
          columns={columns}
          rows={objectData}
          sx={{
            ...sx,
            '.MuiDataGrid-cell': {
              p: 0,
              '&:focus': {
                zIndex: theme => theme.zIndex.appBar,
                borderBottom: 'none !important',
              },
            },
          }}
          apiRef={apiRef}
          loading={loading}
          onCellEditStart={handleCellEditStart}
          onCellEditStop={handleCellEditStop}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            LoadingOverlay: () => <GridLoadingOverlay />,
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 5 }}>
          <Button sx={{ mr: 2 }} onClick={() => handleCancel(isDirtyCells)}>
            {t('modal.cancelBtnText')}
          </Button>
          <Button variant={'contained'} disabled={disabledSaveButton} onClick={handleSave}>
            {t('modal.saveBtnText')}
          </Button>
        </Box>
      </Box>
    </>
  )
}
