import { ItemId, TreeItem } from '@atlaskit/tree'

import { OptionType } from 'src/types'

export class TreeBuilder {
  items: Record<ItemId, TreeItem>
  rootId: ItemId

  constructor(rootId: ItemId, item: Partial<OptionType>) {
    const rootItem = this._createItem(`${rootId}`, item)
    this.rootId = rootItem.id
    this.items = {
      [rootItem.id]: rootItem,
    }
  }

  withLeaf(id: number | string, item: Partial<OptionType>) {
    const leafItem = this._createItem(`${this.rootId}-${id}`, item)
    this._addItemToRoot(leafItem.id)
    this.items[leafItem.id] = leafItem
    return this
  }

  withSubTree(tree: TreeBuilder) {
    const subTree = tree.build()
    this._addItemToRoot(`${this.rootId}-${subTree.rootId}`)

    Object.keys(subTree.items).forEach(itemId => {
      const finalId = `${this.rootId}-${itemId}`
      this.items[finalId] = {
        ...subTree.items[itemId],
        id: finalId,
        children: Array.from<ItemId>(
          new Set(subTree.items[itemId].children.map(i => `${this.rootId}-${i}`))
        ),
      }
    })

    return this
  }

  build() {
    return {
      rootId: this.rootId,
      items: this.items,
    }
  }

  _addItemToRoot(id: string) {
    const rootItem = this.items[this.rootId]
    if (!rootItem.children.includes(id)) {
      rootItem.children.push(id)
    }
    rootItem.isExpanded = !!rootItem.isExpanded
    rootItem.hasChildren = true
  }

  _createItem = (itemId: string, item: Partial<OptionType>) => {
    return {
      id: `${itemId}`,
      children: [],
      hasChildren: false,
      isExpanded: !!item.expanded,
      isChildrenLoading: false,
      data: {
        title: item.title,
        ...item,
      },
    }
  }
}
