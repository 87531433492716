import { useEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

import { useDebounce } from '@hooks'
import { useHandlers } from './useHandlers'
import { useFetchAllViewQuery } from '@redux/api'
import { ROUTES, SEARCH_PATTERN_DELAY } from '@constants'

export const useConfigViewList = () => {
  const { pathname } = useLocation()
  const isAllEntities = matchPath(ROUTES.VIEWS, pathname)

  const { state, data, handlers } = useHandlers()

  const { searchPattern, allEntities } = state

  const { handleSetAllEntities } = handlers

  const debouncedSearchPattern = useDebounce(searchPattern, SEARCH_PATTERN_DELAY)

  const { data: viewsData, isFetching: isFetchingViews } = useFetchAllViewQuery(
    debouncedSearchPattern ? debouncedSearchPattern : undefined
  )

  useEffect(() => {
    handleSetAllEntities(viewsData || []) // TODO
  }, [viewsData])

  return {
    state: {
      ...state,
      isAllEntities,
      allEntities,
    },
    data: {
      ...data,
      isFetchingViews,
    },
    handlers,
  }
}
